import React, { useEffect, useState } from "react";
import useApp from "../../hooks/useAppContext";
import { useParams } from "react-router-dom";
import api from "../../services/axios";
import "./print.css";
import { toast } from "react-toastify";

const AdmImprimirInscritosConteudo = () => {
  let { id } = useParams();
  const { setMostraMenuAdm } = useApp();
  const [conteudo, setConteudo] = useState(null);
  const [inscritos, setInscritos] = useState([]);

  useEffect(() => {
    setMostraMenuAdm(false);
    if (id) {
      carregaConteudo();
    }
  }, []);

  const carregaConteudo = async () => {
    try {
      let respoConteudo = await api.post("/agenda", {
        adm: true,
        id: id
      })
      if (respoConteudo.status === 200 && respoConteudo.data.dados[0]) {
        setConteudo(respoConteudo.data.dados[0])
        if (respoConteudo.data.dados[0].participantes) {
          setInscritos(respoConteudo.data.dados[0].participantes.filter(p => p.usuario.tipo === "convidado"))
        }
      }
    } catch (error) {
      toast.error("Erro ao carregar conteudo")
    }
  };

  return (
    <div className="container-adm-main">
      <div id={`printable`}>
        <div className="header-tela-adm-print">
          <h3>Inscritos conteudo - {conteudo?.nome}</h3>
          <div className="header-options"></div>
        </div>
        <div className="body-tela-adm">
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div>Convidado</div>
              <div>Cargo</div>
              <div>Instituição</div>
            </div>
            <div className="body-lista-adm">
              {inscritos.length ? (
                inscritos.map((r) => {
                  return (
                    <div key={r.id} className="row-lista-adm-print">
                      <div>{r.usuario.nome}</div>
                      <div>{r.usuario.cargo}</div>
                      <div>{r.usuario.empresa}</div>
                    </div>
                  );
                })
              ) : (
                <div className="row-lista-adm">
                  <div>Sem reuniões agenadas</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmImprimirInscritosConteudo;
