import React, { useEffect, useState } from "react";
import ModalPatrocinador from "./modalPatrocinador";

function AreaPatrocinadores(props) {
  const [patrocinadores, setPatrocinadores] = useState([]);
  const [patrocinador, setPatrocinador] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let arrayPatrocinadores = props.patrocinadores?.filter((p) => {
      console.log(p);
      // let user = p.usuarios.filter((u) => {
      //   return u.id === p.id_usuario;
      // });
      // return user.length > 0 && props.id_cota === user[0].id_plano;
      return p.id_plano === props.id_cota || props?.id_extra?.includes(p.id);
    });
    setPatrocinadores(arrayPatrocinadores);
  }, [props]);

  const handleModalPatrocinio = (item) => {
    setShowModal(!showModal);
    setPatrocinador(item);
  };

  return (
    patrocinadores?.length > 0 && (
      <div className="patrocinio-cota">
        <ModalPatrocinador
          titulo={`Validação do evento`}
          show={showModal}
          toggleModal={() => setShowModal(!showModal)}
          item={patrocinador}
        />
        <div className={`cota`}>{props.cota}</div>
        <div className="area-patrocinador">
          {patrocinadores?.map((p) => {
            console.log(p);
            return p.logo ? (
              <div key={p.id} className="patrocinador-item">
                <img
                  src={p.logo}
                  className="imagem-logo"
                  alt={"logo"}
                  onClick={() => handleModalPatrocinio(p)}
                />
              </div>
            ) : (
              false
            );
          })}
        </div>
      </div>
    )
  );
}

export default AreaPatrocinadores;
