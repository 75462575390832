import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaEdit, FaPlus, FaPrint, FaTrash } from "react-icons/fa";
import useApp from "../../hooks/useAppContext";
import { useNavigate, useParams } from "react-router-dom";
import ModalReunioes from "../../components/modal/modalReunioes";
import { formatarDiaHora } from "../../services/conversaoTimer";
import api from "../../services/axios";
import useAuth from "../../hooks/useAuthContext";

const AdmReunioesConvidados = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { usuario } = useAuth();
  const { getUsuario, excluirAgenda } = useApp();
  const [convidado, setConvidado] = useState(null);
  const [reunioes, setReunioes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showContador, setShowContador] = useState(false);
  const [quantidadeMarcada, setQuantidadeMarcada] = useState(0);
  const [idsConvidados, setIdsConvidados] = useState([]);

  useEffect(() => {
    if (id) {
      carregaUsuario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carregaUsuario = async () => {
    let convidado = await getUsuario(id);
    console.log("convidadoconvidadoconvidado", convidado)
    setConvidado(convidado[0]);
    if (convidado[0].plano) {
      let respPatrocinador = await api.post(
        "/patrocinador-verifica-disponibilidade",
        { id_info: convidado[0].infoPatrocinador.id }
      );

      let patro = respPatrocinador.data.patrocinador;
      console.log("patro", patro)
      let arrayIds = [];
      patro.usuarios.map((u) => {
        u.agendaParticipante?.map((ap) => {
          if (
            ap.agenda?.tipo === "reuniao_negocio" &&
            ap.agenda.nome !== "Reunião de almoço/jantar" &&
            ap.agenda.nome !== "Reunião de negócios*"
          ) {
            arrayIds.push(ap.id_agenda);
          }
        });
      });

      let idsConvidados = convidado[0].agendaParticipante.map((ap) => {
        if (
          ap.agenda.tipo === "reuniao_negocio" &&
          ap.agenda.nome !== "Reunião de almoço/jantar" &&
          ap.agenda.nome !== "Reunião de negócios*"
        ) {
          return ap.agenda.participantes[0].id_usuario;
        }
      });

      let idsFilter = idsConvidados.filter((i) => {
        return i;
      });
      setIdsConvidados(idsFilter);
      const arrUnique = [...new Set(arrayIds)];
      setQuantidadeMarcada(arrUnique.length);
      setShowContador(true);
    } else {
      setShowContador(false);
    }

    let ordenacao = convidado[0].agendaParticipante.sort(function (a, b) {
      console.log(a.agenda.tipo);
      let dataA = new Date(a.agenda.dt_hora_inicio);
      let dataB = new Date(b.agenda.dt_hora_inicio);
      return dataA < dataB ? -1 : dataA > dataB ? 1 : 0;
    });

    setReunioes(ordenacao);
  };

  const handleModal = () => {
    carregaUsuario();
    setShowModal(!showModal);
  };

  const handleExclusao = async (id_agenda) => {
    await excluirAgenda(id_agenda);
    carregaUsuario();
  };

  const handleEditar = async (id_agenda) => {
    navigate(`/private/adm/editar-reuniao/${id_agenda}`);
  };

  return (
    <div className="container-adm-main">
      <ModalReunioes
        titulo="Nova Reunião de trabalho"
        to="/agenda"
        labelTo=""
        show={showModal}
        toggleModal={() => handleModal()}
        id={id}
        dia={"02"}
        cota={convidado?.plano?.cota_simultania}
        cota_quantidade={convidado?.plano?.cota_reunioes}
        id_info={convidado?.infoPatrocinador?.id}
        ids_convidados={idsConvidados}
        carrega_usuario={() => carregaUsuario()}
      />
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>Agenda - {convidado?.nome}</h3>
          <div className="header-options">
            {showContador ? (
              usuario?.tipo === "administracao" ? (
                <button
                  className="btn btn-header-options"
                  onClick={() => {
                    navigate(
                      "/private/adm/reuniao-jantar/" +
                      convidado.infoPatrocinador.id
                    );
                  }}
                >
                  Reunião de Almoço/Jantar
                </button>
              ) : (
                false
              )
            ) : (
              false
            )}
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate("/private/adm/convidados/imprimir/" + id);
              }}
            >
              <FaPrint /> imprimir
            </button>

            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div>Horário</div>
              <div>{`${showContador ? "Convidado" : "Evento"}`}</div>
              <div>Opções</div>
            </div>
            <div className="body-lista-adm">
              {reunioes.length ? (
                reunioes.map((r) => {
                  if (showContador) {
                    if (r.agenda.tipo === "reuniao_negocio") {
                      let convidadoReuniao =
                        r.agenda.participantes.filter((ap) => {
                          return ap.id_usuario !== parseInt(id);
                        });
                      return convidadoReuniao.length ? (
                        <div key={r.id} className="row-lista-adm">
                          <div>
                            {formatarDiaHora(r.agenda.dt_hora_inicio).substring(
                              0,
                              16
                            )}
                          </div>
                          <div style={{ flexDirection: "column" }}>
                            <div>
                              {convidadoReuniao[0]?.usuario.nome.toUpperCase()}
                            </div>
                            {r.agenda.nome === "Reunião de negócios*" ? (
                              <div style={{ color: "red" }}>Reunião Extra</div>
                            ) : (
                              ""
                            )}
                            {r.agenda.nome === "Reunião de almoço/jantar" ? (
                              <div style={{ color: "red" }}>Reunião de almoço/jantar</div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {usuario?.tipo === "administracao" ? (
                              <div className={`d-flex`}>
                                <div
                                  className="btn-header-options"
                                  onClick={() => handleExclusao(r.agenda.id)}
                                >
                                  <FaTrash title="Excluir Reunião" />
                                </div>
                                {/* <div
                                  title={`Editar reunão`}
                                  className="btn-header-options"
                                  onClick={() => handleEditar(r.agenda.id)}
                                >
                                  <FaEdit title="Editar Reunião" />
                                </div> */}
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      ) : (
                        false
                      );
                    } else {
                      return false;
                    }
                  } else {
                    if (r.agenda.tipo === "reuniao_negocio") {
                      let convidadoReuniao =
                        r.agenda.participantes.filter((ap) => {
                          return ap.id_usuario !== parseInt(id);
                        });
                      return convidadoReuniao.length ? (
                        <div key={r.id} className="row-lista-adm">
                          <div>
                            {formatarDiaHora(r.agenda.dt_hora_inicio).substring(
                              0,
                              16
                            )}
                          </div>
                          <div>
                            {convidadoReuniao[0]?.usuario.nome.toUpperCase()}
                          </div>
                          <div>
                            {/*<div*/}
                            {/*  className="btn-header-options"*/}
                            {/*  onClick={() => handleExclusao(r.agenda.id)}*/}
                            {/*>*/}
                            {/*  <FaTrash title="Excluir Reunião" />*/}
                            {/*</div>*/}-
                          </div>
                        </div>
                      ) : (
                        false
                      );
                    } else {
                      if (
                        r.agenda.tipo === "conteudo_patrocinado" ||
                        r.agenda.tipo === "conteudo_editorial"
                      ) {
                        return (
                          <div key={r.id} className="row-lista-adm">
                            <div>
                              {formatarDiaHora(
                                r.agenda.dt_hora_inicio
                              ).substring(0, 16)}
                            </div>
                            <div>{r.agenda.nome.toUpperCase()}</div>
                            <div>
                              {/*<div*/}
                              {/*  className="btn-header-options"*/}
                              {/*  onClick={() => handleExclusao(r.agenda.id)}*/}
                              {/*>*/}
                              {/*  <FaTrash title="Excluir Reunião" />*/}
                              {/*</div>*/}-
                            </div>
                          </div>
                        );
                      } else {
                        return false;
                      }
                    }
                  }
                })
              ) : (
                <div className="row-lista-adm">
                  <div>Sem reuniões agenadas</div>
                </div>
              )}
            </div>
          </div>
          <div className="area-botoes-lista-adm">
            <div className={`area-contador`}>
              {showContador ? (
                <h6>
                  Reuniões agendadas:{" "}
                  {`${quantidadeMarcada}/${convidado?.plano?.cota_reunioes} - ${convidado?.plano?.cota_simultania} simultaneas`}
                </h6>
              ) : (
                false
              )}
            </div>
            {quantidadeMarcada < convidado?.plano?.cota_reunioes ? (
              usuario?.tipo === "administracao" ? (
                <button
                  type="button"
                  className="text-white btn botoes-cadastro-salvar"
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                >
                  <FaPlus /> Adicionar Item
                </button>
              ) : (
                false
              )
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmReunioesConvidados;
