import React, { useEffect, useState } from "react";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/axios";
import { formatarDiaHora, formatarHora } from "../../services/conversaoTimer";

const IncluirReuniaoJantar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [agendas, setAgendas] = useState([]);
  const [patrocinador, setPatrocinador] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [nomePesquisa, setNomePesquisa] = useState("");
  const [indHorario, setIndHorario] = useState("");
  const [quantidadeMarcada, setQuantidadeMarcada] = useState(0);
  const [listaMarcado, setListaMarcado] = useState([]);
  const [idsMarcados, setIdsMarcados] = useState([]);
  const [idsMarcadosPatrocinador, setIdsMarcadosPatrocinador] = useState([]);
  const [idsReunioes, setIdsReunioes] = useState([]);

  const matriz_disponibilidade = [
    "01/08 - 20:00",
    "02/08 - 20:00",
    "03/08 - 20:00",
    "04/08 - 20:00",
    "01/08 - 13:00",
    "02/08 - 13:00",
    "03/08 - 13:00",
    "04/08 - 13:00",
  ];

  useEffect(() => {
    handlePatrocinador();
  }, []);

  const handleParticipantesMarcados = async (horario) => {
    let respostaPatrocinador = await api.post("/agenda-lista-jantar", {
      id_patrocinador: patrocinador.id_usuario,
      horario: horario,
    });
    if (respostaPatrocinador.status === 200) {
      setListaMarcado(respostaPatrocinador.data.listaFiltrada);
      setIdsMarcados(respostaPatrocinador.data.idsMarcados);
      setIdsMarcadosPatrocinador(
        respostaPatrocinador.data.idsMarcadosPatrocinador
      );
      let merge = respostaPatrocinador.data.idsMarcados.concat(
        respostaPatrocinador.data.idsMarcadosPatrocinador
      );
      if (respostaPatrocinador.data.reunioes.length) {
        console.log(respostaPatrocinador.data.reunioes)
        let idsReunioes = respostaPatrocinador.data.reunioes.map((r) => {
          return r.participantes[0].id_usuario;
        })
        console.log("idsReunioes", idsReunioes)
        setIdsReunioes(idsReunioes)
        merge = merge.concat(idsReunioes);
      }
      handleConvidados(nomePesquisa, merge);
    }
  };

  const handlePatrocinador = async () => {
    let respostaPatrocinador = await api.get("/patrocinador/" + id);
    if (respostaPatrocinador.status === 200) {
      console.log(respostaPatrocinador.data);
      setPatrocinador(respostaPatrocinador.data.dados);
    }
  };

  const handleHorario = async (id_click) => {
    setUsuarioSelecionado([...usuarioSelecionado, id_click]);
  };

  const handleClickHorario = async (horario) => {
    console.log(horario);
    setHorarioSelecionado(horario);
    setUsuarioSelecionado([]);
    if (horario === "01/08 - 19:30") {
      setIndHorario(30);
    }
    if (horario === "02/08 - 20:00") {
      setIndHorario(1);
    }
    if (horario === "03/08 - 20:00") {
      setIndHorario(2);
    }
    if (horario === "04/08 - 20:00") {
      setIndHorario(3);
    }

    handleParticipantesMarcados(horario);
  };

  const handleConvidados = async (nome, merge = []) => {
    let respUsuarios = await api.post(`/usuarios-jantar`, {
      tipo: ["convidado"],
      nome,
      idsNot: merge,
    });

    if (respUsuarios.status === 200) {
      setParticipantes(respUsuarios.data.dados);
    }
  };

  const handleCriarReuniao = async () => {
    if (usuarioSelecionado.length || horarioSelecionado) {
      let respMarcacao = await api.post("/agenda-marcar-jantar", {
        ids_usuario: usuarioSelecionado,
        horario: horarioSelecionado,
        id_patrocinador: patrocinador.id_usuario,
      });
      if (respMarcacao.status === 200) {
        alert("Reunião criada com sucesso!");
        navigate(-1);
      }
    } else {
      alert("Selecione um convidado");
    }
  };

  const handlePesquisaConvidado = (nome) => {
    let merge = idsMarcados.concat(idsMarcadosPatrocinador);
    merge = merge.concat(idsReunioes);
    handleConvidados(nome, merge);
    setNomePesquisa(nome);
  };

  return (
    <div className="container-adm-main">
      <div className="header-tela-adm">
        <h3>Marcar reunião de almoço/jantar - {patrocinador?.patrocinador}</h3>
        <div className="header-options">
          <button
            className="btn btn-header-options"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft /> Voltar
          </button>
        </div>
      </div>
      <div className={`area-body-remarcar`}>
        <div className={`bloco-remarcar`}>
          <div>Lista de horarios disponiveis</div>

          {matriz_disponibilidade.map((h) => {
            return (
              <div
                className={`linha-remarcar ${horarioSelecionado === h ? "ativo" : ""
                  }`}
                onClick={() => handleClickHorario(h)}
              >
                {h}
              </div>
            );
          })}
        </div>
        <div className={`bloco-remarcar`}>
          <div
            className={`d-flex`}
            style={{ alignItems: "center", width: "345px" }}
          >
            <div>Lista de convidados</div>
            <div style={{ marginLeft: "15px", width: "100%" }}>
              <input
                style={{ width: "100%" }}
                placeholder={`Pesquisa por nome`}
                type="text"
                value={nomePesquisa}
                onChange={(e) => {
                  handlePesquisaConvidado(e.target.value);
                }}
              />
            </div>
          </div>
          {participantes?.map((p) => {
            return (
              <div
                className={`linha-remarcar ${usuarioSelecionado.includes(p.id) ? "ativo" : ""
                  } `}
                onClick={() => {
                  if (usuarioSelecionado.includes(p.id)) {
                    let index = usuarioSelecionado.indexOf(p.id);
                    let array = [...usuarioSelecionado];
                    array.splice(index, 1);
                    setUsuarioSelecionado(array);
                  } else {
                    handleHorario(p.id);
                  }
                }}
              >
                {p.nome}
              </div>
            );
          })}
        </div>

        <div className={`marcar-jantar`}>
          <div
            className={`btn btn-header-options bt-marcar-jantar`}
            onClick={() => handleCriarReuniao()}
          >
            Criar reunião de almoço/jantar
          </div>
          <div className={`bloco-remarcar`}>
            <div
              className={`d-flex`}
              style={{ alignItems: "center", width: "345px" }}
            >
              <div>Lista de convidados agendados</div>
            </div>
            {listaMarcado.map((p) => {
              console.log(p)
              return (
                <div className={`linha-remarcar  `}>
                  {
                    p.participantes[0].usuario.nome
                  }
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "150px" }}></div>
    </div>
  );
};
export default IncluirReuniaoJantar;
