import React, { createContext, useCallback, useState } from "react";
import api from "../services/axios";

const AuthContext = createContext({});
export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  const login = useCallback(async (email, senha) => {
    try {
      console.log("login:", email, senha);
      const resp = await api.post("/login", { email, senha });

      if (resp.status === 200) {
        localStorage.setItem("email-sbf", email);
        localStorage.setItem("password-sbf", senha);
        setToken(`Bearer ${resp.data.dadosToken.token}`);
        localStorage.setItem(
          "token-sbf",
          `Bearer ${resp.data.dadosToken.token}`
        );
        localStorage.setItem("tipo-user-sbf", `${resp.data.dadosUser.tipo}`);
        setUsuario(resp.data);
        setIsLoggedIn(true);
      }
      return resp;
    } catch (error) {
      console.log("login error:", error, error.response?.data);
      return error.response;
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      const resp = await api.post("/logout", {});

      setToken(null);
      localStorage.removeItem("token-sbf");
      localStorage.removeItem("email-sbf");
      localStorage.removeItem("password-sbf");
      setUsuario(null);
      setIsLoggedIn(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Erro logout:", error, error.response?.data);
    }
  }, []);

  const validarUser = useCallback(async () => {
    try {
      const resp = await api.post("/valida-usuario", {});

      if (resp.status !== 200) {
        console.log("status !== 200");
        setToken(null);
        localStorage.removeItem("token-sbf");
        setUsuario(null);
        setIsLoggedIn(false);
        return false;
      } else {
        setIsLoggedIn(true);
        setUsuario(resp.data.usuario);
        return resp.data.usuario;
      }
    } catch (error) {
      console.log("catch");
      setToken(null);
      localStorage.removeItem("token-sbf");
      setUsuario(null);
      setIsLoggedIn(false);
      console.log("Erro logout:", error, error.response?.data);
      return false;
    }
  }, []);

  const verificaStorage = async () => {
    if (
      localStorage.getItem("email-sbf") &&
      localStorage.getItem("password-sbf")
    ) {
      let resp = await login(
        localStorage.getItem("email-sbf"),
        localStorage.getItem("password-sbf")
      );
      return resp.dadosUser;
    }
  };

  const validarEmail = useCallback(async (email) => {
    try {
      const resp = await api.post("/usuarios-verifica-email", { email });

      if (resp.data.dados) {
        return true;
      }
      return false;
    } catch (error) {
      console.log("Erro logout:", error, error.response?.data);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        usuario,
        token,
        isLoggedIn,
        login,
        logout,
        validarUser,
        isAppLoaded,
        setIsAppLoaded,
        validarEmail,
        verificaStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
