import { useContext } from "react";
import AppContext from "../contexts/AppContext";

function useApp() {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error("useApp must be used within an AppProvider");
  }

  return appContext;
}

export default useApp;
