import React from 'react'

// import useApp from "../../hooks/useAppContext";

const ItemAgenda = (props) => {
  return (
    <div className="item-agenda" onClick={props.handleModalAgenda}>
      <div className="icone-agenda">
        <img src={props.icone} alt="icone agenda" />
      </div>
      <div className="dados-agenda">
        <div className="titulo-agenda titulo-selecionado">{props.titulo}</div>
        <div className="detalhe-agenda">
          {props.detalhe}
          {props.participante ? props.participante : null}
        </div>
      </div>
    </div>
  )
}

export default ItemAgenda
