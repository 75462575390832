import React, { useState, useEffect } from "react";
// import Like from "../../assets/home/like.png";
// import Coracao from "../../assets/home/coracao.png";
// import Comentario from "../../assets/home/comentario.png";
import DefautPhoto from "../../assets/participantes/user_padrao.jpg";
// import useApp from "../../hooks/useAppContext";
import api from "../../services/axios";
import useAuth from "../../hooks/useAuthContext";
import { DateTime } from "luxon";
import {
  FaThumbsUp,
  FaHeart,
  FaCommentDots,
  FaRegComment
} from "react-icons/fa";

import { AiFillDelete } from "react-icons/ai"



const Post = (props) => {
  const { usuario } = useAuth();
  const [showInputComentario, setShowInputComentario] = useState(false);
  const [comentario, setComentario] = useState("");
  const [comentou, setComentou] = useState(false);
  const [curtiu, setCortiu] = useState(false);
  const [gostou, setGostou] = useState(false);

  useEffect(() => {
    const coment = props.post.comentariosPostagem.filter((comentario) => {
      return comentario?.usuario?.id === usuario?.id;
    });
    setComentou(coment.length > 0);

    const curtir = props.post.curtidasPostagem.filter((curtida) => {
      return curtida.id_usuario === usuario?.id;
    });
    setCortiu(curtir.length > 0);

    const gostar = props.post.gosteisPostagem.filter((gostei) => {
      return gostei.id_usuario === usuario?.id;
    });
    setGostou(gostar.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleComentario = async () => {
    let dados = {
      id_postagem: props.post.id,
      comentario: comentario,
    };
    let resp = await api.post("comentario-create", dados);
    console.log(resp.data);
    alert("comentario salvo");
    props.handlePostagem();

    setComentario("");
    setShowInputComentario(false);
  };

  const handleCurtir = async () => {
    let dados = {
      id_postagem: props.post.id,
    };
    let resp = await api.post("postagem-curtir", dados);
    console.log(resp.data);
    // alert("comentario salvo");
    props.handlePostagem();
    setComentario("");
    setShowInputComentario(false);
  };

  const handleGostei = async () => {
    let dados = {
      id_postagem: props.post.id,
    };
    let resp = await api.post("postagem-gostar", dados);
    console.log(resp.data);
    // alert("comentario salvo");
    props.handlePostagem();
    setComentario("");
    setShowInputComentario(false);
  };

  const handleExcluir = async () => {
    console.log(props.post)
    let dados = {
      id_postagem: props.post.id,
    };
    let resp = await api.post("postagem-desativar", dados);
    console.log(resp.data);
    alert("postagem excluida");
    props.handlePostagem();
  };

  return (
    <div className="item-timeline">
      <div className="area-participante-item-timeline">
        <div>
          <img
            className="imagem-participante"
            src={props.avatar ?? DefautPhoto}
            alt=""
          />
        </div>
        <div className="dados-participante-timeline">
          <div className="nome-participante-timeline">{props.usuario?.nome}</div>
          <div className="empresa-participante-timeline">{`${props.usuario?.cargo} / ${props.usuario?.empresa}`}</div>
        </div>
      </div>
      <div className="area-imagem-descricao-botoes">
        <img src={props.imagem} alt="" className="imagem-feed" />
        <div className="area-descricao-botoes">
          <div>{props.descricao}</div>
          {showInputComentario ? (
            <div class="input-group mb-3">
              <input
                type="text"
                className="form-control"
                style={{ boxShadow: "none" }}
                placeholder="O que acha desse Post?"
                aria-label="O que acha desse Post?"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setComentario(e.target.value);
                }}
              />
              <button
                className="btn btn-outline-secondary"
                style={{ boxShadow: "none" }}
                type="button"
                id="button-addon2"
                onClick={() => {
                  handleComentario();
                }}
              >
                <FaRegComment />
                <span className="mx-2">Enviar</span>
              </button>
            </div>
          ) : (
            false
          )}
          <div className="area-botoes-timeline">
            <div className="area-botoes-acoes">
              <FaThumbsUp
                className={curtiu ? `icone-post marcado` : `icone-post`}
                onClick={() => {
                  handleCurtir();
                }}
              />
              <FaHeart
                className={gostou ? `icone-post marcado` : `icone-post`}
                onClick={() => {
                  handleGostei();
                }}
              />
              <FaCommentDots
                className={comentou ? `icone-post marcado` : `icone-post`}
                onClick={() => {
                  setShowInputComentario(!showInputComentario);
                }}
              />
              {props.post.id_usuario === usuario.id ?
                <AiFillDelete
                  className={`icone-post-excluir`}
                  onClick={() => {
                    handleExcluir();
                  }}
                  title="Excluir postagem"
                /> : false}
            </div>
            <div className="text-muted texto-quantidades">
              <div className="mx-1">
                {props?.post?.comentariosPostagem.length} comentários{" "}
              </div>
              <div className="mx-1">
                {props?.post?.curtidasPostagem.length} curtidas{" "}
              </div>
              <div className="mx-1">
                {props?.post?.gosteisPostagem.length} gostei
              </div>
            </div>
          </div>
          <div className="area-comentarios">
            {props?.post?.comentariosPostagem.length > 0
              ? props?.post?.comentariosPostagem.map((comentario) => {
                let min = Math.ceil(1);
                let max = Math.floor(99999);
                let rand = Math.floor(Math.random() * (max - min) + min);

                let duracao = DateTime.now()
                  .minus({ hour: 3 })
                  .diff(DateTime.fromISO(comentario.created_at), [
                    "days",
                    "hours",
                    "minutes",
                  ]);

                let textTempo = "";
                if (duracao.values.days > 0) {
                  textTempo = `à ${duracao.values.days} dia${duracao.values.days > 1 ? "s" : ""
                    }`;
                } else {
                  if (duracao.values.hours > 0) {
                    textTempo = `à ${parseInt(duracao.values.hours)} hora${duracao.values.hours > 1 ? "s" : ""
                      }`;
                  } else {
                    textTempo = `à ${parseInt(duracao.values.minutes)} min`;
                  }
                }

                return (
                  <div key={rand} className="item-comentario">
                    <div className="nome-remetente">
                      {comentario.usuario?.nome}:
                      <div
                        className="text-muted"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        {textTempo}
                      </div>
                    </div>
                    <div className="area-comentario">
                      {comentario.comentario}
                    </div>
                  </div>
                );
              })
              : false}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;

