import React, { useEffect, useState } from "react";
import api from "../../services/axios";
import useAuth from "../../hooks/useAuthContext";

function ListaAgenda(props) {
  const [body, setBody] = useState([]);
  const { usuario } = useAuth();


  useEffect(() => {
    console.log(usuario)
    // if (usuario && usuario.tipo === 'administracao') {
    handleLista();
    // }
  }, []);

  const handleLista = async () => {
    let lista = await api.get("/lista-agenda");
    console.log(lista);
    let relatorioBody = lista.data.arrayAgenda.map((a) => {
      console.log(a);
      return (
        <tr>
          <td>{a.nome}</td>
          <td>{a.empresa}</td>
          <td>{a.dez20 ? a.dez20 : "Livre"}</td>
          <td>{a.onze ? a.onze : "Livre"}</td>
          <td>{a.onze40 ? a.onze40 : "Livre"}</td>
          <td>{a.doze20 ? a.doze20 : "Livre"}</td>
          <td>{a.doze50 ? a.doze50 : "Livre"}</td>
          <td>{a.dez303 ? a.dez303 : "Livre"}</td>
          <td>{a.onze103 ? a.onze103 : "Livre"}</td>
          <td>{a.onze503 ? a.onze503 : "Livre"}</td>
          <td>{a.doze303 ? a.doze303 : "Livre"}</td>
          <td>{a.uma103 ? a.uma103 : "Livre"}</td>
        </tr>
      );
    });
    setBody(relatorioBody);
  };

  return (
    <div>
      <table>
        <thead>
          <th>Nome</th>
          <th>Empresa</th>
          <th>02/08/2024 - 10:20 as 10:50</th>
          <th>02/08/2024 - 11:00 as 11:30</th>
          <th>02/08/2024 - 11:40 as 12:10</th>
          <th>02/08/2024 - 12:20 as 12:50</th>
          <th>02/08/2024 - 12:50 as 13:20</th>
          <th>03/08/2024 - 10:30 as 11:00</th>
          <th>03/08/2024 - 11:10 as 11:40</th>
          <th>03/08/2024 - 11:50 as 12:20</th>
          <th>03/08/2024 - 12:30 as 13:00</th>
          <th>03/08/2024 - 13:10 as 13:40</th>
        </thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
}

export default ListaAgenda;
