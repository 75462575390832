import React, { useEffect, useState } from "react";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/axios";
import { formatarDiaHora, formatarHora } from "../../services/conversaoTimer";
import Loading from "../../assets/home/loading.gif";

const IncluirReuniaoNegocio = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [agendas, setAgendas] = useState([]);
  const [patrocinador, setPatrocinador] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [nomePesquisa, setNomePesquisa] = useState("");
  const [indisponivel1, setIndisponivel1] = useState("");
  const [indisponivel2, setIndisponivel2] = useState("");
  const [idsJaMarcados, setIdsJaMarcados] = useState([]);
  const [loading, setLoading] = useState(false);

  const matriz_disponibilidade = [
    "10:20",
    "11:00",
    "11:40",
    "12:20",
    "12:50",
  ];

  const matriz_disponibilidade_segundo_dia = [
    "10:30",
    "11:10",
    "11:50",
    "12:30",
    "13:10",
  ];

  useEffect(() => {
    handlePatrocinador();
    handleConvidados();
  }, []);

  const handlePatrocinador = async () => {
    setLoading(true)
    let respostaPatrocinador = await api.post(
      "/patrocinador-verifica-disponibilidade",
      { id_info: id }
    );
    if (respostaPatrocinador.status === 200) {
      setPatrocinador(respostaPatrocinador.data.patrocinador);
      setIndisponivel1(respostaPatrocinador.data.indisponivel1);
      setIndisponivel2(respostaPatrocinador.data.indisponivel2);
      let marcados = await api.post("/patrocinador-por-id-recepcao", {
        id_info: respostaPatrocinador.data.patrocinador.id_usuario,
      });
      setIdsJaMarcados(marcados.data.agendasParticipantes);
    }
    setLoading(false)
  };

  const handleConvidados = async (nome) => {
    let respUsuarios = await api.post(`/usuarios-apenas-user`, {
      tipo: ["convidado"],
      nome,
    });

    if (respUsuarios.status === 200) {
      setParticipantes(respUsuarios.data.dados);
    }
  };

  const handleHorario = async (id_click) => {
    setLoading(true)
    let respostaHorarios = await api.post(`/agenda-horarios-disponiveis`, {
      id_usuario: id_click,
    });
    setUsuarioSelecionado(id_click);
    if (respostaHorarios.status === 200) {
      let horariosDisponiveis = [];
      matriz_disponibilidade.map((md) => {
        if (
          !respostaHorarios.data.indisponivel01.includes(md)
          // &&          indisponivel1[md] < patrocinador.plano.cota_simultania
        ) {
          horariosDisponiveis.push("02/08 -" + md);
        }
      });
      matriz_disponibilidade_segundo_dia.map((md) => {
        if (
          !respostaHorarios.data.indisponivel02.includes(md)
          // && indisponivel2[md] < patrocinador.plano.cota_simultania
        ) {
          horariosDisponiveis.push("03/08 -" + md);
        }
      });
      console.log(horariosDisponiveis);
      setHorarios(horariosDisponiveis);
    }
    setLoading(false)
    setHorarioSelecionado(null);
  };

  const handleClickHorario = (horario) => {
    setHorarioSelecionado(horario);
  };

  const handleCriarReuniao = async () => {
    console.log(patrocinador);
    setLoading(true)
    let respMarcacao = await api.post("/agenda-marcar", {
      id_usuario: usuarioSelecionado,
      horario: horarioSelecionado,
      id_patrocinador: patrocinador.id_usuario,
    });
    if (respMarcacao.status === 200) {
      setLoading(false)
      alert("Reunião criada com sucesso!");
      navigate("/private/adm/recepcao/02");
    } else {
      setLoading(false)
    }
  };

  const handlePesquisaConvidado = (nome) => {
    handleConvidados(nome);
    setNomePesquisa(nome);
  };

  return (
    <div className="container-adm-main">
      <div className="header-tela-adm">
        <h3>Marcar reunião - {patrocinador?.patrocinador}</h3>
        <div className="header-options">
          <button
            className="btn btn-header-options"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft /> Voltar
          </button>
        </div>
      </div>
      {loading ? (
        <div className="area-loading-blur">
          <div className="area-loading">
            <img src={Loading} alt="" />
          </div>
        </div>
      ) : (
        <div className={`area-body-remarcar`}>
          <div className={`bloco-remarcar`}>
            <div
              className={`d-flex`}
              style={{ alignItems: "center", width: "345px" }}
            >
              <div>Lista de convidados</div>
              <div style={{ marginLeft: "15px", width: "100%" }}>
                <input
                  style={{ width: "100%" }}
                  placeholder={`Pesquisa por nome`}
                  type="text"
                  value={nomePesquisa}
                  onChange={(e) => {
                    handlePesquisaConvidado(e.target.value);
                  }}
                />
              </div>
            </div>
            {participantes.map((p) => {
              return idsJaMarcados.includes(p.id) ? (
                false
              ) : (
                <div
                  key={p.id}
                  className={`linha-remarcar ${usuarioSelecionado === p.id ? "ativo" : ""
                    }`}
                  onClick={() => handleHorario(p.id)}
                >
                  {p.nome}
                </div>
              );
            })}
          </div>
          <div className={`bloco-remarcar`}>
            <div>Lista de horarios disponiveis</div>

            {horarios.map((h) => {
              return (
                <div
                  key={h}
                  className={`linha-remarcar ${horarioSelecionado === h ? "ativo" : ""
                    }`}
                  onClick={() => handleClickHorario(h)}
                >
                  {h}
                </div>
              );
            })}
          </div>
          <div>
            <div
              className={`btn btn-header-options`}
              onClick={() => handleCriarReuniao()}
            >
              Criar Reunião
            </div>
          </div>
        </div>)}
      <div style={{ paddingBottom: "150px" }}></div>
    </div>
  );
};
export default IncluirReuniaoNegocio;
