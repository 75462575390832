import React, { createContext, useCallback, useState, useEffect } from "react";
import api from "../services/axios";

const CadastroPatrocinadorContext = createContext({});
export default CadastroPatrocinadorContext;

export const CadastroPatrocinadorProvider = ({ children }) => {
  const [patrocinador, setPatrocinador] = useState("");
  const [perfil, setPerfil] = useState("");
  const [logo, setLogo] = useState(null);
  const [email, setEmail] = useState("");
  const [segmento, setSegmento] = useState("");
  const [segmentoOutros, setSegmentoOutros] = useState("");

  const [participantes, setParticipantes] = useState([]);
  const [idContador, setIdcontador] = useState(0);

  const [showAlertaPatrocinador, setShowAlertaPatrocinador] = useState(false);

  const verificaFormularioPatrocinador = () => {
    console.log("verificando");
    if (
      patrocinador === "" ||
      // perfil === "" ||
      email === "" ||
      // segmento === "" ||
      participantes.length === 0
    ) {
      setShowAlertaPatrocinador(true);
    } else {
      setShowAlertaPatrocinador(false);
    }
  };

  const salvarPatrocinador = async () => {
    try {
      const formData = new FormData();

      formData.append("patrocinador", patrocinador);
      formData.append("perfil", perfil);
      formData.append("logo", logo);
      formData.append("email", email);

      let response = await api.post(`/patrocinador`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        participantes.map(async (p) => {
          let formDataParticipante = new FormData();
          formDataParticipante.append("nome", p.nomeCompleto);
          formDataParticipante.append("email", p.email);
          formDataParticipante.append("tipo", p.tipo);
          formDataParticipante.append("password", "SBF_2023");
          formDataParticipante.append("cpf", p.cpf);
          formDataParticipante.append("dt_nascimento", p.dataNascimento);
          formDataParticipante.append("celular", p.celular);
          formDataParticipante.append("empresa", patrocinador);
          formDataParticipante.append("cargo", p.cargo);
          formDataParticipante.append("nome_cracha", p.nomeCracha);
          formDataParticipante.append("sexo", p.sexo.toLowerCase());
          formDataParticipante.append("telefone_comercial", p.telComercial);
          formDataParticipante.append("departamento", p.departamento);
          formDataParticipante.append("tipo_instituicao", segmento);
          formDataParticipante.append("segmento_outros", segmentoOutros);
          formDataParticipante.append(
            "id_patrocinador",
            response.data.dados.novoPatrocinadorInsert.id
          );
          response = await api.post(`/usuarios-create`, formDataParticipante, {
            headers: {
              "content-type": "multipart/form-data",
            },
          });
        });
      }
      return response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("login error:", error, error.response?.data);
      return error.response?.data;
    }
  };

  return (
    <CadastroPatrocinadorContext.Provider
      value={{
        patrocinador,
        setPatrocinador,
        perfil,
        setPerfil,
        logo,
        setLogo,
        participantes,
        setParticipantes,
        email,
        setEmail,
        segmento,
        setSegmento,
        segmentoOutros,
        setSegmentoOutros,
        showAlertaPatrocinador,
        setShowAlertaPatrocinador,
        idContador,
        setIdcontador,
        salvarPatrocinador,
        verificaFormularioPatrocinador,
      }}
    >
      {children}
    </CadastroPatrocinadorContext.Provider>
  );
};
