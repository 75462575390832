import { useContext } from "react";
import CadastroPatrocinadorContext from "../contexts/CadastroPatrocinadorContext";

function useCadastroPatrociandor() {
  const cadastroPatrocinadorContext = useContext(CadastroPatrocinadorContext);

  if (!cadastroPatrocinadorContext) {
    throw new Error(
      "useCadastroPatrociandorPatrociandor must be used within an CadastroProvider"
    );
  }

  return cadastroPatrocinadorContext;
}

export default useCadastroPatrociandor;
