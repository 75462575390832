import React, { useEffect, useState } from "react";
import api from "../../services/axios";

function AreaPatrocinadoresConteudoAdm(props) {
  const [salas, setSalas] = useState([]);

  useEffect(() => {
    // let arrayPatrocinadores = props?.patrocinadores?.filter((p) => {
    //   console.log("dentro da linha");
    //   console.log(p);
    //   // let user = p.usuarios.filter((u) => {
    //   //   return u.id === p.id_usuario;
    //   // });
    //   // return user.length > 0 && props.id_cota === user[0].id_plano;
    //   return p.id_plano === props.id_cota;
    // });
    // setPatrocinadores(arrayPatrocinadores);
    handleSalas();
  }, [props]);

  const handleSalas = async () => {
    let respostaSalas = await api.post("/agenda-recepcao-conteudo", {
      tipo: ["conteudo_patrocinado", "conteudo_editorial"],
    });
    if (respostaSalas.status === 200) {
      console.log(respostaSalas.data);

      let auxSalas = respostaSalas.data.dados.map((s) => {
        console.log(s);
        let entregues = s.participantes.filter((ap) => {
          return ap.presenca;
        });
        s.entregues = entregues;
        return s;
      });
      setSalas(auxSalas);
    }
  };

  return (
    <div className="patrocinio-cota-adm">
      <div className={`cota-adm`}>{props.cota}</div>
      <div className="area-patrocinador-adm">
        {salas?.map((p) => {
          console.log(p);
          return (
            <table
              className={`table table-hovered table-bordered text-center"`}
            >
              <tr>
                <td>
                  <div className={`label-nome-patrocinnio`}>{p.nome}</div>
                </td>
                <td
                  style={{
                    textAlign: "end",
                    marginRight: "5px",
                    minWidth: "150px",
                  }}
                ></td>
              </tr>

              <tr>
                <td>Quantidade agendadas</td>
                <td
                  style={{
                    textAlign: "end",
                    marginRight: "5px",
                    minWidth: "150px",
                  }}
                >
                  {p.participantes.length}
                </td>
              </tr>
              <tr>
                <td>Quantidade de presentes</td>
                <td
                  style={{
                    textAlign: "end",
                    marginRight: "5px",
                    minWidth: "150px",
                  }}
                >
                  {p.entregues.length}
                </td>
              </tr>
              <tr>
                <td>Porcentagem SLA</td>
                <td
                  style={{
                    textAlign: "end",
                    marginRight: "5px",
                    minWidth: "150px",
                  }}
                >
                  {p.entregues.length != 0
                    ? (
                      (p.entregues.length / p.participantes.length) *
                      100
                    ).toFixed(2) + " %"
                    : "0 %"}
                </td>
              </tr>
            </table>
          );
        })}
      </div>
    </div>
  );
}

export default AreaPatrocinadoresConteudoAdm;
