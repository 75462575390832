import React, { useEffect, useState } from "react";
import InputText from "../cadastro/inputtext";
import useApp from "../../hooks/useAppContext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/axios";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

const AdmFormEventos = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { getPatrocinadores, getEventoId } = useApp();
  const [nome, setNome] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [data, setData] = useState("");
  const [dt_hora_inicio, setDt_hora_inicio] = useState(null);
  const [dt_hora_fim, setDt_hora_fim] = useState(null);
  const [detalhe, setDetalhe] = useState(null);
  const [local, setLocal] = useState(null);
  const [id_patrocinador, setId_Patrocinador] = useState(null);
  const [select_patrocinadores, setSelect_patrocinadores] = useState([]);
  const [disable, setDisable] = useState(false);

  const [showPatrocinador, setShowPatrocinador] = useState(false);
  const notifyInfo = (mensagem) => toast.info(mensagem, { theme: "colored" });
  const notifyError = (mensagem) => toast.error(mensagem, { theme: "colored" });

  useEffect(() => {
    limparFormulario();
    carregaPatrocinadores();
    if (id) {
      carregaEvento(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carregaPatrocinadores = async () => {
    let patrocinadores = await getPatrocinadores();
    let select = patrocinadores.map((p) => {
      return <option value={p.id}>{p.patrocinador}</option>;
    });
    setSelect_patrocinadores(select);
  };

  const carregaEvento = async (id) => {
    let evento = await getEventoId(id);

    setNome(evento.nome);
    setTipo(evento.tipo);
    if (evento.tipo === "conteudo_patrocinado") {
      setShowPatrocinador(true);
    }
    let dataEvento = evento.dt_hora_inicio.substring(8, 10);
    setData(dataEvento);
    let horaIni = evento.dt_hora_inicio.substring(11, 16);
    console.log(horaIni);
    setDt_hora_inicio(horaIni);
    let horaFim = evento.dt_hora_fim.substring(11, 16);
    console.log(horaFim);
    setDt_hora_fim(horaFim);
    setDetalhe(evento.detalhe);
    setLocal(evento.local);
    setId_Patrocinador(evento.id_patrocinador);
  };

  const handleSalvarEvento = async () => {
    setDisable(true);

    let dataInicio = "2024-08-" + data + " " + dt_hora_inicio + ":00";
    let dataFim = "2024-08-" + data + " " + dt_hora_fim + ":00";

    let dados = {
      nome,
      tipo,
      dt_hora_inicio: dataInicio,
      dt_hora_fim: dataFim,
      detalhes: detalhe,
      local,
      id_patrocinador,
    };
    if (id) {
      try {
        let resp = await api.put("/agenda-update/" + id, dados);
        notifyInfo(resp.data.mensagem);
        setDisable(false);
        limparFormulario();
      } catch (error) {
        notifyError(error.response.data.mensagem);
        setDisable(false);
      }
    } else {
      try {
        let resp = await api.post("/agenda-create", dados);
        notifyInfo(resp.data.mensagem);
        setDisable(false);
        limparFormulario();
      } catch (error) {
        setDisable(false);
        notifyError(error.response.data.mensagem);
      }
    }
  };

  const handleTipo = (e) => {
    setTipo(e);
    if (e === "conteudo_patrocinado") {
      setShowPatrocinador(true);
    } else {
      setShowPatrocinador(false);
    }
  };

  const limparFormulario = () => {
    setNome("");
    setTipo("");
    setShowPatrocinador(false);
    setData("");
    setDt_hora_inicio("");
    setDt_hora_fim("");
    setDetalhe("");
    setLocal("");
    setId_Patrocinador("");
  };

  return (
    <div className="container-adm-main">
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>{id ? "Editar" : "Novo"} Conteudo</h3>
          <div className="header-options">
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className="area-inputs-adm">
            <div className="coluna-dupla">
              <InputText
                label="Nome"
                type="text"
                requered={true}
                value={nome}
                onChange={setNome}
                focus={true}
                validador={() => {
                  console.log("campo posicao");
                }}
              />
              <span className="espacador"></span>
              <div className="mb-1">
                <label
                  className="form-label label-input"
                  htmlFor="input_procura"
                >
                  Tipo: <span className="text-red">*</span>
                </label>
                <select
                  className="form-select form-control form-control-lg"
                  aria-label="Default select example"
                  id="input_procura"
                  value={tipo}
                  onChange={(e) => handleTipo(e.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="conteudo_patrocinado">
                    Conteudo Patrocinado
                  </option>
                  <option value="conteudo_editorial">Conteudo Editorial</option>
                  <option value="esporte">Esporte</option>
                </select>
              </div>
            </div>
            <div className="coluna-dupla">
              <div className="mb-1">
                <label
                  className="form-label label-input"
                  htmlFor="input_procura"
                >
                  Data evento: <span className="text-red">*</span>
                </label>
                <select
                  className="form-select form-control form-control-lg"
                  aria-label="Default select example"
                  id="input_procura"
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                >
                  <option value="">Selecione o dia</option>
                  <option value="02">02/08/2024</option>
                  <option value="03">03/08/2024</option>
                </select>
              </div>
              <span className="espacador"></span>
              <InputText
                label="Hora início"
                type="time"
                requered={true}
                value={dt_hora_inicio}
                onChange={setDt_hora_inicio}
                validador={() => {
                  console.log("campo posicao");
                }}
              />
              <span className="espacador"></span>
              <InputText
                label="Hora fim"
                type="time"
                requered={true}
                value={dt_hora_fim}
                onChange={setDt_hora_fim}
                validador={() => {
                  console.log("campo posicao");
                }}
              />
            </div>
            <div className="area-texto-bio">
              <label className="form-label" htmlFor="input_bio">
                Detalhe
              </label>
              <textarea
                className="textarea-bio"
                rows="5"
                onChange={(e) => setDetalhe(e.target.text)}
                defaultValue={detalhe}
              ></textarea>
            </div>

            <div className="coluna-dupla">
              <InputText
                label="local"
                type="text"
                requered={true}
                value={local}
                onChange={setLocal}
                validador={() => {
                  console.log("campo posicao");
                }}
              />
              <span className="espacador"></span>

              {showPatrocinador ? (
                <div className="mb-1">
                  <label
                    className="form-label label-input"
                    htmlFor="input_procura"
                  >
                    Patrocinador: <span className="text-red">*</span>
                  </label>
                  <select
                    className="form-select form-control form-control-lg"
                    aria-label="Default select example"
                    id="input_procura"
                    value={id_patrocinador}
                    onChange={(e) => setId_Patrocinador(e.target.value)}
                  >
                    <option value="">Selecione um patrocinador</option>
                    {select_patrocinadores}
                  </select>
                </div>
              ) : (
                false
              )}
            </div>
          </div>
          <div className="area-botoes">
            <button
              style={{ backgroundColor: "#008A38" }}
              type="button"
              className="text-white btn botoes-cadastro-salvar"
              onClick={() => {
                handleSalvarEvento();
              }}
              disabled={disable}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmFormEventos;
