import React, { useEffect, useState } from "react";
import InputText from "../cadastro/inputtext";
import InputRadio from "../cadastro/inputradio";
// import { FaPlus, FaEdit } from "react-icons/fa";
import useApp from "../../hooks/useAppContext";
import useCadastro from "../../hooks/useCadastroContext";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatarDia, formatarHora } from "../../services/conversaoTimer";
import { FaArrowLeft } from "react-icons/fa";
import Loading from "../../assets/home/loading.gif";

const AdmFormConvidados = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { getUsuario, handleImagemParticipante, redefinirSenha, getEventos } =
    useApp();
  const {
    nomeCompleto,
    setNomeCompleto,
    setAvatar,
    nomeCracha,
    setNomeCracha,
    bio,
    setBio,
    email,
    setEmail,
    cpf,
    setCPF,
    dataNascimento,
    setDataNascimento,
    telComercial,
    setTelComercial,
    sexo,
    setSexo,
    celular,
    setCelular,
    nomeInstituicao,
    setnomeInstituicao,
    cargo,
    setCargo,
    departamento,
    setDepartamento,
    showAlerta,
    salvarUsuario,
    setTipo,
    eventoId1,
    setEventoId1,
    eventoId2,
    setEventoId2,
    limpaVariaveis,
  } = useCadastro();
  const [convidado, setConvidado] = useState(null);
  const notifyInfo = (mensagem) => toast.info(mensagem, { theme: "colored" });
  const [preview, setPreview] = useState(null);
  const [selectEvento, setSelectEvento] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    limpaVariaveis();
    if (id) {
      handleTela();
    }
  }, []);

  useEffect(() => {
    handleEventos();
  }, [convidado]);

  const handleTela = async () => {
    await carregaConvidado();
  };

  const handleEventos = async () => {
    let eventos = await getEventos();
    let auxEvento = eventos.filter((e) => {
      return (
        (e.tipo === "conteudo_patrocinado" ||
          e.tipo === "conteudo_editorial") &&
        (e.dt_hora_inicio.includes("2024-08-02") ||
          e.dt_hora_inicio.includes("2024-08-03"))
      );
    });
    let auxAgenda = convidado?.agendaParticipante?.filter((ap) => {
      return (
        ap.agenda.tipo === "reuniao_negocio" ||
        ap.agenda.tipo === "conteudo_editorial" ||
        ap.agenda.tipo === "conteudo_patrocinado"
      );
    });

    let arrayHorariosMarcados = auxAgenda?.map((a) => {
      return a.agenda.dt_hora_inicio.substring(0, 16);
    });
    let auxSelectEvento = auxEvento.map((e) => {
      let dataInicio = e.dt_hora_inicio.substring(0, 16);
      let indisponivel = false;
      if (arrayHorariosMarcados && arrayHorariosMarcados.includes(dataInicio)) {
        indisponivel = true;
      } else {
        let marcados = arrayHorariosMarcados?.map((hm) => {
          if (hm.includes("2024-08-02")) {
            let hora = hm.substring(11, 16);
            if (dataInicio.substring(11, 16) === "10:20") {
              if (hora === "11:00") {
                return true;
              }
            }
            if (dataInicio.substring(11, 16) === "11:40") {
              if (hora === "12:20") {
                return true;
              }
            }

            return false;
          }

          if (hm.includes("2024-08-03")) {
            let hora = hm.substring(11, 16);

            if (dataInicio.substring(11, 16) === "10:30") {
              if (hora === "11:10") {
                return true;
              }
            }
            if (dataInicio.substring(11, 16) === "11:50") {
              if (hora === "12:30") {
                return true;
              }
            }

            return false;
          }
        });
        let filterMarcados = marcados?.filter((m) => {
          return m;
        });
        indisponivel = filterMarcados?.length ? true : false;
      }
      // let indisponivel = arrayHorariosMarcados.includes(dataInicio);
      return (
        <option
          key={e.id}
          value={e.id}
          disabled={e.participantes.length >= 25 || indisponivel}
        >
          {`
          ${formatarDia(e.dt_hora_inicio)} -> ${formatarHora(
            e.dt_hora_inicio
          ).substring(0, 5)} à ${formatarHora(e.dt_hora_fim).substring(
            0,
            5
          )} -- ${e.nome}  ${e.participantes.length >= 25 ? "--*Esgotado" : ""
            }`}
        </option>
      );
    });
    setSelectEvento(auxSelectEvento);
  };

  const carregaConvidado = async () => {
    let loadConvidado = await getUsuario(id);
    setConvidado(loadConvidado[0]);
    setNomeCompleto(loadConvidado[0].nome);
    setNomeCracha(loadConvidado[0].nome_cracha);
    setEmail(loadConvidado[0].email);
    setCPF(loadConvidado[0].cpf);
    setDataNascimento(loadConvidado[0]?.dt_nascimento?.substring(0, 10));
    setTelComercial(loadConvidado[0].telefone_comercial);
    setSexo(loadConvidado[0].sexo === "feminino" ? "Feminino" : "Masculino");
    setCelular(loadConvidado[0].celular);
    setnomeInstituicao(loadConvidado[0].empresa);
    setCargo(loadConvidado[0].cargo);
    setDepartamento(loadConvidado[0].departamento);
    setTipo(loadConvidado[0].tipo);
    setAvatar(loadConvidado[0].avatar);
    setBio(loadConvidado[0].bio);
    setEventoId1(loadConvidado[0].conteudo1);
    setEventoId2(loadConvidado[0].conteudo2);
  };

  const handleSalvarConvidado = async () => {
    setLoading(true);
    let resp;
    if (id) {
      resp = await salvarUsuario(id);
    } else {
      resp = await salvarUsuario();
    }
    notifyInfo(resp.mensagem);
    setLoading(false);
  };

  const handleChangeImage = (e) => {
    if (e.target.files.length) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setAvatar(e.target.files[0]);
    }
  };

  const handleReiniciarSenha = () => {
    // eslint-disable-next-line no-restricted-globals
    let comfirmacao = confirm(
      "Deseja redefinir a senha do usuario para sbf@2024"
    );
    if (comfirmacao) {
      if (id) {
        redefinirSenha(id);
      }
      notifyInfo("Senha alterada com sucesso");
    }
  };

  return (
    <div className="container-adm-main">
      {loading ? (
        <div className="area-loading-blur">
          <div className="area-loading">
            <img src={Loading} alt="" />
          </div>
        </div>
      ) : (
        false
      )}
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>{id ? "Editar" : "Novo"} convidado</h3>
          <div className="header-options">
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className="area-inputs-adm">
            <div className="area-imagem-usuario">
              <div className="form-imagem">
                {preview ? (
                  <div className="">
                    <img src={preview} alt="" />
                  </div>
                ) : (
                  <img
                    className=""
                    src={handleImagemParticipante(convidado)}
                    alt=""
                    title="Alterar imagem de perfil"
                  />
                )}
                <input
                  type="file"
                  id="input-perfil-adm"
                  onChange={(e) => handleChangeImage(e)}
                />
              </div>
              <div className="form-lateral">
                <InputText
                  label="Nome completo"
                  type="text"
                  requered={true}
                  value={nomeCompleto}
                  onChange={setNomeCompleto}
                  focus={true}
                  validador={() => { }}
                />
                <InputText
                  label="Nome para o crachá"
                  type="text"
                  requered={true}
                  value={nomeCracha}
                  onChange={setNomeCracha}
                  validador={() => { }}
                />
                <div className="mb-1">
                  <label htmlFor="bio" className="form-label label-input">
                    Bio
                  </label>
                  <textarea
                    id="bio"
                    className="textarea-bio"
                    rows="5"
                    onChange={(e) => setBio(e.target.value)}
                    value={bio || ""}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="coluna-dupla">
              <InputText
                label="CPF"
                type="text"
                requered={true}
                value={cpf}
                onChange={setCPF}
                mascara="999.999.999-99"
                validador={() => { }}
              />
              <span className="espacador"></span>
              <InputText
                label="E-mail"
                type="mail"
                requered={true}
                value={email}
                onChange={setEmail}
                validador={() => { }}
              />
            </div>
            <div className="coluna-dupla">
              <InputText
                label="Data de nascimento"
                type="date"
                requered={true}
                value={dataNascimento}
                onChange={setDataNascimento}
                validador={() => { }}
              />
              <span className="espacador"></span>
              <InputRadio
                label="Sexo"
                values={["Masculino", "Feminino"]}
                onChange={setSexo}
                value={sexo}
                validador={() => { }}
              />
            </div>
            <div className="coluna-dupla">
              <InputText
                label="Telefone comercial"
                type="text"
                requered={true}
                value={telComercial}
                onChange={setTelComercial}
                mascara="(99) 99999-9999"
                validador={() => { }}
              />

              <span className="espacador"></span>
              <InputText
                label="Celular"
                type="text"
                requered={true}
                value={celular}
                onChange={setCelular}
                mascara="(99) 99999-9999"
                validador={() => { }}
              />
            </div>

            <InputText
              label="Nome da Instituição"
              type="text"
              requered={true}
              value={nomeInstituicao}
              onChange={setnomeInstituicao}
              validador={() => { }}
            />
            <div className="coluna-dupla">
              <InputText
                label="Cargo"
                type="text"
                requered={true}
                value={cargo}
                onChange={setCargo}
                validador={() => { }}
              />
              <span className="espacador"></span>
              <InputText
                label="Departamento"
                type="text"
                requered={true}
                value={departamento}
                onChange={setDepartamento}
                validador={() => { }}
              />
            </div>
            {showAlerta ? (
              <div className="area-alerta">
                ATENÇÃO! Um ou mais campos obrigatórios não foram preenchidos
                corretamente.
              </div>
            ) : (
              false
            )}
          </div>

          {/* <div className="area-conteudos">
            <div className="title-conteudos">
              <h3>Conteudos</h3>
            </div>
            <div className="area-select-conteudos">
              <label className="form-label" htmlFor="input_procura">
                Primeiro Conteudo
              </label>
              <select
                className="form-select form-control form-control-lg"
                aria-label="Default select example"
                id="input_procura"
                value={eventoId1 ?? ""}
                onChange={(e) => setEventoId1(e.target.value)}
              >
                <option value={`0`}>Selecione um conteudo</option>
                {selectEvento}
              </select>
              <label>Segundo Conteudo</label>
              <select
                className="form-select form-control form-control-lg"
                aria-label="Default select example"
                id="input_procura"
                value={eventoId2 ?? ""}
                onChange={(e) => setEventoId2(e.target.value)}
              >
                <option value={`0`}>Selecione um conteudo</option>
                {selectEvento}
              </select>
            </div>
          </div> */}

          <div className="area-botoes">
            {id ? (
              <button
                style={{ backgroundColor: "#008A38" }}
                type="button"
                className="text-white btn botoes-cadastro-salvar"
                onClick={() => {
                  handleReiniciarSenha();
                }}
              >
                Redefinir Senha
              </button>
            ) : (
              false
            )}
            <button
              style={{ backgroundColor: "#008A38" }}
              type="button"
              className="text-white btn botoes-cadastro-alterar-senha"
              onClick={() => {
                handleSalvarConvidado();
              }}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmFormConvidados;
