import React, { useEffect } from "react";
import { useState } from "react";
import useApp from "../../hooks/useAppContext";
import useAuth from "../../hooks/useAuthContext";
import UserPadrao from "../../assets/participantes/user_padrao.jpg";
import UserPadraoM from "../../assets/participantes/Ícone-1-200x200.jpg";
import UserPadraoF from "../../assets/participantes/Ícone-2-200x200.jpg";
import { useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { FiSend } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import "./chat.css";

const Conversa = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const {
    getMensagensChat,
    enviaMenagem,
    verificaDispositivo,
    visualizarMenagem,
  } = useApp();
  const { usuario } = useAuth();
  const [mensagens, setMensagens] = useState([]);
  const [texto, setTexto] = useState("");
  const [usuarioConversa, setUsuarioConversa] = useState(null);
  const [myInterval, setMyInterval] = useState([]);
  const [validaApple, setValidaApple] = useState(false);

  useEffect(() => {
    handleMensagens();
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  }, [usuario]);

  useEffect(() => {
    atualizaMensagens();
  }, []);

  useEffect(() => {
    setValidaApple(verificaDispositivo());
  }, []);

  const voltar = async () => {
    for (let index = 0; index < 1000; index++) {
      clearInterval(index);
    }
    setTexto("");
    navigate(-1);
  };

  const atualizaMensagens = () => {
    let time = setInterval(handleMensagens, 10000);
    setMyInterval(time);
  };

  const handleMensagens = async () => {
    let aux_mensagens = await getMensagensChat(id);
    console.log(aux_mensagens);
    if (aux_mensagens?.dados[0]?.chatMensagens) {
      setMensagens(aux_mensagens?.dados[0]?.chatMensagens);
    }
    setUsuarioConversa(aux_mensagens.usuario);

    // let aux_mensagens_com_usuario = aux_mensagens.conversas.map((c) => {
    //   let baseUser = c.id_origem === usuario?.id ? c.id_destino : c.id_origem;
    //   let user = aux_mensagens.usuario_conversas.filter((uc) => {
    //     return uc.id === baseUser;
    //   });
    //   c.usuario = user[0];
    //   return c;
    // });
    // setMensagens(aux_mensagens_com_usuario);
  };

  const handleEnviaMensagem = async () => {
    if (texto.trim() !== "") {
      await enviaMenagem(id, texto);
      setTexto("");
      handleMensagens();
    }
  };

  const handleImagemParticipante = (usuario) => {
    if (usuario?.avatar && usuario?.avatar !== "") {
      return usuario.avatar;
    }
    if (usuario?.sexo === "masculino") {
      return UserPadraoM;
    }
    if (usuario?.sexo === "feminino") {
      return UserPadraoF;
    }
    return UserPadrao;
  };

  return (
    <div className="main">
      <div className="corpo-pagina-chat-conversa">
        <div className="header-conversa">
          <div className="dados-user-conversa">
            <img
              className="imagem-participante-chat"
              src={handleImagemParticipante(usuarioConversa)}
              alt=""
            />
            <div className="texto-user-conversa">{usuarioConversa?.nome}</div>
          </div>
          <div className="botaao-voltar" onClick={() => voltar()}>
            <FaArrowLeft />
          </div>
        </div>
        <div className={`body-conversa ${validaApple ? "espaco-apple" : ""}`}>
          <div className={`espaco-footer${validaApple ? "-apple" : ""}`}></div>
          {mensagens.map((m) => {
            if (m.visualizada_em === null) {
              visualizarMenagem(m.id);
            }
            return (
              <div
                key={m.id}
                className={`linha-mensagem ${m.id_usuario !== parseInt(id) ? "user" : ""
                  }`}
              >
                <div
                  key={m.id}
                  className={`mensagem ${m.id_usuario !== parseInt(id) ? "mensagem-user" : "left"
                    }`}
                >
                  {m.mensagem}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`footer-conversa ${validaApple ? "espaco-apple2" : ""}`}
        >
          <div className="grupo-input-conversa">
            <input
              type="text"
              className="input-mensagem"
              value={texto}
              onChange={(e) => {
                setTexto(e.target.value);
              }}
            />
            <div className="botao-enviar" onClick={() => handleEnviaMensagem()}>
              <FiSend />
            </div>
          </div>
          <div className={`espaco-footer`}></div>
        </div>
        <div className={`espaco-footer`}></div>
      </div>
    </div>
  );
};

export default Conversa;
