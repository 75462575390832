import React from "react";
import useApp from "../../../hooks/useAppContext";
import useAuth from "../../../hooks/useAuthContext";
import "./menuAdm.css";
import iconeMenu from "../../../assets/header/icone_menu.png";
import ItemMenu from "./itemMenuAdm";

import { IoMdLogOut } from "react-icons/io";
import { AiOutlineAudit } from "react-icons/ai";
import { FaPaperclip, FaUsers } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import { BiHome } from "react-icons/bi";
import {
  BsBell,
  BsStar,
  BsFillBriefcaseFill,
  BsCalendar3,
  BsListCheck,
} from "react-icons/bs";

const Menu = () => {
  const { menuIsOpen, toggleMenu } = useApp();
  const { logout, usuario } = useAuth();
  const handleMenu = () => {
    console.log("clique menu");
    toggleMenu();
  };
  let mostrar = menuIsOpen ? "mostrar-menu" : "esconder-menu";

  const handleLogout = () => {
    toggleMenu();
    logout();
  };

  return (
    <>
      <div id="container-menu" className={mostrar}>
        <div style={{ height: "100%", background: "#fff" }}>
          <div id="menu-title">
            <img
              src={iconeMenu}
              alt="icone menu"
              id="menu-botao"
              onClick={() => {
                handleMenu();
              }}
            />
            <h2 className={`titulo-menu`}>Menu de configurações</h2>
            <span id="btn-logout" onClick={() => handleLogout()} title="Logout">
              <IoMdLogOut />
            </span>
          </div>

          <div id="menu-body">
            {usuario?.tipo === "administracao" ||
              usuario?.tipo === "administracao_dash" ? (
              <>
                <ItemMenu
                  icone={<MdSpaceDashboard />}
                  label="Dashboard"
                  link="/private/adm"
                />
                <ItemMenu
                  icone={<BsStar />}
                  label="Patrocinadores"
                  link="/private/adm/patrocinadores"
                />
                <ItemMenu
                  icone={<FaUsers />}
                  label="Convidados"
                  link="/private/adm/convidados"
                />
                <ItemMenu
                  icone={<BsCalendar3 />}
                  label="Agenda Adm"
                  link="/private/adm/agenda/01"
                />
                <ItemMenu
                  icone={<AiOutlineAudit />}
                  label="Conteudos"
                  link="/private/adm/eventos/02"
                />
                <ItemMenu
                  icone={<BsFillBriefcaseFill />}
                  label="Entregas"
                  link="/private/adm/reunioes"
                />
                <ItemMenu
                  icone={<FaPaperclip />}
                  link="/portal-patrocinador"
                  label="Portal Patrocinadores"
                />
                {usuario?.tipo === "administracao" ? (
                  <ItemMenu
                    icone={<BsBell />}
                    label="Notificações"
                    link="/private/adm/notificacoes"
                  />
                ) : (
                  false
                )}
              </>
            ) : (
              false
            )}
            {usuario?.tipo === "recepcao" ? (
              <ItemMenu
                icone={<BsListCheck />}
                label="Recepção"
                link="/private/adm/recepcao/01"
              />
            ) : (
              false
            )}
            {usuario?.tipo === "administracao" ||
              usuario?.tipo === "administracao_dash" ? (
              <ItemMenu icone={<BiHome />} label="Home" link="/private/" />
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
