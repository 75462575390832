import "./agradecimento.css";
import sbfLogo from "../../assets/SBF-logo.png";

const Agradecimento = () => {
  return (
    <div className="container-agradecimento">
      <div className="barra-header"></div>
      <img src={sbfLogo} alt="SBF" className="logo-agradecimento" />
      <div
        style={{
          width: "60%",
          marginTop: "25px",
          fontSize: "19px",
          textAlign: "center",
        }}
      >
        <p>
          Agradecemos a sua inscrição. Seus dados para o seu credenciamento
          foram recebidos com sucesso! Em breve, você receberá mais informações
          sobre o app do evento.
          <br />
          Em caso de dúvidas, acione nosso time pelo e-mail:{" "}
          <b>sbf@informa.com</b>
          <br />
          <br />
          <b>Nos vemos na Chapada dos Guimarães!</b>
        </p>
      </div>
    </div>
  );
};
export default Agradecimento;
