import React, { useEffect, useState } from "react";
import "./login-verde.css";

import sbfLogo from "../../assets/SBF-logo.png";
import bg from "../../assets/2560x1140-verde.png";

import useAuth from "../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import carregando from "../../assets/Spinner-1s-200px.gif";

const LoginVerde = () => {
  const { login, validarUser, verificaStorage, usuario } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const notifyError = (mensagem) => toast.error(mensagem, { theme: "colored" });
  const notifyInfo = (mensagem) => toast.info(mensagem, { theme: "colored" });
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    handleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);

  const handleUser = async () => {
    let user = await validarUser();
    console.log("1: ", user);
    if (!user) {
      console.log("2: ", user);
      user = await verificaStorage();
    }
    console.log("3: ", user);

    if (user) {
      console.log(user);
      if (user.tipo === "convidado" || user.tipo === "patrocinador") {
        navigate("/private");
      }
      if (user.tipo === "recepcao") {
        navigate("/private/adm/recepcao/02");
      }
      if (user.tipo === "convidado") {
        navigate("/private");
      }
      if (user.tipo === "administracao" || user.tipo === "administracao_dash") {
        navigate("/private/adm");
      }
    }
    console.log(user);
  };

  const handleLogin = async () => {
    setShowLoading(true);
    let response = await login(email, password);
    if (response) {
      if (response?.status === 200) {
        console.log(response.data);
        if (
          response.data.dadosUser.tipo === "administracao" ||
          response.data.dadosUser.tipo === "administracao_dash"
        ) {
          navigate("/private/adm");
          return;
        }
        if (response.data.dadosUser.tipo === "recepcao") {
          navigate("/private/adm/recepcao/02");
          return;
        }
        navigate("/private");
      }
      if (response?.status === 401) {
        notifyInfo(response.data.mensagem);
      } else {
        notifyError(response.data.mensagem + " - " + response.data.erro);
      }
      setShowLoading(false);
    }
  };

  return (
    <div id="container-login-verde">
      <ToastContainer />
      <div id="container-central-verde">
        <img src={sbfLogo} alt="SBF" id="img-logo-verde" />
        <div id="container-inputs-verde">
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className={`btn px-5 text-white mt-2 ${showLoading ? "bt-loading" : ""
              }`}
            id="botao-acessar-verde"
            onClick={() => {
              handleLogin();
            }}
          >
            {showLoading ? (
              <img src={carregando} width="30" alt="loading" />
            ) : (
              "Acessar"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginVerde;
