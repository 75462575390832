import { useEffect, useState } from 'react'
import sbfLogo from '../../assets/SBF-logo.png'
import bg from '../../assets/2560x1140-azul.png'
import useApp from '../../hooks/useAppContext'
import { formatarDiaHora } from '../../services/conversaoTimer'
import './portal-patrocinador.css'

const PortalPatrocinador = () => {
  const { getDocs, addClickDoc } = useApp()
  const [itens, setItens] = useState([{ descricao: 'teste' }])

  useEffect(() => {
    handleItens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleItens = async () => {
    let aux_docs = await getDocs()
    setItens(aux_docs)
  }
  const handleClick = (id) => {
    addClickDoc(id)
  }

  return (
    <div className="container-patrocinador">
      <div className="barra-header"></div>
      <img src={bg} alt="SBF" id="bg-fundo-patrocinadores" />
      <img src={sbfLogo} alt="SBF" className="logo-agradecimento" />
      <div
        style={{
          width: '86%',
          marginTop: '25px',
          fontSize: '19px',
          textAlign: 'center',
          paddingBottom: '65px',
        }}
      >
        <div style={{ color: "#FFF" }}>
          <h4>Portal do Patrocinador</h4>
          <br />
          <h5>
            Olá patrocinador, tudo bem? <br />
            Seja bem-vindo à <span style={{ color: '#8fc642', fontWeight: '700' }}>21ª edição</span> do Saúde Business
            Fórum!
          </h5>
          <br />
          <small>
            Nesse link você terá acesso aos principais documentos para a sua participação no fórum,<br /> que acontecerá de 01
            a 04 de agosto de 2024 – no resort <b>Malai Manso</b>, na <b>Chapada dos Guimarães!</b> <br />
            Dessa forma, você sempre terá acesso aos dados mais atualizados. <br />
            Se ficar com qualquer dúvida nos sinalize falando com seu gerente de conta ou no e-mail:{' '}
            <b>sbf@informa.com</b>
          </small>
        </div>
        <div className="area-itens-patrocinadores">
          {itens?.map((i) => {
            return (
              <a href={i.url} target={'_blank'} rel="noreferrer" onClick={() => handleClick(i.id)}>
                <div className="item-portal-patrocinador">
                  {i.descricao}
                  <div className="item-portal-detalhe">{i.detalhes}</div>
                  <small>Última modificação: {formatarDiaHora(i?.updated_at)}</small>
                </div>
              </a>
            )
          })}
        </div>
        <small style={{ color: "#FFF" }}>
          Você já fechou a logística da sua equipe no evento? <br />
          Fale com a nossa agência de viagens parceira o mais breve possível, assim você garantirá os melhores valores para as passagens aéreas <br />
          e mais opções nas categorias dos quartos no Malai Manso (fique atento à virada dos lotes!):   <br />
          sbf@travelplustur.com.br <br />
          (11) 9 9449-4084 <br />
          <br />
          <b>Nos vemos na Chapada dos Guimarães!</b>
        </small>
      </div>
    </div>
  )
}
export default PortalPatrocinador
