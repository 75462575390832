import React, { useState, useEffect } from "react";
import ModalB from "react-bootstrap/Modal";
import "../../components/modal/modal.css";
import InputTextPatrocinador from "../../pages/cadastro/inputtextPatrocinador";
import InputRadio from "../../pages/cadastro/inputradio";

import { ToastContainer, toast } from "react-toastify";

const ModalAdmPatrociandor = (props) => {
  const [nome, setNomeCompleto] = useState("");
  const [cargo, setCargo] = useState("");
  const [nome_cracha, setNomeCracha] = useState("");
  const [email, setEmail] = useState("");
  const [dt_nascimento, setDataNascimento] = useState("");
  const [celular, setCelular] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [telefone_comercial, setTelComercial] = useState("");
  const [cpf, setCPF] = useState("");
  const [sexo, setSexo] = useState("");

  const [tituloModal, setTituloModal] = useState(props.titulo);

  const notifyError = (mensagem) => toast.error(mensagem, { theme: "colored" });

  useEffect(() => {}, []);

  const handleModal = () => props.toggleModal();

  const handleInclusao = () => {
    let participante = {
      // id: idContador,
      nome,
      cargo,
      nome_cracha,
      email,
      dt_nascimento,
      celular,
      departamento,
      telefone_comercial,
      cpf,
      sexo,
      tipo: "participante_patrocinador",
    };
    props.incluirParticipante(participante);
    limparFormulario();
    handleModal();
  };

  const limparFormulario = () => {
    setNomeCompleto("");
    setCargo("");
    setNomeCracha("");
    setEmail("");
    setDataNascimento("");
    setCelular("");
    setDepartamento("");
    setTelComercial("");
    setCPF("");
    setSexo("");
  };

  return (
    <>
      <ModalB show={props.show} onHide={handleModal}>
        <ModalB.Header closeButton>
          <ModalB.Title className="titulo-modal">{tituloModal}</ModalB.Title>
        </ModalB.Header>
        <ModalB.Body>
          <div className="area-modal-reuniao">
            <div className="area-inputs-modal">
              <InputTextPatrocinador
                label="Nome completo"
                type="text"
                requered={true}
                value={nome}
                onChange={setNomeCompleto}
                focus={true}
              />
              <InputTextPatrocinador
                label="Nome para o crachá"
                type="text"
                requered={true}
                value={nome_cracha}
                onChange={setNomeCracha}
              />

              <InputTextPatrocinador
                label="E-mail"
                type="email"
                requered={true}
                value={email}
                onChange={setEmail}
              />
              <InputTextPatrocinador
                label="CPF"
                type="text"
                requered={true}
                value={cpf}
                onChange={setCPF}
                mascara="999.999.999-99"
                countCPF={true}
              />
              <InputTextPatrocinador
                label="Data de nascimento"
                type="date"
                requered={true}
                value={dt_nascimento}
                onChange={setDataNascimento}
              />
              <InputRadio
                label="Sexo"
                values={["Masculino", "Feminino"]}
                onChange={setSexo}
                value={sexo}
              />
              <InputTextPatrocinador
                label="Telefone comercial"
                type="text"
                requered={true}
                value={telefone_comercial}
                onChange={setTelComercial}
                mascara="(99) 99999-9999"
                countCPF={true}
              />
              <InputTextPatrocinador
                label="Celular"
                type="text"
                requered={true}
                value={celular}
                onChange={setCelular}
                mascara="(99) 99999-9999"
                countCPF={true}
              />
              <InputTextPatrocinador
                label="Cargo"
                type="text"
                requered={true}
                value={cargo}
                onChange={setCargo}
              />
              <InputTextPatrocinador
                label="Departamento"
                type="text"
                requered={true}
                value={departamento}
                onChange={setDepartamento}
              />
            </div>
          </div>
        </ModalB.Body>
        {props.footer ? (
          <ModalB.Footer>
            <button
              style={{ backgroundColor: "#008A38" }}
              type="button"
              className="text-white btn botoes-cadastro"
              onClick={() => {
                handleInclusao();
              }}
            >
              Incluir
            </button>
          </ModalB.Footer>
        ) : (
          false
        )}
      </ModalB>
    </>
  );
};
export default ModalAdmPatrociandor;

