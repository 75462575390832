import React, { useState, useEffect } from "react";
import { FaPlus, FaEdit, FaSearch, FaArrowLeft } from "react-icons/fa";
import useApp from "../../hooks/useAppContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import Modal from "../../components/modal/modal";
import "./adm.css";
import useAuth from "../../hooks/useAuthContext";
import { DateTime } from "luxon";
import DetalhesParticipantesConteudo from "../../components/agenda/detalhes-participantes-conteudo";

const AdmEventos = () => {
  const { usuario } = useAuth();
  const navigate = useNavigate();
  const { getEventos } = useApp();
  const [eventos, setEventos] = useState([]);
  let { dia } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [bodyModal, setBodyModal] = useState([]);
  const [idEvento, setIdEvento] = useState(null);

  useEffect(() => {
    handleEventos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dia]);

  const handleEventos = async () => {
    let aux_eventos = await getEventos(dia);
    setEventos(aux_eventos);
  };

  const handleModalInscritos = (item) => {
    setIdEvento(item.id);
    let ordenacao = item.participantes.sort((a, b) => {
      if (a.usuario.nome > b.usuario.nome) {
        return 1;
      }
      if (a.usuario.nome < b.usuario.nome) {
        return -1;
      }
      return 0;
    });
    let now = DateTime.now();
    let aux_boryModal = ordenacao.map((ap) => {
      let verificacao = DateTime.fromISO(item.dt_hora_inicio).plus({
        hours: 3,
      });
      let status =
        now > verificacao && item.status === "futura" ? "no_show" : item.status;

      return (
        <DetalhesParticipantesConteudo
          usuario={ap.usuario}
          status={ap.presenca ? "entregue" : status}
        />
      );
    });
    setBodyModal(aux_boryModal);
    setShowModal(true);
  };

  return (
    <div className="container-adm-main">
      <Modal
        titulo="Convidados inscritos"
        show={showModal}
        toggleModal={() => setShowModal(!showModal)}
        to={"/private/adm/conteudo/imprimir/" + idEvento}
        labelTo="Imprimir"
      >
        <div className="body-modal-participantes-evento">{bodyModal}</div>
      </Modal>
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>Conteudos e Esportes</h3>
          <div className="area-filtro-dias">
            <Link
              to="/private/adm/eventos/02"
              className={`btn-header-options ${dia === "02" ? "ativo" : ""}`}
            >
              02/08
            </Link>
            <Link
              to="/private/adm/eventos/03"
              className={`btn-header-options ${dia === "03" ? "ativo" : ""}`}
            >
              03/08
            </Link>
          </div>
          <div className="header-options">
            {usuario?.tipo === "administracao" ? (
              <Link
                to="/private/adm/eventos-novo"
                className="btn-header-options"
              >
                <FaPlus title="Adicionar novo evento" />
                Novo
              </Link>
            ) : (
              false
            )}
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div>Horario</div>
              <div>Evento</div>
              <div>Inscritos</div>
              <div>Opções</div>
            </div>
            <div className="body-lista-adm">
              {eventos.length > 0 ? (
                eventos?.map((p) => {
                  let dt_inicio = p.dt_hora_inicio.substring(11, 16);
                  let dt_fim = p.dt_hora_fim.substring(11, 16);
                  return (
                    <div className="row-lista-adm">
                      <div>{`${dt_inicio} - ${dt_fim}`}</div>
                      <div>{p.nome}</div>
                      <div>{p.participantes.length}</div>
                      <div className="opcoes-lista-adm">
                        {usuario?.tipo === "administracao" ? (
                          <Link
                            to={`/private/adm/eventos-editar/${p.id}`}
                            className="btn-header-options"
                          >
                            <FaEdit title="Editar evento" />{" "}
                            <span> Editar</span>
                          </Link>
                        ) : (
                          false
                        )}
                        <div
                          className="btn-header-options"
                          onClick={() => {
                            handleModalInscritos(p);
                          }}
                        >
                          <FaSearch title="Visualizar inscritos" /> Inscritos
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row-lista-adm">
                  <div>Nenhum Evento cadastrado para essa data</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmEventos;
