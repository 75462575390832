import React, { useEffect, useState } from "react";
import ModalB from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { formatarHora } from "../../services/conversaoTimer";
import { FaCheck } from "react-icons/fa";
import api from "../../services/axios";

function ModalValidaAgendaConteudo(props) {
  const [tituloModal, setTituloModal] = useState(props.titulo);
  const [codigoValidacao, setCodigoValidacao] = useState("");
  const [respostaValidacao, setRespostaValidacao] = useState(null);
  const [mensagemErro, setMensagemErro] = useState("");
  const [usuarioVerificado, setUsuarioVerificado] = useState(false);
  const [objUsuarioVerificado, setObjUsuarioVerificado] = useState(false);

  const handleValidarCodigo = async () => {
    console.log(props.item);
    if (codigoValidacao !== "") {
      let resp = await api.post("/reunioes-valida-reuniao-conteudo", {
        pin: codigoValidacao,
        id_agenda: props.item.id,
        id_participante: props?.participante.id_usuario,
      });
      if (resp.data.reuniao) {
        setRespostaValidacao(true);
        setMensagemErro("");
        if (resp.data.usuario) {
          setUsuarioVerificado(true);
          setObjUsuarioVerificado(resp.data.usuario);
        }
      } else {
        setRespostaValidacao(false);
        setMensagemErro(resp.data.mensagem);
      }
    } else {
      alert("Digite o codigo do convidado!");
    }
  };

  useEffect(() => {
    setTituloModal(props.titulo);
    setCodigoValidacao("");
    setUsuarioVerificado(false);
    setRespostaValidacao(false);
    setMensagemErro("");
  }, [props.participante]);

  const handleModal = () => props.toggleModal();

  return (
    <>
      <ModalB show={props.show} onHide={handleModal}>
        <ModalB.Header closeButton>
          <ModalB.Title className="titulo-modal">{tituloModal}</ModalB.Title>
        </ModalB.Header>
        {/*<ModalB.Body>{props.children}</ModalB.Body>*/}
        <ModalB.Body>
          <div className={`area-modal-validacao`}>
            <div className={`d-flex`}>
              <div className={`label-hora-modal`}>
                {formatarHora(props.item.dt_hora_inicio).substring(0, 5)} /{" "}
                {formatarHora(props.item.dt_hora_fim).substring(0, 5)}
              </div>
              <div className={`label-agenda-modal`}>{props.item.nome}</div>
            </div>
            <div className={`area-input-modal`}>
              <div>Digite o codigo de {props?.participante?.usuario?.nome}</div>
              <div
                className={`d-flex justify-content-center align-items-center`}
              >
                <input
                  type="text"
                  className={`input-verificador`}
                  placeholder={`Digite aqui o cogigo`}
                  style={{ marginRight: "15px" }}
                  value={codigoValidacao}
                  onChange={(e) => {
                    setCodigoValidacao(e.target.value);
                  }}
                />
                {respostaValidacao ? <FaCheck /> : false}
              </div>
              <div>
                {respostaValidacao ? (
                  false
                ) : (
                  <div style={{ color: "red" }}>{mensagemErro}</div>
                )}
              </div>

              <div className={`area-botao-modal`}>
                {!usuarioVerificado ? (
                  <button
                    className={`btn-header-options`}
                    onClick={() => {
                      handleValidarCodigo();
                    }}
                  >
                    Verificar
                  </button>
                ) : (
                  <>
                    <div>Presença Confirmada {objUsuarioVerificado.nome}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </ModalB.Body>
        {props.to ? (
          <ModalB.Footer>
            <Link to={props.to} onClick={handleModal} className="link-padrao">
              {props.labelTo}
            </Link>
          </ModalB.Footer>
        ) : (
          false
        )}
      </ModalB>
    </>
  );
}

export default ModalValidaAgendaConteudo;
