import React from "react";

import { CadastroProvider } from "../../contexts/CadastroContext";
import TelaPerfil from "./telaPerfil";

const Perfil = () => {
  return (
    <CadastroProvider>
      <div className="main">
        <TelaPerfil />
      </div>
    </CadastroProvider>
  );
};

export default Perfil;

