import React from 'react'
import useApp from '../../hooks/useAppContext'
import useAuth from '../../hooks/useAuthContext'
import './menu.css'
import iconeMenu from '../../assets/header/icone_menu.png'
import ItemMenu from './itemMenu'

import { IoMdLogOut } from 'react-icons/io'
import { FaCogs, FaPaperclip, FaUsers, FaFileSignature } from 'react-icons/fa'
import { BiHome, BiUserCircle } from 'react-icons/bi'
import { BsBell, BsStar, BsFillChatLeftDotsFill, BsMap, BsCalendar3, BsPin, BsFillBriefcaseFill } from 'react-icons/bs'
import ItemMenuModal from './itemMenuModal'

const Menu = () => {
  const { menuIsOpen, toggleMenu } = useApp()
  const { logout, usuario } = useAuth()
  const handleMenu = () => {
    console.log('clique menu')
    toggleMenu()
  }
  let mostrar = menuIsOpen ? 'mostrar-menu' : 'esconder-menu'

  const handleLogout = () => {
    toggleMenu()
    logout()
  }

  return (
    <>
      <div id="container-menu" className={mostrar}>
        <div style={{ height: '100%', background: '#fff' }}>
          <div id="menu-title">
            <img
              src={iconeMenu}
              alt="icone menu"
              id="menu-botao"
              onClick={() => {
                handleMenu()
              }}
            />
            <h2>Menu</h2>
            <span id="btn-logout" onClick={() => handleLogout()} title="Logout">
              <IoMdLogOut />
            </span>
          </div>
          <div id="menu-body">
            <ItemMenu icone={<BiHome />} label="Home" link="/private/" />
            <ItemMenu icone={<BiUserCircle />} label="Perfil" link="/private/perfil" />
            <ItemMenu icone={<BsCalendar3 />} label="Minha Agenda" link="/private/agenda" />
            <ItemMenu icone={<BsMap />} label="Mapa" link="/private/mapa" />
            {usuario?.tipo === 'patrocinador' ? (
              <>
                <ItemMenu icone={<BsFillBriefcaseFill />} label="Reuniões" link="/private/reuniao" />
                <ItemMenu icone={<FaFileSignature />} label="Avaliação conteudos" link="/private/avaliacoes" />
              </>
            ) : (
              false
            )}
            {usuario?.tipo === 'administracao' ||
              usuario?.tipo === 'administracao_dash' ||
              usuario?.tipo === 'patrocinador' ? (
              <ItemMenu icone={<FaPaperclip />} link="/portal-patrocinador" label="Portal Patrocinadores" />
            ) : (
              false
            )}
            <ItemMenu icone={<BsFillChatLeftDotsFill />} label="Mensagens" link="/private/chat" />
            <ItemMenu icone={<BsStar />} label="Patrocinadores" link="/private/patrocinadores" />
            <ItemMenu icone={<BsBell />} label="Alertas" link="/private/notificacoes" />
            <ItemMenu icone={<FaUsers />} label="Participantes" link="/private/participantes" />
            {usuario?.tipo !== 'patrocinador' ? <ItemMenuModal icone={<BsPin />} label="Pin" /> : false}
            {usuario?.tipo === 'administracao' || usuario?.tipo === 'administracao_dash' ? (
              <ItemMenu icone={<FaCogs />} link="/private/adm" label="Configurações" />
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu
