import React, { useEffect, useState } from "react";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/axios";
import { formatarDiaHora, formatarHora } from "../../services/conversaoTimer";

const AdmEditarRuniao = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patrocinador, setPatrocinador] = useState([]);
  const [convidado, setConvidado] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [indisponivel1, setIndisponivel1] = useState("");
  const [indisponivel2, setIndisponivel2] = useState("");

  const matriz_disponibilidade = [
    "09:30",
    "10:10",
    "12:10",
    "12:50",
    // "12:30",
    // "13:10",
    // "13:40",
    "13:20",
  ];

  const matriz_disponibilidade_segundo_dia = [
    "10:30",
    "11:10",
    "11:50",
    "12:30",
    "13:10",
  ];

  useEffect(() => {
    handleAgenda();
    handleHorario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleHorario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convidado]);

  const handlePatrocinador = async (id_patrocinador) => {
    let respostaPatrocinador = await api.post(
      "/patrocinador-verifica-disponibilidade",
      { id_info: id_patrocinador }
    );
    if (respostaPatrocinador.status === 200) {
      console.log(respostaPatrocinador.data);
      setPatrocinador(respostaPatrocinador.data.patrocinador);
      setIndisponivel1(respostaPatrocinador.data.indisponivel1);
      setIndisponivel2(respostaPatrocinador.data.indisponivel2);
    }
  };

  const handleAgenda = async () => {
    let respostaAgenda = await api.post("/agenda", { adm: true, id: id });
    if (respostaAgenda.status === 200) {
      let auxPatrocinador =
        respostaAgenda.data.dados[0].agenda_participantes[1].usuario;
      console.log(auxPatrocinador);
      await handlePatrocinador(auxPatrocinador.id_patrocinador);

      let auxConvidado =
        respostaAgenda.data.dados[0].agenda_participantes[0].usuario;
      setConvidado(auxConvidado);
    }
  };

  const handleHorario = async (id_click) => {
    let respostaHorarios = await api.post(`/agenda-horarios-disponiveis`, {
      id_usuario: convidado?.id,
    });
    if (respostaHorarios.status === 200) {
      let horariosDisponiveis = [];
      matriz_disponibilidade.map((md) => {
        console.log(md);
        if (
          !respostaHorarios.data.indisponivel01.includes(md) &&
          indisponivel1[md] < patrocinador?.planos.cota_simultania
        ) {
          horariosDisponiveis.push("02/08 -" + md);
        }
      });
      matriz_disponibilidade_segundo_dia.map((md) => {
        console.log(md);
        if (
          !respostaHorarios.data.indisponivel02.includes(md) &&
          indisponivel2[md] < patrocinador?.planos.cota_simultania
        ) {
          horariosDisponiveis.push("03/08 -" + md);
        }
      });
      setHorarios(horariosDisponiveis);
    }
    setHorarioSelecionado(null);
  };

  const handleClickHorario = (horario) => {
    setHorarioSelecionado(horario);
  };

  const handleSalvar = async () => {
    console.log(patrocinador);
    let respRemarcar = await api.post("/agenda-editar", {
      id_agenda: id,
      horario: horarioSelecionado,
    });
    if (respRemarcar.status === 200) {
      alert("Reunião editada!");
      navigate(-1);
    }
  };

  return (
    <div className="container-adm-main">
      <div className="header-tela-adm">
        <h3>
          Editar reunião - {patrocinador?.patrocinador} - {convidado?.nome}
        </h3>
        <div className="header-options">
          <button
            className="btn btn-header-options"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft /> Voltar
          </button>
        </div>
      </div>
      <div className={`area-body-remarcar`}>
        {/*<div className={`bloco-remarcar`}>*/}
        {/*  <div*/}
        {/*    className={`d-flex`}*/}
        {/*    style={{ alignItems: "center", width: "345px" }}*/}
        {/*  >*/}
        {/*    <div style={{ width: "250px" }}>Lista de convidados</div>*/}
        {/*    <div style={{ marginLeft: "15px", width: "100%" }}>*/}
        {/*      <input*/}
        {/*        style={{ width: "100%" }}*/}
        {/*        placeholder={`Pesquisa por nome`}*/}
        {/*        type="text"*/}
        {/*        value={nomePesquisa}*/}
        {/*        onChange={(e) => {*/}
        {/*          handlePesquisaConvidado(e.target.value);*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  {participantes.map((p) => {*/}
        {/*    return (*/}
        {/*      <div*/}
        {/*        className={`linha-remarcar ${*/}
        {/*          usuarioSelecionado === p.id ? "ativo" : ""*/}
        {/*        }`}*/}
        {/*        onClick={() => handleHorario(p.id)}*/}
        {/*      >*/}
        {/*        {p.nome}*/}
        {/*      </div>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}
        <div className={`bloco-remarcar`}>
          <div>Lista de horarios disponiveis</div>
          {horarios.map((h) => {
            return (
              <div
                className={`linha-remarcar ${horarioSelecionado === h ? "ativo" : ""
                  }`}
                onClick={() => handleClickHorario(h)}
              >
                {h}
              </div>
            );
          })}
        </div>
        <div>
          <div
            className={`btn btn-header-options`}
            onClick={() => handleSalvar()}
          >
            Salvar
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "150px" }}></div>
    </div>
  );
};
export default AdmEditarRuniao;
