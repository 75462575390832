import { useContext } from "react";
import CadastroContext from "../contexts/CadastroContext";

function useCadastro() {
  const cadastroContext = useContext(CadastroContext);

  if (!cadastroContext) {
    throw new Error("useCadastro must be used within an CadastroProvider");
  }

  return cadastroContext;
}

export default useCadastro;
