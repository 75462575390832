import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { useFormik } from "formik";
import { NumericFormat } from "react-number-format";
import useAuth from "../../hooks/useAuthContext";

const InputText = (props) => {
  const { validarEmail } = useAuth();
  const [schema, setSchema] = useState(null);
  const [classErro, setClassErro] = useState("");
  const [erroEmail, setErroEmail] = useState(false);

  useEffect(() => {
    let tipo = props.type ? props.type : "text";
    switch (tipo) {
      case "text":
        setSchema({ valor: yup.string() });
        break;
      case "email":
        setSchema({ valor: yup.string().email("E-mail inválido") });
        break;
      case "date":
        setSchema({ valor: yup.date("Data inválida") });
        break;
      case "number":
        setSchema({ valor: yup.number("Campo inválido") });
        break;
      default:
        break;
    }
    if (props?.requered && schema?.valor) {
      setSchema({ valor: schema.valor.required("O campo é obrigatório.") });
    }

    if (props?.countCPF && schema?.valor) {
      console.log("valida cpf");
      setSchema({ valor: schema.valor.max(11, "Campo inválido") });
      setSchema({ valor: schema.valor.min(11, "Campo inválido") });
    }
  }, [props]);

  const formik = useFormik({
    initialValues: {
      valor: props.value ? props.value : "",
    },
    validationSchema: yup.object(schema),
  });

  useEffect(() => {
    if (props.value) {
      formik.setValues({ valor: props.value });
    }
  }, [props]);

  const change = (e) => {
    if (props.mascara) {
      e.target.value = e.target.value
        .replaceAll("_", "")
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll(" ", "");
    }
    formik.handleChange(e);
    if (formik.touched.valor && formik.errors.valor) {
      setClassErro("input-erro");
      props.onChange("");
    } else {
      props.onChange(e.target.value);
      setClassErro();
    }
    if (props.mascara && props.requered) {
      if (e.target.value === "") {
        formik.handleChange(e);
        setClassErro("input-erro");
        props.onChange("");
      }
    }
    props.validador();
    verificacaoEmail(e.target.value);
  };

  const blur = (e) => {
    if (props.mascara) {
      e.target.value = e.target.value
        .replaceAll("_", "")
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll(" ", "");
    }
    formik.handleBlur(e);

    if (formik.touched.valor && formik.errors.valor) {
      setClassErro("input-erro");
      props.onChange("");
    } else {
      props.onChange(e.target.value);
      setClassErro();
    }
    if (props.mascara && props.requered) {
      if (e.target.value === "") {
        setClassErro("input-erro");
        props.onChange("");
      }
    }
    props.validador();
    verificacaoEmail(e.target.value);
  };

  const verificacaoEmail = async (email) => {
    console.log("validaEmail");
    if (props.validaEmail && email !== "") {
      let respEmail = await validarEmail(email);
      console.log(respEmail);
      if (respEmail) {
        setClassErro("input-erro");
        setErroEmail(true);
      } else {
        setClassErro("");
        setErroEmail(false);
      }
    }
  };

  function formatarValorMonetario(valor) {
    let valorNumerico = valor.replace(/\D/g, '');
    valorNumerico = Number(valorNumerico) / 100;
    const valorFormatado = valorNumerico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    return valorFormatado;
  }

  return (
    <div className={`${props?.classNames ? props?.classNames : "mb-1"}`}>
      <label htmlFor="valor" className="form-label label-input">
        {props.label}
        {props.requered ? <span className="text-red">*</span> : false}
      </label>

      {props.mascara ? (
        <InputMask
          id="valor"
          name="valor"
          autoFocus={props?.focus ?? false}
          type={props?.type ? props?.type : "text"}
          mask={props.mascara}
          onChange={(e) => change(e)}
          onBlur={(e) => blur(e)}
          value={formik.values.valor}
          className={`form-control form-control-lg ${classErro}`}
        />
      ) : props.financeiro ? (
        // <NumericFormat
        //   thousandSeparator="."
        //   decimalSeparator=","
        //   prefix={"R$"}
        //   id="valor"
        //   name="valor"
        //   onValueChange={(e) => change(e)}
        //   onBlur={(e) => blur(e)}
        //   value={formik.values.valor}
        //   className={`form-control form-control-lg ${classErro}`}
        // />
        <input
          autoFocus={props?.focus ?? false}
          id="valor"
          name="valor"
          type={"number"}
          onChange={(e) => change(e)}
          onBlur={(e) => blur(e)}
          value={formik.values.valor}
          placeholder="R$ 0,00"
          className={`form-control form-control-lg ${classErro}`}
        />
      ) : (
        <input
          autoFocus={props?.focus ?? false}
          id="valor"
          name="valor"
          type={props?.type ? props?.type : "text"}
          onChange={(e) => change(e)}
          onBlur={(e) => blur(e)}
          value={formik.values.valor}
          className={`form-control form-control-lg ${classErro}`}
          disabled={props.disabled || false}
        />
      )}

      {formik.touched.valor && formik.errors.valor ? (
        <div className="erro-formulario">{formik.errors.valor}</div>
      ) : null}
      {erroEmail ? (
        <div className="erro-formulario">Este e-mail já possui cadastro </div>
      ) : null}
    </div>
  );
};
export default InputText;
