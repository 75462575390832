import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuthContext";
import InputText from "../cadastro/inputtext";
import InputRadio from "../cadastro/inputradio";
import "./perfil.css";
import useCadastro from "../../hooks/useCadastroContext";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useApp from "../../hooks/useAppContext";

const TelaPerfil = () => {
  const navigate = useNavigate();
  const { usuario, setIsAppLoaded } = useAuth();
  const { handleImagemParticipante } = useApp();
  const {
    salvarUsuario,
    nomeCompleto,
    setNomeCompleto,
    nomeCracha,
    setNomeCracha,
    email,
    setEmail,
    cpf,
    setCPF,
    dataNascimento,
    setDataNascimento,
    sexo,
    setSexo,
    telComercial,
    setTelComercial,
    celular,
    setCelular,
    nomeInstituicao,
    setnomeInstituicao,
    cargo,
    setCargo,
    departamento,
    setDepartamento,
    segmento,
    setSegmento,
    segmentoOutros,
    setSegmentoOutros,
    secretaria,
    setSecretaria,
    telSecretaria,
    setTelSecretaria,
    emailSecretaria,
    setEmailSecretaria,
    bio,
    setBio,
    setTipo,
    setAvatar,
  } = useCadastro();
  const [showSegmentoOutros, setShowSegmentoOutros] = useState(false);

  const notifyInfo = (mensagem) => toast.info(mensagem, { theme: "colored" });
  const [preview, setPreview] = useState(null);

  const handleSegmento = (e) => {
    if (e === "outros") {
      setShowSegmentoOutros(true);
    }
    setSegmento(e);
  };

  const carregaUsuario = () => {
    setNomeCompleto(usuario?.nome);
    setNomeCracha(usuario?.nome_cracha);
    setEmail(usuario?.email);
    setCPF(usuario?.cpf);
    setDataNascimento(usuario?.dt_nascimento?.substring(0, 10));
    setSexo(usuario?.sexo === "feminino" ? "Feminino" : "Masculino");
    setTelComercial(usuario?.telefone_comercial);
    setCelular(usuario?.celular);
    setnomeInstituicao(usuario?.empresa);
    setCargo(usuario?.cargo);
    setDepartamento(usuario?.departamento);
    setSegmento(usuario?.tipo_instituicao);

    if (usuario?.tipo_instituicao === "outros") {
      setShowSegmentoOutros(true);
      setSegmentoOutros(usuario?.segmento_outros);
    }

    setSecretaria(usuario?.nome_secretaria);
    setEmailSecretaria(usuario?.email_secretaria);
    setTelSecretaria(usuario?.telefone_secretaria);
    setBio(usuario?.bio);
    setTipo(usuario?.tipo);
  };

  useEffect(() => {
    carregaUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);

  const handleSalvar = async () => {
    setIsAppLoaded(false);
    let resp = await salvarUsuario(usuario.id);
    console.log(resp);

    setIsAppLoaded(true);
    notifyInfo("Dados atualizados com sucesso!");
    navigate(0);
    // carregaUsuario();
  };

  const handleChangeImage = (e) => {
    // setEnviado("true");
    if (e.target.files.length) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setAvatar(e.target.files[0]);
    }
  };

  return (
    <div className="corpo-pagina">
      <div className="area-usuario">
        <div className="area-foto-perfil">
          <label htmlFor="input-perfil">
            <img
              className="imagem-participante-perfil"
              src={handleImagemParticipante(usuario)}
              alt=""
              title="Alterar imagem de perfil"
            />
          </label>
          {usuario.tipo !== 'patrocinador' ?
            <input
              type="file"
              id="input-perfil"
              onChange={(e) => handleChangeImage(e)}
            />
            : false}
        </div>
        <div className="dados-usuario">
          <div className="nome-usuario">{usuario?.nome}</div>
          <div className="empresa-usuario">{`${usuario?.cargo} / ${usuario?.empresa}`}</div>
          <div className="pin-usuario">{`PIN: ${usuario?.pin}`}</div>
        </div>
      </div>
      {preview ? (
        <div className="area-preview">
          <img src={preview} alt="" />
          <div
            className="btn bg-green"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              handleSalvar();
            }}
          >
            {" "}
            Salvar
          </div>
        </div>
      ) : (
        false
      )}
      <div className="area-inputs-dados">
        <InputText
          label="Nome completo"
          type="text"
          requered={true}
          value={nomeCompleto}
          onChange={setNomeCompleto}
          focus={true}
          validador={() => {
            console.log("campo a");
          }}
          disabled={usuario.tipo !== 'patrocinador' ? false : true}
        />

        <InputText
          label="Nome para o crachá"
          type="text"
          requered={true}
          value={nomeCracha}
          onChange={setNomeCracha}
          validador={() => {
            console.log("campo a");
          }}
          disabled={usuario.tipo !== 'patrocinador' ? false : true}
        />
        <InputText
          label="E-mail"
          type="email"
          requered={true}
          value={email}
          onChange={setEmail}
          validador={() => {
            console.log("campo a");
          }}
          disabled={usuario.tipo !== 'patrocinador' ? false : true}
        />
        {usuario.tipo !== 'patrocinador' ? (
          <>
            <InputText
              label="CPF"
              type="text"
              requered={true}
              value={cpf}
              onChange={setCPF}
              mascara="999.999.999-99"
              countCPF={true}
              validador={() => {
                console.log("campo a");
              }}
            />
            <InputText
              label="Data de nascimento"
              type="date"
              requered={true}
              value={dataNascimento}
              onChange={setDataNascimento}
              validador={() => {
                console.log("campo a");
              }}
            />
            <InputRadio
              label="Sexo"
              values={["Masculino", "Feminino"]}
              onChange={setSexo}
              value={sexo}
            />
            <InputText
              label="Telefone comercial"
              type="text"
              requered={true}
              value={telComercial}
              onChange={setTelComercial}
              mascara="(99) 99999-9999"
              countCPF={true}
              validador={() => {
                console.log("campo a");
              }}
            />
          </>) : false}
        <InputText
          label="Celular"
          type="text"
          requered={true}
          value={celular}
          onChange={setCelular}
          mascara="(99) 99999-9999"
          countCPF={true}
          validador={() => {
            console.log("campo a");
          }}
        />
        {usuario.tipo !== 'patrocinador' ? (
          <>
            <InputText
              label="Nome da Instituição"
              type="text"
              requered={true}
              value={nomeInstituicao}
              onChange={setnomeInstituicao}
              validador={() => {
                console.log("campo a");
              }}
            />
            <InputText
              label="Cargo"
              type="text"
              requered={true}
              value={cargo}
              onChange={setCargo}
              validador={() => {
                console.log("campo a");
              }}
            />
            <InputText
              label="Departamento"
              type="text"
              requered={true}
              value={departamento}
              onChange={setDepartamento}
              validador={() => {
                console.log("campo a");
              }}
            />
            <div className="mb-1">
              <label className="form-label" htmlFor="input_procura">
                Segmento: <span className="text-red">*</span>
              </label>
              <select
                className="form-select form-control form-control-lg"
                aria-label="Default select example"
                id="input_procura"
                value={segmento}
                onChange={(e) => handleSegmento(e.target.value)}
              >
                <option value="">Selecione uma opção</option>
                <option value="operadora">Operadora</option>
                <option value="clinica">Clínica</option>
                <option value="laboratorio">Laboratório</option>
                <option value="hospital">Hospital</option>
                <option value="homecare">Homecare</option>
                <option value="outros">Outros</option>
              </select>
            </div>
            {showSegmentoOutros ? (
              <InputText
                label="Descrição"
                type="text"
                value={segmentoOutros}
                onChange={setSegmentoOutros}
                requered={showSegmentoOutros}
                validador={() => {
                  console.log("campo a");
                }}
              />
            ) : (
              false
            )}
            <InputText
              label="Nome da(o) secretária(o) "
              type="text"
              requered={true}
              value={secretaria}
              onChange={setSecretaria}
              validador={() => {
                console.log("campo a");
              }}
            />
            <InputText
              label="Telefone da(o) secretária(o)"
              type="text"
              requered={true}
              value={telSecretaria}
              onChange={setTelSecretaria}
              mascara="(99) 99999-9999"
              countCPF={true}
              validador={() => {
                console.log("campo a");
              }}
            />
            <InputText
              label="E-mail da(o) secretária(o)"
              type="email"
              requered={true}
              value={emailSecretaria}
              onChange={setEmailSecretaria}
              validador={() => {
                console.log("campo a");
              }}
            />

            <div className="mb-1 bio">
              <div className="area-texto-bio">
                <label className="form-label" htmlFor="input_bio">
                  Nos envie uma minibiografia de até 5 linhas
                </label>
                <textarea
                  className="textarea-bio"
                  rows="5"
                  onChange={(e) => setBio(e.target.value)}
                  defaultValue={bio}
                ></textarea>
              </div>
            </div>
          </>) : false}
      </div>
      <div className="area-botoes-perfil">

        <div className="alinhado-centro">
          {/* {usuario.tipo !== 'patrocinador' ? */}
          <button
            id="btn_avancar"
            style={{ backgroundColor: "#008A38" }}
            type="button"
            className="text-white btn botoes-cadastro"
            onClick={() => {
              handleSalvar();
            }}
          >
            Salvar
          </button>
          {/* : false} */}
          <Link
            to={`/cadastro/confirmacao/${usuario?.remember_me_token}`}
            style={{ backgroundColor: "#008A38" }}
            type="button"
            className="text-white btn botoes-cadastro"
          >
            Alterar Senha
          </Link>
        </div>

      </div>
    </div>
  );
};
export default TelaPerfil;
