import React, { useState, useEffect } from "react";
import ItemAgenda from "./itemAgenda";
import AgendaIconeRealizada from "../../assets/agenda/agenda_icone_realizada.png";
import AgendaIconeCinza from "../../assets/agenda/agenda_icone_cinza.png";
import Modal from "../../components/modal/modal";
import api from "../../services/axios";
import useAuth from "../../hooks/useAuthContext";
import useApp from "../../hooks/useAppContext";
import Loading from "../../assets/home/loading.gif";
import DetalhesParticipantes from "./detalhes-participantes";
import DetalhesParticipantesModal from "./detalhes-participantes-modal";

const AreaAgenda = (props) => {
  const { usuario } = useAuth();
  const [loading, setLoading] = useState(false);
  const { handleImagemParticipante, getPalestrantes } = useApp();
  const [showModal, setShowModal] = useState(false);
  const [dadosAgenda, setDadosAgenda] = useState("");
  const [itens, setItens] = useState([]);
  const [palestrantes, setPalestrantes] = useState([]);

  useEffect(() => {
    carregaItens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const carregaItens = async () => {
    console.log(props.dia);
    setLoading(true);
    let responseItens = await api.post("/agenda-usuario-list", {
      dia: props.dia ?? "01",
    });
    if (responseItens.status === 200) {
      setItens(responseItens.data.dados);
      let palestrantesAux = await getPalestrantes();
      setPalestrantes(palestrantesAux);
    }
    setLoading(false);
  };

  const handleModalAgenda = (item) => {
    console.log(item);
    let inicio = item.agenda.dt_hora_inicio.split(" ");
    let fim = item.agenda.dt_hora_fim.split(" ");
    inicio = inicio[1].substring(0, 5);
    fim = fim[1].substring(0, 5);
    let participantes = [];
    let dadosPalestrantes = [];
    if (item.agenda.tipo === "reuniao_negocio") {
      if (item.agenda.participantes) {
        // eslint-disable-next-line array-callback-return
        participantes = item.agenda.participantes.map((p, i) => {
          if (p.usuario.id !== usuario.id) {
            return <DetalhesParticipantesModal usuario={p.usuario} />;
          }
        });
      }
    }
    if (
      item.agenda.tipo === "conteudo_patrocinado" ||
      item.agenda.tipo === "conteudo_editorial"
    ) {
      if (item.agenda.convidados) {
        // eslint-disable-next-line array-callback-return
        let idPalestrantes = item.agenda.convidados.split(",");
        let ids = idPalestrantes.map((i) => {
          return parseInt(i);
        });
        dadosPalestrantes = palestrantes?.map((p, i) => {
          if (ids.includes(p.id)) {
            return <DetalhesParticipantesModal usuario={p} />;
          }
        });
      }
    }

    let detalhes = item.agenda.detalhe
      ? item.agenda.detalhe.split("<br/>")
      : [];
    let bodyModal = (
      <div className="dados-agenda">
        <div className="titulo-agenda titulo-selecionado">{`${inicio} - ${fim} - ${item.agenda.nome}`}</div>
        <div className="detalhe-agenda">{`Local: ${item.agenda.local}`}</div>
        <div className="area-agenda-detalhe">
          {detalhes.map((d) => {
            return (
              <>
                <p>{d}</p>
              </>
            );
          })}
        </div>
        {item.agenda.tipo === "conteudo_patrocinado" ||
          item.agenda.tipo === "conteudo_editorial" ? (
          <div
            className="d-flex"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            {item?.agenda.patrocinador?.logo ? (
              <img
                src={item?.agenda.patrocinador?.logo}
                alt="logo"
                width={"250px"}
                style={{ marginRight: "15px" }}
              />
            ) : (
              false
            )}
            <div style={{ fontWeight: "bold", color: "#757272" }}>
              {item?.agenda.patrocinador?.patrocinador}
            </div>
          </div>
        ) : (
          false
        )}

        <div className="area-participantes">
          {dadosPalestrantes.length ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <strong>Palestrantes</strong>
              </div>
              {dadosPalestrantes}
            </div>
          ) : (
            participantes
          )}
        </div>
      </div>
    );
    setDadosAgenda(bodyModal);
    setShowModal(!showModal);
  };

  return (
    <div id="area-agenda">
      <Modal
        titulo="Minha Agenda"
        show={showModal}
        toggleModal={() => setShowModal(!showModal)}
      >
        {dadosAgenda}
      </Modal>
      {loading ? (
        <div className="area-loading-blur">
          <div className="area-loading">
            <img src={Loading} alt="" />
          </div>
        </div>
      ) : (
        itens.length ? itens.map((item) => {
          let participante = null;
          let inicio = item.agenda.dt_hora_inicio.split(" ");
          let fim = item.agenda.dt_hora_fim.split(" ");
          inicio = inicio[1].substring(0, 5);
          fim = fim[1].substring(0, 5);

          let min = Math.ceil(1);
          let max = Math.floor(99999);
          let rand = Math.floor(Math.random() * (max - min) + min);

          let detalhes = item.agenda.tipo + " / " + item.agenda.local;
          if (item.agenda.tipo === "reuniao_negocio") {
            detalhes =
              usuario.tipo === "patrocinador"
                ? null
                : item.agenda.participantes[1].usuario.nome;

            if (item.agenda.participantes) {
              // eslint-disable-next-line array-callback-return
              participante = item.agenda.participantes.map((p, i) => {
                if (p.usuario.id !== usuario.id) {
                  return <DetalhesParticipantesModal usuario={p.usuario} />;
                }
              });
            }
          }
          if (
            item.agenda.tipo === "conteudo_patrocinado" ||
            item.agenda.tipo === "conteudo_editorial"
          ) {
            detalhes = item.agenda?.patrocinador?.patrocinador;
          }

          return (
            <ItemAgenda
              handleModalAgenda={() => handleModalAgenda(item)}
              key={rand}
              titulo={`${inicio} - ${fim} - ${item.agenda.nome}`}
              detalhe={detalhes}
              icone={
                item.presenca === null ? AgendaIconeCinza : AgendaIconeRealizada
              }
              participante={participante}
            />
          );
        }) : (
          <div className="area-vazia">Nenhum item encontrado</div>
        )
      )}
    </div>
  );
};

export default AreaAgenda;
