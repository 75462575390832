import React, { useEffect, useState } from "react";
import InputText from "./inputtext";
import useCadastro from "../../hooks/useCadastroContext";
import { NumericFormat } from "react-number-format";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const optionsPatrocindores = [
  { value: 'ALVAREZ & MARSAL', label: 'ALVAREZ & MARSAL' },
  { value: 'B BRAUN', label: 'B BRAUN' },
  { value: 'BARRFAB', label: 'BARRFAB' },
  { value: 'BIONEXO', label: 'BIONEXO' },
  { value: 'BLUECORP (Nilo)', label: 'BLUECORP (Nilo)' },
  { value: 'CIRMED', label: 'CIRMED' },
  { value: 'CLOUD SAUDE', label: 'CLOUD SAUDE' },
  { value: 'DANONE', label: 'DANONE' },
  { value: 'DRAGER', label: 'DRAGER' },
  { value: 'ECOLIMP', label: 'ECOLIMP' },
  { value: 'ELSEVIER', label: 'ELSEVIER' },
  { value: 'EY', label: 'EY' },
  { value: '8SR Gestão e Valor em Saúde', label: '8SR Gestão e Valor em Saúde' },
  { value: 'GETINGE', label: 'GETINGE' },
  { value: 'GFP SAÚDE', label: 'GFP SAÚDE' },
  { value: 'GLOBALTHINGS', label: 'GLOBALTHINGS' },
  { value: 'GRUPO DOC', label: 'GRUPO DOC' },
  { value: 'HIGGO', label: 'HIGGO' },
  { value: 'HUMANITAR', label: 'HUMANITAR' },
  { value: 'INDIGO', label: 'INDIGO' },
  { value: 'INOVA PHARMA', label: 'INOVA PHARMA' },
  { value: 'INTELECTAH', label: 'INTELECTAH' },
  { value: 'INTERSYSTEMS', label: 'INTERSYSTEMS' },
  { value: 'KONIMAGEM', label: 'KONIMAGEM' },
  { value: 'LIBBS FARMACÊUTICA', label: 'LIBBS FARMACÊUTICA' },
  { value: 'MAX EMERGENCIAS', label: 'MAX EMERGENCIAS' },
  { value: 'MEDSIMPLES', label: 'MEDSIMPLES' },
  { value: 'META HOSPITALAR', label: 'META HOSPITALAR' },
  { value: 'MILMEDIC', label: 'MILMEDIC' },
  { value: 'MV', label: 'MV' },
  { value: 'NUVIE', label: 'NUVIE' },
  { value: 'ONCO IMPORT', label: 'ONCO IMPORT' },
  { value: 'OSANA', label: 'OSANA' },
  { value: 'PHILIPS', label: 'PHILIPS' },
  { value: 'RHOPEN', label: 'RHOPEN' },
  { value: 'RTS', label: 'RTS' },
  { value: 'SALESFORCE', label: 'SALESFORCE' },
  { value: 'SANTÉ', label: 'SANTÉ' },
  { value: 'SAPORE', label: 'SAPORE' },
  { value: 'SINCRON', label: 'SINCRON' },
  { value: 'SISNAC', label: 'SISNAC' },
  { value: 'SISQUAL', label: 'SISQUAL' },
  { value: 'SKEPS', label: 'SKEPS' },
  { value: 'SOFTTEK', label: 'SOFTTEK' },
  { value: 'SOLVENTUM (antiga 3M)', label: 'SOLVENTUM (antiga 3M)' },
  { value: 'SPECIAL GASES', label: 'SPECIAL GASES' },
  { value: 'TETO', label: 'TETO' },
  { value: 'THINK DIGITAL', label: 'THINK DIGITAL' },
  { value: 'TOTVS', label: 'TOTVS' },
  { value: 'VERZANI & SANDRINI', label: 'VERZANI & SANDRINI' },
  { value: 'WOLTERS KLUWER', label: 'WOLTERS KLUWER' }
]

const CadastroPesquisa = () => {
  const {
    faturamento,
    setfaturamento,
    leitos,
    setleitos,
    vidas,
    setvidas,
    procura,
    setprocura,
    segmento,
    hobby,
    setHobby,
    outrosProcura,
    setOutrosProcura,
    // empresaInteresse,
    setEmpresaInteresse,
    verificaFormularioPesquisa,
    prioridade,
    setPrioridade,
    transformacao,
    setTransformacao,
    usoImagem,
    setUsoImagem,
  } = useCadastro();
  const [showOutros, setShowOutros] = useState(false);

  const [enteresses, setEnteresses] = useState([]);

  const handleSelectProcura = (e) => {
    if (e === "outros") {
      setShowOutros(true);
    } else {
      setShowOutros(false);
    }
    setprocura(e);
  };

  const handleSelectInteresse = (e) => {
    console.log(e)
    setEnteresses(e)
    let stringEmpresaInteresse = e.map((item) => item.value).join("; ");
    setEmpresaInteresse(stringEmpresaInteresse);
    verificaFormularioPesquisa();
  };


  useEffect(() => {
    verificaFormularioPesquisa();
    setUsoImagem(true);
  }, []);

  const handleUsoImagem = (e) => {
    if (e.target.checked) {
      setUsoImagem(true);
    } else {
      setUsoImagem(false);
    }
  };

  return (
    <>
      <h3 className="fw-bold text-center" style={{ color: "#0d8133" }}>
        Só mais algumas perguntas...
      </h3>
      <div className="area-inputs">
        <div className="mb-1">
          <label className="form-label" htmlFor="input_procura">
            O que você procura no Saúde Business Fórum?{" "}
            <span className="text-red">*</span>
          </label>
          <select
            className="form-select"
            aria-label="Default select example"
            id="input_procura"
            value={procura}
            onChange={(e) => handleSelectProcura(e.target.value)}
          >
            <option value="">Selecione uma opção</option>
            <option value="tecnologia">Tecnologia</option>
            <option value="facilities">Facilities</option>
            <option value="equipamentos">Equipamentos</option>
            <option value="homecare">Homecare</option>
            <option value="infraestrutura">Infraestrutura</option>
            <option value="consultoria">Consultoria</option>
            <option value="logistica">Logistica</option>
            <option value="consumiveis">Consumiveis</option>
            <option value="outros">Outros</option>
          </select>

          <input
            type="text"
            id="input_procura2"
            className="form-control form-control-lg oculto"
            placeholder="Escreva aqui..."
          />
        </div>

        {showOutros ? (
          <InputText
            label="Descrição"
            type="text"
            requered={showOutros}
            value={outrosProcura}
            onChange={setOutrosProcura}
            validador={verificaFormularioPesquisa}
          />
        ) : (
          false
        )}

        {/* <InputText
          label="Com qual empresa você gostaria de conversar durante o evento? "
          type="text"
          requered={false}
          value={empresaInteresse}
          onChange={setEmpresaInteresse}
          validador={() => console.log("interesse")}
        /> */}

        <label className="form-label" htmlFor="input_procura">
          Com qual empresa você gostaria de conversar durante o evento?
          <span className="text-red">*</span>
        </label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          noOptionsMessage={() => "Nenhuma empresa encontrada"}
          options={optionsPatrocindores}
          placeholder="Selecione uma ou mais empresas"
          onChange={(e) => handleSelectInteresse(e)}
          value={enteresses}
        />

        <label htmlFor="valor" className="form-label label-input">
          Qual foi o faturamento da sua instituição em 2023?
          <span className="text-red">*</span>
        </label>
        <select
          className="form-select"
          aria-label="faturamento-label"
          id="input_faturamento"
          value={faturamento}
          onChange={(e) => setfaturamento(e.target.value)}
        >
          <option value="">Selecione uma opção</option>
          <option value="Abaixo de 1M">Abaixo de 1M</option>
          <option value="Entre 1M e 300M">Entre 1M e 300M</option>
          <option value="Entre 300M e 500M">Entre 300M e 500M</option>
          <option value="Entre 500M e 1Bi">Entre 500M e 1Bi</option>
          <option value="Entre 1Bi e 5Bi">Entre 1Bi e 5Bi</option>
          <option value="Superior a 5Bi">Superior a 5Bi</option>
        </select>

        {segmento === "hospital" ? (
          <InputText
            label="Qual o número de leitos?"
            type="number"
            requered={true}
            value={leitos}
            onChange={setleitos}
            validador={verificaFormularioPesquisa}
          />
        ) : (
          false
        )}
        {segmento === "operadora" ? (
          <InputText
            label="Qual número de vidas?"
            type="number"
            requered={true}
            value={vidas}
            onChange={setvidas}
            validador={verificaFormularioPesquisa}
          />
        ) : (
          false
        )}

        <InputText
          label="Qual é a sua prioridade de investimento para 2024/2025?"
          type="text"
          requered={true}
          value={prioridade}
          onChange={setPrioridade}
          validador={verificaFormularioPesquisa}
        />

        <InputText
          label="Sobre transformação digital: qual área/solução é o seu foco atual?"
          type="text"
          requered={true}
          value={transformacao}
          onChange={setTransformacao}
          validador={verificaFormularioPesquisa}
        />

        <InputText
          label="Que tal nos contar um hobby ou curiosidade sua? Isso te ajudará a encontrar pessoas com o mesmo interesse e quem sabe, começar um bate-papo que resultará em grandes negócios! Se não quiser, basta colocar “não” abaixo."
          type="text"
          requered={true}
          value={hobby}
          onChange={setHobby}
          validador={verificaFormularioPesquisa}
        />

        <label htmlFor="valor" className="form-label label-input">
          As fotografias e imagens do evento poderão ser usadas pela Informa Markets para promover o setor e o evento. Você autoriza expressamente, a título gratuito e definitivo, a utilização de sua imagem e/ou voz em qualquer material, impresso ou virtual, no território nacional e internacional.
        </label>
        <div className="d-flex">
          <input
            type="checkbox"
            name="check-termo-aceite1"
            id="check-termo-aceite1"
            checked={usoImagem}
            onChange={(e) => handleUsoImagem(e)}
          />
          <div className="label-check">Concordo</div>
        </div>
      </div>
    </>
  );
};
export default CadastroPesquisa;
