import React, { useEffect, useState } from "react";
import "./agenda.css";

// import useApp from "../../hooks/useAppContext";

import AreaAgenda from "../../components/agenda/areaAgenda";

const Agenda = () => {
  // const { toggleModal } = useApp();
  const [aeraAgenda, setAreaAgenda] = useState(null);
  const [diaLabel, setDiaLabel] = useState("01");
  useEffect(() => {
    handleAgenda("01");
    //verificar se e dia 01/08/2024
    if (new Date().toLocaleDateString() === "01/08/2024") {
      handleAgenda("01");
    }
    if (new Date().toLocaleDateString() === "02/08/2024") {
      handleAgenda("02");
    }
    if (new Date().toLocaleDateString() === "03/08/2024") {
      handleAgenda("03");
    }
    if (new Date().toLocaleDateString() === "04/08/2024") {
      handleAgenda("04");
    }
  }, []);

  const handleAgenda = (dia) => {
    console.log("area");
    setAreaAgenda(<AreaAgenda dia={dia} />);
    setDiaLabel(dia);
  };

  return (
    <div className="main">
      <div className="area-dias">
        <div className="titulo-pagina">Minha agenda</div>
        <div className="d-flex">
          <div
            className={`item-dias ${diaLabel === "01" ? "dia-selecionado" : ""
              }`}
            onClick={() => handleAgenda("01")}
          >
            01
          </div>
          <div
            className={`item-dias ${diaLabel === "02" ? "dia-selecionado" : ""
              }`}
            onClick={() => handleAgenda("02")}
          >
            02
          </div>
          <div
            className={`item-dias ${diaLabel === "03" ? "dia-selecionado" : ""
              }`}
            onClick={() => handleAgenda("03")}
          >
            03
          </div>
          <div
            className={`item-dias ${diaLabel === "04" ? "dia-selecionado" : ""
              }`}
            onClick={() => handleAgenda("04")}
          >
            04
          </div>
        </div>
      </div>
      {aeraAgenda}
      <div className="espaco-footer"></div>
    </div>
  );
};

export default Agenda;
