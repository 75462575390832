import React, { useState, useEffect } from "react";
import useApp from "../../hooks/useAppContext";
import { formatarDiaHora } from "../../services/conversaoTimer";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AdmNotificacoes = () => {
  const navigate = useNavigate();
  const [mensagem, setMensagem] = useState("");

  const { notificacoes, novaNotificacao, getNotificacoes } = useApp();

  useEffect(() => {
    getNotificacoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNotificacao = () => {
    novaNotificacao(mensagem);
    setMensagem("");
  };

  return (
    <div className="container-adm-main">
      <div className="header-tela-adm">
        <h3>Notificações</h3>
        <div className="header-options">
          <button
            className="btn btn-header-options"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft /> Voltar
          </button>
        </div>
      </div>
      <div>
        <div className="label-notificacao"> Mensagem Notificação:</div>
        <textarea
          className="text-area-notificacao"
          rows="5"
          onChange={(e) => setMensagem(e.target.value)}
          value={mensagem}
        ></textarea>
      </div>
      <div className="area-botoes-notificacao">
        <button
          className="btn btn-sm text-white btn-enviar"
          onClick={handleNotificacao}
        >
          Enviar
        </button>
      </div>
      <div style={{ paddingBottom: "150px" }}>
        <table className="table table-hovered table-bordered text-center">
          <thead>
            <tr>
              <th width="200px">Data Envio</th>
              <th>Mensagem</th>
              <th>Visualizações</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {notificacoes.map((n, i) => {
              console.log("jhkjghkjgkjhkhn", n)
              return (
                <tr key={i}>
                  <td>{formatarDiaHora(n.created_at)}</td>
                  <td>{n.texto}</td>
                  <td>{n.count_usuarios}</td>
                  <td>
                    <button className="btn btn-sm text-white btn-reenviar">
                      Reenviar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdmNotificacoes;
