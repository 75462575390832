import React from "react";
import "./mapa.css";
import mapa from "../../assets/mapa/SBF-Planta-artística-24-1782x1694.png";
import mapa2 from "../../assets/mapa/Centro_de_Convencoes.png";

const Mapa = () => {
  return (
    <div className="main">
      <div className="mapa-container">
        <img src={mapa} alt="Mapa" className="mapa" />
        <img src={mapa2} alt="Mapa2" className="mapa" />
      </div>
    </div>
  );
};

export default Mapa;
