import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import useApp from "../../hooks/useAppContext";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/modal/modal";
import "./adm.css";
import { DateTime } from "luxon";
import DetalhesParticipantes from "../../components/agenda/detalhes-participantes";

const AdmReunioes = () => {
  const navigate = useNavigate();
  const { getReunioesNegocio, getEventos } = useApp();
  const [showModal, setShowModal] = useState(false);
  const [bodyModal, setBodyModal] = useState([]);
  const [tituloModal, setTituloModal] = useState("Reunião de negócios");
  const [horaClick, setHoraClick] = useState("02/10:20");
  const [myInterval, setMyInterval] = useState(null);

  useEffect(() => {
    // handleReunioes();
    // setInterval(() => {
    //   handleModalReunioes();
    // }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleModalReunioes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horaClick]);

  useEffect(() => {
    handleAlterarShowModal();
  }, [showModal]);

  // const handleReunioes = async () => {
  //   console.log("verificando atualizacaoes");
  //   console.log(horaClick);
  //   let aux_reunioes = await getReunioesNegocio();
  //   setReunioes(aux_reunioes);
  // };

  const handleModalReunioes = async () => {
    let indexHora =
      "2024-08-" + horaClick.substring(0, 2) + " " + horaClick.substring(3, 8);

    setTituloModal("Reunião de negócios - " + horaClick.substring(3, 8));
    let aux_reunioes = await getReunioesNegocio();
    let aux_eventos = await getEventos();
    let aux_bodyModal = aux_reunioes.filter((ap) => {
      return ap.dt_hora_inicio.substring(0, 16) === indexHora;
    });

    let eventos = aux_eventos.filter((ap) => {
      return ap.dt_hora_inicio.substring(0, 16) === indexHora && ap.tipo !== "esportes";
    });
    let now = DateTime.now();
    console.log("agora agora agora", now)

    // console.log("aux_bodyModalaux_bodyModalaux_bodyModal", aux_bodyModal)

    let reunioesOrdenadas = aux_bodyModal.sort((a, b) => {
      return a.participantes[0]?.usuario.nome.localeCompare(b.participantes[0]?.usuario.nome)
    })

    let bodyMobal = reunioesOrdenadas.map((item) => {
      let verificacao = DateTime.fromFormat(item.dt_hora_inicio, "yyyy-MM-dd HH:mm:ss")
      // .minus({
      //   hours: 8,
      // });
      console.log("verificacao verificacao ", verificacao)
      let status =
        now > verificacao && item.status === "futura" ? "no_show" : item.status;
      return (
        <div key={item.id} className={`item-participante-modal ${status}`}>
          <DetalhesParticipantes
            usuario={item.participantes[0]?.usuario}
          />
          <div className={`dados-patrocinador`}>
            {item.participantes[1]?.usuario.empresa}
          </div>
        </div>
      );
    });

    let bodyMobalEventos = eventos?.map((item) => {
      let verificacao = DateTime.fromFormat(item.dt_hora_inicio, "yyyy-MM-dd HH:mm:ss")
      let participantes = item.participantes.map((participante) => {
        let status =
          now > verificacao && participante.status === 0 ? "no_show" : "";
        return (
          <div key={participante.id} className={`item-participante-modal ${participante?.presenca ? "entregue" : status}`}>
            <DetalhesParticipantes
              usuario={participante.usuario}
            />
            <div className={`dados-eventos`}>
              {item.nome}
            </div>
          </div>
        );
      });
      return participantes
    });


    let corpoModal = (
      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }
      }>
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}>
          <div className="label-dia-reunioes">Reuniões de negócios</div>
          <div className="body-modal-participantes-evento">
            {bodyMobal}
          </div>
        </div>
        <hr />
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}>
          <div className="label-dia-reunioes">Salas de conteudos</div>
          <div className="body-modal-participantes-evento">
            {bodyMobalEventos}
          </div>
        </div>
      </div >
    )

    setBodyModal(corpoModal);
  };

  const handleAlterarHoraModal = async (hora) => {
    await setHoraClick(hora);
    setShowModal(true);
  };

  const handleAlterarShowModal = () => {
    if (showModal) {
      if (myInterval) {
        clearInterval(myInterval);
        let timer = setInterval(() => {
          handleModalReunioes();
        }, 5000);
        setMyInterval(timer);
      } else {
        let timer = setInterval(() => {
          handleModalReunioes();
        }, 5000);
        setMyInterval(timer);
      }
    } else {
      if (myInterval) {
        clearInterval(myInterval);
      }
    }
  };

  return (
    <div className="container-adm-main">
      <Modal
        titulo={tituloModal}
        show={showModal}
        toggleModal={() => setShowModal(!showModal)}
      >
        <div className="body-modal-participantes-evento">{bodyModal}</div>
      </Modal>
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>Entregas</h3>
          <div className="header-options">
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className={`label-dia-reunioes`}>02/08/2024</div>
          <div className="area-botoes-reuniao">
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("02/10:20")}
            >
              10H20
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("02/11:00")}
            >
              11H00
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("02/11:40")}
            >
              11H40
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("02/12:20")}
            >
              12H20
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("02/12:50")}
            >
              12H50
            </div>
          </div>
          <div className={`label-dia-reunioes`}>03/08/2024</div>
          <div className="area-botoes-reuniao">
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("03/10:30")}
            >
              10H30
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("03/11:10")}
            >
              11H10
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("03/11:50")}
            >
              11H50
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("03/12:30")}
            >
              12H30
            </div>
            <div
              className="bt-hora"
              onClick={() => handleAlterarHoraModal("03/13:10")}
            >
              13H10
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmReunioes;
