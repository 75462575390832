import React from "react";
import "./footer.css";
import useApp from "../../hooks/useAppContext";
import { Link } from "react-router-dom";

import iconeAgenda from "../../assets/footer/icone_agenda.png";
import iconeChat from "../../assets/footer/icone_chat.png";
import iconeHome from "../../assets/footer/icone_home.png";

const Footer = () => {
  const { menuIsOpen, toggleMenu } = useApp();
  const handleMenu = () => {
    if (menuIsOpen) {
      toggleMenu();
    }
  };
  return (
    <div id="container-footer">
      <div id="container-footer-icones">
        <Link to="/private/" className="small-box-footer">
          <img
            src={iconeHome}
            className="icone-footer"
            alt="home"
            onClick={handleMenu}
          />
        </Link>
        <Link to="/private/agenda" className="small-box-footer">
          <img
            src={iconeAgenda}
            className="icone-footer"
            alt="agenda"
            onClick={handleMenu}
          />
        </Link>
        <Link to="/private/chat" className="small-box-footer">
          <img
            src={iconeChat}
            className="icone-footer"
            alt="chat"
            onClick={handleMenu}
          />
        </Link>
      </div>
    </div>
  );
};

export default Footer;
