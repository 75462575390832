import { useState } from "react";
import useApp from "../../hooks/useAppContext";

const DetalhesParticipantes = (props) => {
  const { handleImagemParticipante } = useApp();
  const [classTamanho, setClassTamanho] = useState("pequeno");

  const handleTamanhoImagem = () => {
    if (classTamanho === "pequeno") {
      setClassTamanho("grande");
      setTimeout(() => {
        setClassTamanho("pequeno");
      }, 3000);
    } else {
      setClassTamanho("pequeno");
    }
  };

  const detalhes = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="nome-convidado-adm" style={{ fontWeight: "500", color: "#404040", fontSize: "15px" }}>
        {props.usuario.nome}
      </div>
      <div>
        <hr style={{ padding: 0, margin: "4px" }}></hr>
      </div>
      <div style={{ fontSize: "12px", fontWeight: "700", color: "#000" }}>{`${props.usuario?.cargo ? props.usuario?.cargo + " /" : ""
        }  ${props.usuario?.empresa ? props.usuario?.empresa : ""}`}</div>
    </div>
  );

  const detalheslink = (
    <a
      title="Conversar pelo Whatsapp"
      href={`https://wa.me/55${props.usuario.celular}`}
      target={"_black"}
      style={{ textDecoration: "none", color: "#000" }}
    >
      {detalhes}
    </a>
  );

  // const detalhes = props.usuario.celular ? (
  //   <a
  //     title="Conversar pelo Whatsapp"
  //     href={`https://wa.me/55${props.usuario.celular}`}
  //     target={"_black"}
  //     style={{ textDecoration: "none", color: "#000" }}
  //   >
  //     <div style={{ fontWeight: "400", color: "#000" }}>
  //       {props.usuario.nome}
  //     </div>
  //     <div>{`${props.usuario?.cargo ? props.usuario?.cargo + "/" : ""}  ${
  //       props.usuario.empresa
  //     }`}</div>
  //   </a>
  // ) : (
  //   <div onClick={() => alert("Convidado não tem telefone cadastrado.")}>
  //     <div style={{ fontWeight: "400", color: "#000" }}>
  //       {props.usuario.nome}
  //     </div>
  //     <div>{`${props.usuario?.cargo ? props.usuario?.cargo + "/" : ""}  ${
  //       props.usuario.empresa
  //     }`}</div>
  //   </div>
  // );

  // return (
  //   <div
  //     className={`participante-agenda-${classTamanho}`}
  //     style={{ alignItems: "center" }}
  //   >
  //     <img
  //       src={handleImagemParticipante(props.usuario)}
  //       alt=""
  //       className={`imagem-participante-${classTamanho}`}
  //       onClick={() => handleTamanhoImagem()}
  //     />
  //     <div className="dados-participante">{detalhes}</div>
  //   </div>
  // );

  return (
    <div
      className={`participante-agenda-${classTamanho} ${props.status}`}
      style={{ alignItems: "center" }}
    >
      <img
        src={handleImagemParticipante(props.usuario)}
        alt=""
        className={`imagem-participante-${classTamanho}-modal`}
        onClick={() => handleTamanhoImagem()}
      />
      <div className="dados-participante" style={{ display: "flex" }}>
        {props.usuario.celular ? detalheslink : detalhes}
      </div>
    </div>
  );
};
export default DetalhesParticipantes;
