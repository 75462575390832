import React from "react";
import { Link } from "react-router-dom";
import useApp from "../../../hooks/useAppContext";
const ItemMenu = ({ label, icone, iconeIm, link }) => {
  const { toggleMenu } = useApp();

  const handleMenuClick = () => {
    console.log("clique item menu");
    toggleMenu();
  };

  const iconeItem = iconeIm ? <img src={iconeIm} alt="icone" /> : icone;

  return (
    <>
      <Link to={link ?? "/"} className="small-box-footer link-menu">
        <div
          className="container-item-menu"
          onClick={() => {
            handleMenuClick();
          }}
        >
          <div className={`item-menu-icone${label ? "-label" : ""}`}>
            {iconeItem}
          </div>
          {label ? (
            <div className="item-menu-label">{label ?? "label"}</div>
          ) : (
            false
          )}
        </div>
      </Link>
    </>
  );
};

export default ItemMenu;
