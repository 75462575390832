import axios from "axios";

const api = axios.create({
  // baseURL:  'https://fs-apps.xyz:3333',
 // baseURL: "http://localhost:3333",
   baseURL: "https://app.saudebusiness.com:3333",
  //   baseURL:`${process.env.REACT_APP_URI_API}`
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token-sbf");

  if (token !== "" && token !== null) {
    config.headers.Authorization = token;
  }
  return config;
});

export default api;
