import React, { useState, useEffect } from 'react'
import { FaArrowLeft, FaSave } from 'react-icons/fa'
import useApp from '../../hooks/useAppContext'
import { useNavigate, useParams } from 'react-router-dom'
import './adm.css'
import useAuth from '../../hooks/useAuthContext'
import InputText from '../cadastro/inputtext'
import { toast } from 'react-toastify'

const AdmPortalPatrocinadorNovo = () => {
  const { id } = useParams()
  const { usuario } = useAuth()
  const navigate = useNavigate()
  const { incluirDocs, getDoc, alterarDocs } = useApp()
  const [descricao, setDescricao] = useState('')
  const [detalhes, setDetalhes] = useState('')
  const [posicao, setPosicao] = useState('0')
  const [arquivo, setArquivo] = useState(null)
  const [nomeArquivo, setNomeArquivo] = useState(null)

  useEffect(() => {
    if (id) {
      handleDocUpdate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDocUpdate = async () => {
    let response = await getDoc(id)
    console.log(response)
    if (response.dados) {
      setDescricao(response.dados.doc.descricao)
      setDetalhes(response.dados.doc.detalhes)
      setPosicao(response.dados.doc.posicao)
      setNomeArquivo(response.dados.doc.url.split('/').pop())
    }
  }

  const salvarItem = async () => {
    if (id) {
      let response = await alterarDocs(id, descricao, detalhes, posicao, arquivo ?? null)
      if (response.status === 200) {
        toast.success(response.data.mensagem)
        navigate('/private/adm/portal-patrocinador')
      }
    } else {
      if (arquivo) {
        let response = await incluirDocs(descricao, detalhes, posicao, arquivo)
        if (response?.status === 201) {
          toast.success(response.data.mensagem)
          navigate('/private/adm/portal-patrocinador')
        }
      } else {
        toast.warn('o campo arquivo é obrigatorio')
      }
    }
  }

  const handleArquivo = (e) => {
    if (e.target.files.length) {
      console.log(e.target.files[0])
      setNomeArquivo(`${e.target.files[0].name} - ${e.target.files[0].size} bytes`)
      setArquivo(e.target.files[0])
    }
  }

  return (
    <div className="container-adm-main">
      <div style={{ paddingBottom: '150px' }}>
        <div className="header-tela-adm">
          <h3>Portal Patrocinador</h3>
          <div className="header-options">
            {usuario?.tipo === 'administracao' ? (
              <div title="Adicionar novo item" className="btn-header-options" onClick={() => salvarItem()}>
                <FaSave />
                Salvar
              </div>
            ) : (
              false
            )}
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1)
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div>Incluir nova documentação</div>
          <div className="area-inputs">
            <div className="row col-12">
              <div className="col-10">
                <InputText
                  label="Descricao"
                  type="text"
                  requered={true}
                  value={descricao}
                  onChange={setDescricao}
                  focus={true}
                  validador={() => {
                    console.log('campo descricao')
                  }}
                />
              </div>
              <div className="col-2">
                <InputText
                  label="Posição"
                  type="number"
                  requered={true}
                  value={posicao}
                  onChange={setPosicao}
                  validador={() => {
                    console.log('campo posicao')
                  }}
                />
              </div>
            </div>
            <div className="row col-12">
              <InputText
                label="Detalhes"
                type="text"
                requered={true}
                value={detalhes}
                onChange={setDetalhes}
                validador={() => {
                  console.log('campo detalhe')
                }}
              />
            </div>
            <label>Arquivo: </label>
            <input type="file" style={{ marginLeft: '8px', marginTop: '8px' }} onChange={(e) => handleArquivo(e)} />

            {nomeArquivo ? (
              <div className="fw-bold">
                {' '}
                {`>`} {nomeArquivo}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdmPortalPatrocinadorNovo
