import React, { useState } from "react";
import useAuth from "../../hooks/useAuthContext";
import Modal from "react-bootstrap/Modal";

const ItemMenuModal = ({ label, icone, iconeIm }) => {
  const { usuario } = useAuth();

  const iconeItem = iconeIm ? <img src={iconeIm} alt="icone" /> : icone;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const togleModal = () => {
    setShow(!show);
  };

  const modal = (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pin do usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className={`menu-modal`}> {usuario?.pin} </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="btn btn-header-options">
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );

  return (
    <>
      {modal}
      <div
        className="small-box-footer link-menu"
        onClick={() => {
          togleModal();
        }}
      >
        <div className="container-item-menu">
          <div className={`item-menu-icone${label ? "-label" : ""}`}>
            {iconeItem}
          </div>
          {label ? (
            <div className="item-menu-label">{label ?? "label"}</div>
          ) : (
            false
          )}
        </div>
      </div>
    </>
  );
};

export default ItemMenuModal;
