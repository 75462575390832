import React, { createContext, useCallback, useState } from "react";
import api from "../services/axios";
import UserPadrao from "../assets/participantes/user_padrao.jpg";
import UserPadraoM from "../assets/participantes/Ícone-1-200x200.jpg";
import UserPadraoF from "../assets/participantes/Ícone-2-200x200.jpg";
import useAuth from "../hooks/useAuthContext";
import { toast } from "react-toastify";

const AppContext = createContext({});
export default AppContext;

export const AppProvider = ({ children }) => {
  const { usuario } = useAuth();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [mostraMenuAdm, setMostraMenuAdm] = useState(true);
  const [apresentarModal, setApresentarModal] = useState(false);

  const [notificacoes, setNotificacoes] = useState([]);
  const [notificacoesUsuario, setNotificacoesUsuario] = useState([]);
  const [formValido, setFormValido] = useState(false);
  const [formValidoPesquisa, setFormValidoPesquisa] = useState(false);
  const [formValidoBio, setFormValidoBio] = useState(false);
  const [pesquisasUsuario, setPesquisasUsuario] = useState([]);
  const [tipoUsuario, setTipoUsuario] = useState(null);

  const toggleMenu = useCallback(() => {
    try {
      setMenuIsOpen(!menuIsOpen);
    } catch (error) {
      console.log("toggle menu error:", error, error.response?.data);
    }
  }, [menuIsOpen]);

  const toggleModal = useCallback(() => {
    try {
      setApresentarModal(!apresentarModal);
    } catch (error) {
      console.log("toggleModal error:", error, error.response?.data);
    }
  }, [apresentarModal]);

  const novaNotificacao = async (texto) => {
    try {
      const resp = await api.post("/notificacoes", { texto, tipo: "geral" });
      if (resp.status === 200) {
        getNotificacoes();
        consultarNotificacoes();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("login error:", error, error.response?.data);
    }
  };

  const getNotificacoes = async () => {
    try {
      const resp = await api.get("/notificacoes");
      if (resp.status === 200) {
        setNotificacoes(resp.data.dados);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const consultarNotificacoes = async () => {
    try {
      if (usuario && usuario?.tipo === 'recepcao') return
      const resp = await api.get("/notificacoes-usuario");
      if (resp.status === 200) {
        let auxNotificacores = resp.data.dados;
        setNotificacoesUsuario(auxNotificacores);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const visualizarNotificacao = async (id_notificacao) => {
    try {
      const resp = await api.post("/notificacoes-visualizar", {
        id: id_notificacao,
      });
      if (resp.status === 200) {
        consultarNotificacoes();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getPatrocinadores = async () => {
    try {
      const resp = await api.get("/patrocinador");
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getPatrocinadoresLista = async () => {
    try {
      const resp = await api.get("/patrocinador-lista");
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getPatrocinador = async (id_patrocinador) => {
    try {
      const resp = await api.get("/patrocinador/" + id_patrocinador);
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getUsuario = async (id_usuario) => {
    try {
      const resp = await api.post("/usuarios", { id: id_usuario });
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const insertUsuarioAdm = async (
    nome,
    cpf,
    dt_nascimento,
    email,
    tipo,
    nome_cracha,
    sexo,
    celular,
    empresa,
    cargo,
    telefone_comercial,
    departamento,
    tipo_instituicao,
    segmento_outros,
    id_patrocinador
  ) => {
    try {
      let user = {
        nome,
        cpf,
        dt_nascimento,
        email,
        tipo,
        nome_cracha,
        sexo,
        celular,
        empresa,
        cargo,
        telefone_comercial,
        departamento,
        tipo_instituicao,
        segmento_outros,
        id_patrocinador,
      };

      const resp = await api.post("/usuarios-create/", user);
      if (resp.status === 200) {
        return resp.data.dados;
      }
      console.log(user);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const atualizaPatrocinadorAdm = async (
    patrocinador,
    perfil,
    email,
    logo,
    id
  ) => {
    try {
      const formData = new FormData();

      formData.append("patrocinador", patrocinador);
      formData.append("perfil", perfil);
      formData.append("email", email);
      if (logo) {
        formData.append("logo", logo);
      }
      if (id) {
        formData.append("id", id);
      }

      const resp = await api.post("/patrocinador-update/", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getEventos = async (dia) => {
    try {
      const resp = await api.post("/agenda", {
        tipo: ["conteudo_patrocinado", "esporte", "conteudo_editorial"],
        dia,
        adm: true,
      });
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getAgenda = async (dia) => {
    try {
      const resp = await api.post("/agenda", {
        tipo: [
          "conteudo_patrocinado",
          "conteudo_editorial",
          "esporte",
          "geral",
        ],
        dia,
        adm: true,
      });
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getAgendaRecepcao = async (dia, patrocinador, tipo) => {
    try {
      let resp;
      if (patrocinador) {
        resp = await api.post("/agenda-recepcao", {
          tipo,
          dia,
          patrocinador,
        });
      } else {
        resp = await api.post("/agenda-recepcao-conteudo", {
          tipo,
          dia,
          patrocinador,
        });
      }
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getReunioesNegocio = async () => {
    try {
      const resp = await api.post("/agenda", {
        tipo: ["reuniao_negocio"],
        adm: true,
      });
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getEventoId = async (id) => {
    try {
      const resp = await api.post("/agenda", {
        id,
        adm: true,
      });
      if (resp.status === 200) {
        return resp.data.dados[0];
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getMensagens = async () => {
    try {
      const resp = await api.get("/chat-conversas");
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getMensagensChat = async (id_usuario) => {
    try {
      const resp = await api.get("/chat-conversas/" + id_usuario);
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const enviaMenagem = async (id_usuario, mensagem) => {
    try {
      let dados = {
        destino: id_usuario,
        mensagem,
      };
      const resp = await api.post("/chat-mensagens/", dados);
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const visualizarMenagem = async (id_chat_mensagem) => {
    try {
      let dados = {
        id_chat_mensagem,
      };
      const resp = await api.post("/chat-visualizar/", dados);
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const verificaDispositivo = () => {
    let navigatorObject = window.navigator;
    console.log(navigatorObject)
    // noinspection RedundantIfStatementJS
    if (navigatorObject.userAgent.includes("iPhone")) {
      return true;
    }
    return false;
  };

  const handleImagemParticipante = (usuarioImg) => {
    if (usuarioImg?.avatar && usuarioImg?.avatar !== "") {
      return usuarioImg?.avatar;
    }
    if (usuarioImg?.sexo === "masculino") {
      return UserPadraoM;
    }
    if (usuarioImg?.sexo === "feminino") {
      return UserPadraoF;
    }
    return UserPadrao;
  };

  const redefinirSenha = async (id_usuario) => {
    try {
      const resp = await api.post("/usuarios-redefinir-senha/", {
        id: id_usuario,
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const criarUsuarioPatrocinadorAdm = async (id_info) => {
    try {
      const resp = await api.post("/patrocinador-criar-usuario", { id_info });
      if (resp.status === 200) {
        console.log("criar usuario", resp.data);
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
      return false
    }
  };

  const excluirAgenda = async (id_agenda) => {
    try {
      const resp = await api.post("/agenda-delete", { id_agenda });
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const verificaPesquisa = async () => {
    try {
      let pesquisas = await api.post("/verifica-pesquisa", {});

      if (pesquisas.status === 200) {
        setPesquisasUsuario(pesquisas.data.pesquisas);
        setTipoUsuario(pesquisas.data.usuarioTipo);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const getPalestrantes = async () => {
    try {
      let ids = [518, 519, 520, 521, 523, 524, 525, 530, 557, 643, 664];
      const resp = await api.post("/usuarios-apenas-user", { ids });
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      console.log("error:", error, error.response?.data);
    }
  };

  const getDocs = async () => {
    try {
      const resp = await api.get("/docs");
      if (resp.status === 200) {
        return resp.data.dados;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const excluirDocs = async (id) => {
    try {
      const resp = await api.delete("/docs/" + id);
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const incluirDocs = async (descricao, detalhes, posicao, doc) => {
    const formData = new FormData();
    formData.append("descricao", descricao);
    formData.append("detalhes", detalhes);
    formData.append("posicao", posicao);
    formData.append("doc", doc);
    try {
      const resp = await api.post("/docs", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      if (resp.status === 201) {
        return resp;
      }
    } catch (error) {
      console.log("error:", error, error.response?.data.mensagem);
      if (error.response?.status === 413) {
        toast.error("Erro ao salvar item, arquivo enviado é muito grande.");
      }
    }
  };

  const getDoc = async (id) => {
    try {
      const resp = await api.get("/docs/" + id);
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const alterarDocs = async (id, descricao, detalhes, posicao, doc = null) => {
    console.log(descricao, posicao, doc);
    const formData = new FormData();
    formData.append("descricao", descricao);
    formData.append("detalhes", detalhes);
    formData.append("posicao", posicao);
    if (doc) {
      formData.append("doc", doc);
    }
    try {
      const resp = await api.put("/docs/" + id, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      if (resp.status === 200) {
        return resp;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  const addClickDoc = async (id) => {
    try {
      const resp = await api.post("/docs-add-click", { id });
      if (resp.status === 200) {
        return resp;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error, error.response?.data);
    }
  };

  return (
    <AppContext.Provider
      value={{
        menuIsOpen,
        toggleMenu,
        apresentarModal,
        toggleModal,
        notificacoes,
        getNotificacoes,
        novaNotificacao,
        notificacoesUsuario,
        visualizarNotificacao,
        consultarNotificacoes,
        getPatrocinadores,
        getEventos,
        getEventoId,
        getMensagens,
        getMensagensChat,
        enviaMenagem,
        verificaDispositivo,
        handleImagemParticipante,
        visualizarMenagem,
        formValido,
        setFormValido,
        formValidoPesquisa,
        setFormValidoPesquisa,
        formValidoBio,
        setFormValidoBio,
        getUsuario,
        redefinirSenha,
        getPatrocinador,
        insertUsuarioAdm,
        atualizaPatrocinadorAdm,
        getAgenda,
        getReunioesNegocio,
        mostraMenuAdm,
        setMostraMenuAdm,
        getAgendaRecepcao,
        criarUsuarioPatrocinadorAdm,
        excluirAgenda,
        getPatrocinadoresLista,
        verificaPesquisa,
        pesquisasUsuario,
        setPesquisasUsuario,
        tipoUsuario,
        getPalestrantes,
        getDocs,
        excluirDocs,
        incluirDocs,
        getDoc,
        alterarDocs,
        addClickDoc,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
