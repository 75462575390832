import React, { useEffect, useState } from "react";
import useApp from "../../hooks/useAppContext";
import { useParams } from "react-router-dom";
import { formatarDiaHora } from "../../services/conversaoTimer";
import api from "../../services/axios";
import logo from "../../assets/SBF-logo.png";
import "./print.css";

const AdmImprimirReunioesConvidados = () => {
  let { id } = useParams();
  const { getUsuario, setMostraMenuAdm } = useApp();
  const [convidado, setConvidado] = useState(null);
  const [reunioes, setReunioes] = useState([]);

  useEffect(() => {
    setMostraMenuAdm(false);
    if (id) {
      carregaUsuario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carregaUsuario = async () => {
    let convidado = await getUsuario(id);
    console.log("convidado", convidado)

    setConvidado(convidado[0]);
    if (convidado[0].plano) {
      let respPatrocinador = await api.post(
        "/patrocinador-verifica-disponibilidade",
        { id_info: convidado[0].infoPatrocinador.id }
      );

      let patro = respPatrocinador.data.patrocinador;

      let arrayIds = [];
      // eslint-disable-next-line array-callback-return
      patro.usuario?.agendaParticipante?.map((ap) => {
        if (ap.agenda?.tipo === "reuniao_negocio") {
          arrayIds.push(ap.id_agenda);
        }
      });
    }

    let ordenacao = convidado[0].agendaParticipante.sort(function (a, b) {
      let dataA = new Date(a.agenda.dt_hora_inicio);
      let dataB = new Date(b.agenda.dt_hora_inicio);
      return dataA < dataB ? -1 : dataA > dataB ? 1 : 0;
    });

    setReunioes(ordenacao);
  };

  return (
    <div className="container-adm-main">
      <div id={`printable`}>
        <div className={`area-imagem-logo`}>
          <img src={logo} alt="logo" style={{ filter: 'invert(1)' }} />
        </div>
        <div className="header-tela-adm-print">
          <h3>Agenda - {convidado?.nome}</h3>
          <div className="header-options"></div>
        </div>
        <div className="body-tela-adm">
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div>Horário</div>
              <div>Convidado</div>
              <div>Cargo</div>
              <div>Instituição</div>
            </div>
            <div className="body-lista-adm">
              {reunioes.length ? (
                reunioes.map((r) => {
                  if (r.agenda.tipo === "reuniao_negocio") {
                    let convidadoReuniao = r.agenda.participantes.filter(
                      (ap) => {
                        return ap.id_usuario !== parseInt(id);
                      }
                    );
                    return convidadoReuniao.length ? (
                      <div key={r.id} className="row-lista-adm-print">
                        <div>
                          {formatarDiaHora(r.agenda.dt_hora_inicio).substring(
                            0,
                            16
                          )}
                        </div>
                        <div>{convidadoReuniao[0]?.usuario.nome}</div>
                        <div>
                          {convidadoReuniao[0]?.usuario.cargo &&
                            convidadoReuniao[0]?.usuario.cargo !== "null"
                            ? convidadoReuniao[0]?.usuario.cargo
                            : "-"}
                        </div>
                        <div>{convidadoReuniao[0]?.usuario.empresa}</div>
                      </div>
                    ) : (
                      false
                    );
                  } else {
                    if (
                      r.agenda.tipo === "conteudo_patrocinado" ||
                      r.agenda.tipo === "conteudo_editorial"
                    ) {
                      return (
                        <div key={r.id} className="row-lista-adm-print">
                          <div>
                            {formatarDiaHora(r.agenda.dt_hora_inicio).substring(
                              0,
                              16
                            )}
                          </div>
                          <div>{r.agenda.nome}</div>
                          <div>-</div>
                          <div>- </div>
                        </div>
                      );
                    } else {
                      return false;
                    }
                  }
                })
              ) : (
                <div className="row-lista-adm">
                  <div>Sem reuniões agenadas</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmImprimirReunioesConvidados;
