import React, { useEffect } from "react";
import ModalB from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function ModalPatrocinador(props) {
  useEffect(() => {
    console.log(props);
  }, [props]);

  const handleModal = () => props.toggleModal();

  return (
    <>
      <ModalB show={props.show} onHide={handleModal}>
        <ModalB.Header closeButton>
          <ModalB.Title className="titulo-modal">
            {props?.item?.patrocinador}
          </ModalB.Title>
        </ModalB.Header>
        <ModalB.Body>
          <div className={`modal-perfil`}>
            <img
              src={props?.item?.logo}
              alt="logo"
              className="imagem-logo-modal"
            />
            {props?.item?.perfil ? (
              <div className={`texto-modal-perfil`}>
                {props?.item?.perfil !== "null" ? props?.item?.perfil : ""}
              </div>
            ) : (
              false
            )}
          </div>
          {/*<div className={`label-contatos`}>Contatos</div>*/}
          {/*{props?.item?.usuarios.map((u) => {*/}
          {/*  return u.id !== props.item.id_usuario ? (*/}
          {/*    <div className={`linha-contatos`}>*/}
          {/*      <span className={`span-nome`}>{u.nome} - </span> {u.email} -{" "}*/}
          {/*      {u.telefone_comercial}*/}
          {/*    </div>*/}
          {/*  ) : (*/}
          {/*    false*/}
          {/*  );*/}
          {/*})}*/}
        </ModalB.Body>
        {props.to ? (
          <ModalB.Footer>
            <Link to={props.to} onClick={handleModal} className="link-padrao">
              {props.labelTo}
            </Link>
          </ModalB.Footer>
        ) : (
          false
        )}
      </ModalB>
    </>
  );
}

export default ModalPatrocinador;
