import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import api from "../../services/axios";

const LinhaModalReunioes = (props) => {
  const [showSelecao, setShowSelecao] = useState(false);
  const [disponibilidade, setDisponibilidade] = useState([]);
  const [hora, setHora] = useState("");

  useEffect(() => {
    setShowSelecao(false);
    setHora("");
  }, [props.dia]);

  const handleLinha = () => {
    console.log(props?.participante.mostrarbotao);
    setShowSelecao(!showSelecao);
    carregaSelectDisponibilidade();
  };

  const carregaSelectDisponibilidade = () => {
    console.log(props.participante);
    let array_dispo = props.participante.selectdisponibilidade.split(" ");
    let select = array_dispo.map((ad) => {
      if (ad !== "") {
        return <option value={ad}>{ad}</option>;
      }
    });
    setDisponibilidade(select);
  };

  const salvarReuniao = async (id_interessado, id_convidado, horario, dia) => {
    if (hora !== "") {
      props.setLoading(true);
      let dados = { id_interessado, id_convidado, horario, dia };
      console.log(dados);
      let resp = await api.post("/reunioes", dados);
      if (resp.status === 200) {
        if (resp.data?.status === "erro") {
          alert(resp.data?.mensagem);
        }
        props.carregarParticipantes();
        setShowSelecao(!showSelecao);
      }
    } else {
      alert("escolha um horario!");
    }
  };

  return (
    <>
      <div
        className={`row-lista-adm ${showSelecao ? "ativo" : ""}`}
        onClick={() => {
          // props.dia === "04" ? console.log("a") : handleLinha();
          handleLinha();
        }}
      >
        <div>{props.participante.nome}</div>
        <div>{props.participante.empresa}</div>
        <div>{props.participante.cargo}</div>
        <div>{props.participante.disponibilidade}</div>
      </div>
      {showSelecao ? (
        <div className="area-selecao-reuniao">
          {props.jaMarcado ? (
            <div className={`text-red`}>
              Já existe uma reunião com esse convidado
            </div>
          ) : (
            false
          )}
          <div>
            <select
              className="form-select form-control form-control-xs"
              value={hora}
              onChange={(e) => setHora(e.target.value)}
            >
              <option value="">Selecione o horario</option>
              {disponibilidade}
            </select>
          </div>

          <div>
            {props?.mostrarIncluir && props?.participante.mostrarbotao ? (
              <button
                type="button"
                className="text-white btn botoes-cadastro-salvar"
                onClick={() => {
                  salvarReuniao(
                    props.id,
                    props.participante.id,
                    hora,
                    props.dia
                  );
                }}
              >
                <FaPlus /> Confirmar
              </button>
            ) : (
              false
            )}
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
};
export default LinhaModalReunioes;
