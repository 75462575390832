import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuthContext";
import api from "../../services/axios";
import "./avaliacoes.css";
import { FaDownload, FaQuestion } from "react-icons/fa";
import exportToExcel from "../../services/exportExcel";
import { formatarDiaHora } from "../../services/conversaoTimer";
import ModalPesquisa from "../../components/modal/modalPesquisa";
import useApp from '../../hooks/useAppContext'

const Avaliacoes = () => {
  const { usuario } = useAuth();
  const { tipoUsuario, pesquisasUsuario } = useApp()
  const [resultados, setResultados] = useState([]);
  const [showModalPesquisa, setShowModalPesquisa] = useState(false)
  const [pesquisa, setPesquisa] = useState(null)
  const [media, setMedia] = useState(0)

  const handleModalPesquisa = (idPesquisa) => {
    console.log("pesquisasUsuario", pesquisasUsuario)
    if (idPesquisa) {
      setPesquisa(pesquisasUsuario.filter(p => p.id === idPesquisa)[0])
    }
    setShowModalPesquisa(!showModalPesquisa)
    handlePesquisa()
  }

  useEffect(() => {
    handlePesquisa();
  }, []);

  const handlePesquisa = async () => {
    if (usuario?.id_patrocinador) {
      let respostaPesquisa = await api.post("/pesquisa-conteudo", { id_patrocinador: usuario.id_patrocinador });
      if (respostaPesquisa.status === 200) {
        console.log(respostaPesquisa.data);
        setResultados(respostaPesquisa.data.pesquisas);
        setMedia(respostaPesquisa.data.media)
      }
    }
  };

  const handleDownload = async () => {
    console.log("download");
    let jsonRelatorio = resultados.map((r) => {
      return {
        Convidado: r.nome,
        Conteudo: r.conteudo,
        Empresa: r.empresa,
        "Data / Hora": formatarDiaHora(r.presenca),
        "Resultado convidado":
          r.rate > 0 ? r.rate : "Não Avaliado",
      };
    });
    await exportToExcel(jsonRelatorio, "Resultado conteudo");
  };

  return (
    <div className="main">
      <ModalPesquisa
        titulo="Pesquisa"
        show={showModalPesquisa}
        toggleModal={handleModalPesquisa}
        pesquisa={pesquisa}
        tipoUsuario={tipoUsuario}
        close={true}
      ></ModalPesquisa>
      <div className="titulo-pagina">Avaliações das salas de conteudo</div>
      <div className="header-options">
        <button
          style={{ marginBottom: "10px" }}
          className="btn btn-header-options"
          onClick={() => {
            handleDownload();
          }}
        >
          <FaDownload style={{ marginRight: "10px" }} /> Download
        </button>
      </div>
      <div className="corpo-pagina">
        <table
          className={`table table-bordered table-hover text-center align-items-center`}
          style={{ verticalAlign: "middle" }}
        >
          <thead>
            <tr>
              <th>Convidado</th>
              <th>Conteudo</th>
              <th>Empresa</th>
              <th>Data / Hora</th>
              <th>Resultado convidado</th>
            </tr>
          </thead>
          <tbody>
            {resultados.length ? resultados.map((r, index) => {
              return (
                <tr key={index}>
                  <td className={``}>{r.nome}</td>
                  <td className={``}>{r.conteudo}</td>
                  <td>{r.empresa}</td>
                  <td>
                    {formatarDiaHora(r.presenca)}
                  </td>
                  <td>{r.rate === 0 ? "não avaliado" : r.rate}</td>
                </tr>
              );
            }) :
              <tr>
                <td colSpan="5">
                  <div className="sem-avaliacoes">
                    <span>Sem avaliações</span>
                  </div>
                </td>
              </tr>
            }
            {resultados.length ?
              <tr>
                <td colSpan="5">
                  <div className="">
                    <span>Avaliação Media: {media}</span>
                  </div>
                </td>
              </tr> : false}
          </tbody>
        </table>
        <div className={`espaco-footer`}></div>
      </div>
    </div>
  );
};

export default Avaliacoes;
