import React, { useEffect, useState } from "react";
import ModalB from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./modal.css";

// import useApp from "../../hooks/useAppContext";

const Modal = (props) => {
  // const { apresentarModal, toggleModal } = useApp();
  // eslint-disable-next-line no-unused-vars
  const [tituloModal, setTituloModal] = useState(props.titulo);

  useEffect(() => {
    setTituloModal(props.titulo);
  }, [props]);

  const handleModal = () => props.toggleModal();

  return (
    <>
      <ModalB show={props.show} onHide={handleModal}>
        <ModalB.Header closeButton>
          <ModalB.Title className="titulo-modal">{tituloModal}</ModalB.Title>
        </ModalB.Header>
        <ModalB.Body>{props.children}</ModalB.Body>
        {props.to ? (
          <ModalB.Footer>
            <Link to={props.to} onClick={handleModal} className="link-padrao">
              {props.labelTo}
            </Link>
          </ModalB.Footer>
        ) : (
          false
        )}
      </ModalB>
    </>
  );
};

export default Modal;

