import React, { useEffect, useState } from "react";
import "./patrocinadores.css";
import useApp from "../../hooks/useAppContext";
import AreaPatrocinadores from "./areaPatrocinadores";

const Patrocinadores = () => {
  const { getPatrocinadores } = useApp();
  const [patrocinadores, setPatrocinadores] = useState();

  useEffect(() => {
    handleCarregaPatrocinadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCarregaPatrocinadores = async () => {
    let arrayP = await getPatrocinadores();
    setPatrocinadores(arrayP);
  };

  return (
    <div className="main">
      <div className="titulo-pagina">Patrocinadores</div>
      <div className="corpo-pagina">
        <AreaPatrocinadores
          patrocinadores={patrocinadores}
          cota={`ADVANCE`}
          id_cota={3}
          id_extra={[143]}
        />
        <AreaPatrocinadores
          patrocinadores={patrocinadores}
          cota={`LITE`}
          id_cota={4}
          id_extra={[117, 74]}
        />
        <AreaPatrocinadores
          patrocinadores={patrocinadores}
          cota={`LOUNGE`}
          id_cota={6}
        />
        <AreaPatrocinadores
          patrocinadores={patrocinadores}
          cota={`CONTEÚDO`}
          id_cota={7}
          id_extra={[143, 93]}
        />
        <AreaPatrocinadores
          patrocinadores={patrocinadores}
          cota={`EXPERIENCE`}
          id_cota={8}
          id_extra={[141, 143, 86, 155]}
        />
      </div>
      <div className={`espaco-footer`}></div>
    </div>
  );
};

export default Patrocinadores;
