import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AreaPatrocinadoresAdm from "./areaPatrocinadoresAdm";
import useApp from "../../hooks/useAppContext";
import api from "../../services/axios";
import AreaPatrocinadoresConteudoAdm from "./areaPatrocinadoresConteudoAdm";

const AdmMaisDetalhes = () => {
  const navigate = useNavigate();
  const { getPatrocinadores } = useApp();
  const [patrocinadores, setPatrocinadores] = useState();

  useEffect(() => {
    handleCarregaPatrocinadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCarregaPatrocinadores = async () => {
    // let arrayP = await getPatrocinadores();
    let arrayP = await api.get("/patrocinador-mais-detalhes");
    let arrayPatrocinadores = arrayP.data.patrocinadores.map((p) => {
      console.log(p);
      p.agenda_patrocinador =
        p.usuario.agendaParticipante.filter(
          (a) => {
            return a.agenda.tipo === "reuniao_negocio";
          }
        );
      let quantidadeFutura = 0;
      let quantidadeRealizada = 0;
      let quantidadeNoShow = 0;
      p.agenda_patrocinador.map((ap) => {
        if (ap.agenda.status === "futura") {
          quantidadeFutura++;
        }
        if (ap.agenda.status === "entregue") {
          quantidadeRealizada++;
        }
        if (ap.agenda.status === "no_show") {
          quantidadeNoShow++;
        }
      });

      p.futuras = quantidadeFutura;
      p.entregues = quantidadeRealizada;
      p.noshow = quantidadeNoShow;

      return p;
    });
    setPatrocinadores(arrayPatrocinadores);
  };

  return (
    <div className="container-adm-main">
      <div className="header-tela-adm">
        <h3>Mais Detalhes</h3>
        <div className="header-options">
          <button
            className="btn btn-header-options"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft /> Voltar
          </button>
        </div>
      </div>

      <div className="body-tela-adm">
        <AreaPatrocinadoresAdm
          patrocinadores={patrocinadores}
          cota={`ADVANCE`}
          id_cota={3}
        />
        <AreaPatrocinadoresAdm
          patrocinadores={patrocinadores}
          cota={`LITE`}
          id_cota={4}
        />
        <AreaPatrocinadoresConteudoAdm
          cota={`COTA CONTEÚDO`}
          id_cota={7}
        />
      </div>
      <div className={`espaco-footer`}></div>
    </div>
  );
};
export default AdmMaisDetalhes;
