import React, { useEffect, useState } from "react";
import "./participante.css";
// import { IoChatboxEllipses } from "react-icons/io5";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import useApp from "../../hooks/useAppContext";
import api from "../../services/axios";
import ItemParticipantes from "../../components/participantes/itemParticipante";

const Participantes = () => {
  const { menuIsOpen } = useApp();
  const [participantes, setParticipantes] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [labelPesquisa, setLabelPesquisa] = useState("Nome");
  const [options, setOptions] = useState([]);
  const [tipoFiltro, setTipoFiltro] = useState("text");

  const optionsCargos = [
    <option value="">Selecione uma opção</option>,
    <option value="Presidente">Presidente</option>,
    <option value="CEO">CEO</option>,
    <option value="CIO_CMO_COO">CIO, CMO, COO</option>,
    <option value="Diretor Executivo">Diretor Executivo</option>,
    <option value="Superintendente">Superintendente</option>,
  ];
  const optionsInstituicoes = [
    <option value="">Selecione uma opção</option>,
    <option value="Hospitais">Hospitais</option>,
    <option value="Laboratórios">Laboratórios</option>,
    <option value="Clinicas">Clinicas</option>,
    <option value="Operadoras">Operadoras</option>,
    <option value="Outros">Outros</option>,
  ];

  useEffect(() => {
    carregarParticipantes();
  }, []);

  const handleUsuarios = async (filtroTxt) => {
    setFiltro(filtroTxt);
    let dados;
    switch (labelPesquisa) {
      case "Nome":
        dados = {
          nome: filtroTxt.trim(),
        };
        break;
      case "Empresa":
        dados = {
          empresa: filtroTxt.trim(),
        };
        break;
      case "Cargo":
        dados = {
          cargo: filtroTxt.trim(),
        };
        break;
      case "Tipo Instituição":
        dados = {
          instituicao: filtroTxt.trim(),
        };
        break;
      default:
        dados = {};
        break;
    }
    if (dados) {
      dados.tipo = ["convidado", "patrocinador", "administracao_dash"];
      let respUsuarios = await api.post(`/usuarios-apenas-user-externo`, dados);

      if (respUsuarios.status === 200) {
        setParticipantes(respUsuarios.data.dados);
      }
    }
  };

  const carregarParticipantes = async () => {
    let respUsuarios = await api.post(`/usuarios-apenas-user-externo`, {
      tipo: ["convidado", "patrocinador", "administracao_dash"],
    });

    if (respUsuarios.status === 200) {
      setParticipantes(respUsuarios.data.dados);
    }
  };

  return (
    <div className="main">
      <div className="corpo-pagina">
        {menuIsOpen ? (
          false
        ) : (
          <div className="area-filtro">
            <div className="titulo-pagina">Participantes</div>
            <InputGroup className="">
              {tipoFiltro === "text" ? (
                <Form.Control
                  className="pesquisa"
                  aria-label="Text input with dropdown button"
                  onChange={(e) => {
                    handleUsuarios(e.target.value);
                  }}
                  value={filtro}
                />
              ) : (
                <Form.Select
                  className="pesquisa"
                  onChange={(e) => {
                    handleUsuarios(e.target.value);
                  }}
                  value={filtro}
                >
                  {options}
                </Form.Select>
              )}
              <DropdownButton
                variant="outline-secondary"
                title={labelPesquisa}
                id="input-group-dropdown-participantes"
                align="end"
              >
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setFiltro("");
                    setLabelPesquisa("Todos");
                    setTipoFiltro("text");
                    carregarParticipantes();
                  }}
                >
                  Todos
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setFiltro("");
                    setLabelPesquisa("Nome");
                    setTipoFiltro("text");
                  }}
                >
                  Nome
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setFiltro("");
                    setLabelPesquisa("Empresa");
                    setTipoFiltro("text");
                  }}
                >
                  Empresa
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setFiltro("");
                    setLabelPesquisa("Cargo");
                    setOptions(optionsCargos);
                    setTipoFiltro("select");
                  }}
                >
                  Cargo
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setFiltro("");
                    setLabelPesquisa("Tipo Instituição");
                    setOptions(optionsInstituicoes);
                    setTipoFiltro("select");
                  }}
                >
                  Tipo Instituição
                </Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          </div>
        )}
        <div id="lista-participantes">
          {participantes.map((participante) => {
            return (
              <ItemParticipantes
                key={participante.id}
                participante={participante}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Participantes;
