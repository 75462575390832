import React, { useEffect, useState } from "react";
import ModalB from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { formatarHora } from "../../services/conversaoTimer";
import { FaCheck } from "react-icons/fa";
import api from "../../services/axios";

function ModalValidaAgenda(props) {
  const [tituloModal, setTituloModal] = useState(props.titulo);
  const [codigoValidacao, setCodigoValidacao] = useState("");
  const [respostaValidacao, setRespostaValidacao] = useState(null);
  const [mensagemErro, setMensagemErro] = useState("");
  const [usuarioVerificado, setUsuarioVerificado] = useState(false);
  const [disableVerificar, setDisableVerificar] = useState(true)

  const handleValidarCodigo = async () => {
    console.log(props.item);
    if (codigoValidacao !== "") {
      let resp = await api.post("/reunioes-valida-reuniao", {
        pin: codigoValidacao,
        id_agenda: props.item.id_agenda,
        id_usuario_convidado:
          props.item.agenda?.participantes[0]?.id_usuario,
      });
      if (resp.data.reuniao) {
        setRespostaValidacao(true);
        setMensagemErro("");
        if (resp.data.usuario) {
          setUsuarioVerificado(true);
        }
      } else {
        setRespostaValidacao(false);
        setMensagemErro(resp.data.mensagem);
      }
    } else {
      alert("Digite o codigo do convidado!");
    }
  };

  useEffect(() => {
    setTituloModal(props.titulo);
    setCodigoValidacao("");
    setUsuarioVerificado(false);
    setRespostaValidacao(false);
    setMensagemErro("");
  }, [props]);

  useEffect(() => {
    setDisableVerificar(true)
    setTimeout(() => {
      setDisableVerificar(false)
    }, 2000)
  }, [props.show]);

  const handleModal = () => props.toggleModal();

  return (
    <>
      <ModalB show={props.show} onHide={handleModal}>
        <ModalB.Header closeButton>
          <ModalB.Title className="titulo-modal">{tituloModal}</ModalB.Title>
        </ModalB.Header>
        {/*<ModalB.Body>{props.children}</ModalB.Body>*/}
        <ModalB.Body>
          <div className={`area-modal-validacao`}>
            <div className={`d-flex`}>
              <div className={`label-hora-modal`}>
                {formatarHora(props.item.agenda.dt_hora_inicio).substring(0, 5)}{" "}
                / {formatarHora(props.item.agenda.dt_hora_fim).substring(0, 5)}
              </div>
              <div className={`label-agenda-modal`}>
                {props.item.agenda.nome}
              </div>
            </div>
            <div className={`area-input-modal`}>
              <div>
                Digite o codigo de{" "}
                {props.item.agenda?.participantes[0]?.usuario?.nome}
              </div>
              <div
                className={`d-flex justify-content-center align-items-center`}
              >
                <input
                  type="text"
                  className={`input-verificador`}
                  placeholder={`Digite aqui o cogigo`}
                  style={{ marginRight: "15px" }}
                  value={codigoValidacao}
                  onChange={(e) => {
                    setCodigoValidacao(e.target.value);
                  }}
                />
                {respostaValidacao ? <FaCheck /> : false}
              </div>
              <div>
                {respostaValidacao ? (
                  false
                ) : (
                  <div style={{ color: "red" }}>{mensagemErro}</div>
                )}
              </div>

              <div className={`area-botao-modal`}>
                {!usuarioVerificado ? (
                  <button
                    className={`btn-header-options${disableVerificar ? ' disabled' : ''}`}
                    onClick={() => {
                      handleValidarCodigo();
                    }}
                    disabled={disableVerificar}
                  >
                    Verificar
                  </button>
                ) : (
                  <div>Presença Confirmada!</div>
                )}
              </div>
            </div>
          </div>
        </ModalB.Body>
        {props.to ? (
          <ModalB.Footer>
            <Link to={props.to} onClick={handleModal} className="link-padrao">
              {props.labelTo}
            </Link>
          </ModalB.Footer>
        ) : (
          false
        )}
      </ModalB>
    </>
  );
}

export default ModalValidaAgenda;
