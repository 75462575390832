import React, { useEffect, useState } from "react";
import "./home.css";
import { Link } from "react-router-dom";

import Post from "../../components/timeLine/post";

import useAuth from "../../hooks/useAuthContext";
import useApp from "../../hooks/useAppContext";
import api from "../../services/axios";

import { FaCamera } from "react-icons/fa";
import Loading from "../../assets/home/loading.gif";
import { toast } from "react-toastify";

const Home = () => {
  const { usuario } = useAuth();
  const { verificaDispositivo, handleImagemParticipante } = useApp();
  const [postagens, setPostagens] = useState([]);
  const [textoPostagem, setTextoPostagem] = useState("");
  const [imagemPostagem, setImagemPostagem] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validaApple, setValidaApple] = useState(false);

  const notifyInfo = (mensagem) =>
    toast.info(mensagem, { theme: "colored", autoClose: 3000 });
  const notifyError = (mensagem) => toast.error(mensagem, { theme: "colored" });

  useEffect(() => {
    carregarPostagens();
  }, []);

  useEffect(() => {
    setValidaApple(verificaDispositivo());
  }, []);

  const carregarPostagens = async () => {
    let respPostagens = await api.post(`/postagens`, {});

    if (respPostagens.status === 200) {
      console.log(respPostagens.data.dados);
      setPostagens(respPostagens.data.dados);
    }
  };

  const handleChangeImage = (e) => {
    // setEnviado("true");
    if (e.target.files.length) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setImagemPostagem(e.target.files[0]);
    }
  };

  const handlePostagem = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("descricao", textoPostagem);
      if (imagemPostagem) {
        formData.append("foto", imagemPostagem);
      }

      const response = await api.post(`/postagens-create`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        notifyInfo(response.data.mensagem);
        limparFormulario();
        carregarPostagens();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      notifyError(
        error.response.data.mensagem + "\n" + error.response.data.erro
      );
    }
  };

  const limparFormulario = () => {
    setTextoPostagem("");
    setImagemPostagem(null);
    setPreview(null);
  };

  return (
    <>
      {loading ? (
        <div className="area-loading-blur">
          <div className="area-loading">
            <img src={Loading} alt="" />
          </div>
        </div>
      ) : (
        false
      )}
      <div className="main">
        <div
          className={`area-postagem ${loading ? "area-postagem-loading" : ""}`}
        >
          <div className="area-input">
            <div>
              <img
                src={handleImagemParticipante(usuario)}
                alt=""
                className="imagem-participante"
              />
            </div>
            <div className="input-postagem">
              <input
                type="text"
                value={textoPostagem}
                onChange={(e) => {
                  setTextoPostagem(e.target.value);
                }}
                placeholder="O que está pensando?"
              />
            </div>
          </div>
          <div className="area-botoes">
            <div>
              {loading ? (
                false
              ) : (
                <label htmlFor="input-camera" className="btn btn-sm bg-green">
                  <FaCamera />
                </label>
              )}
              <input
                type="file"
                id="input-camera"
                onChange={(e) => handleChangeImage(e)}
              />
            </div>
            <button
              disabled={loading}
              className="btn btn-sm text-white btn-enviar"
              onClick={handlePostagem}
            >
              Enviar
            </button>
          </div>
          {preview ? (
            <div className="area-preview">
              <div className="label-preview">Preview</div>
              <img src={preview} alt="" />
            </div>
          ) : (
            false
          )}
        </div>
        <div className={`area-timeline ${validaApple ? "espaco-apple" : ""}`}>
          <div className="area-btn-minha-agenda">
            <Link to="/private/agenda" className="small-box-footer">
              <button className="btn btn-sm text-white btn-minha-agenda">
                Minha agenda
              </button>
            </Link>
          </div>
          <div id="listagem-timeline">
            {postagens
              ? postagens.map((post) => {
                return (
                  <Post
                    key={post.id}
                    imagem={post.foto}
                    usuario={post.usuario}
                    avatar={handleImagemParticipante(post.usuario)}
                    descricao={post.descricao}
                    post={post}
                    handlePostagem={() => carregarPostagens()}
                  />
                );
              })
              : false}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
