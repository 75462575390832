import React, { useState } from "react";
const InputRadio = (props) => {
  const handleEscolha = (e) => {
    if (e.target.checked) {
      console.log(e.target.value);
      props.onChange(e.target.value);
    }
  };
  return (
    <div className={`${props?.classNames ? props?.classNames : "mb-1"}`}>
      <label className="form-check-label label-input">
        {props.label} <span className="text-red">*</span>
      </label>
      <div className="area-radio-cadastro">
        <fieldset>
          {props?.values?.map((v) => {
            console.log(props.value);
            return (
              <div key={v} className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id={`radio_${v}`}
                  name="sexo"
                  value={v}
                  onChange={(e) => {
                    handleEscolha(e);
                  }}
                  checked={v === props.value ?? false}
                />
                <label htmlFor={`radio_${v}`} className="form-check-label">
                  {v}
                </label>
              </div>
            );
          })}
        </fieldset>
      </div>
    </div>
  );
};
export default InputRadio;
