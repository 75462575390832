import React, { useEffect, useState } from "react";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import api from "../../services/axios";
import { formatarDiaHora, formatarHora } from "../../services/conversaoTimer";
import useAuth from "../../hooks/useAuthContext";

const AdmMaisDetalhesNaoEntregues = () => {
  const { usuario } = useAuth();
  const navigate = useNavigate();
  const [agendas, setAgendas] = useState([]);

  useEffect(() => {
    handleAgendas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAgendas = async () => {
    let respostaAgendas = await api.get("/reunioes-pendentes");
    if (respostaAgendas.status === 200) {
      setAgendas(respostaAgendas.data.agendas);
    }
  };

  const handleNoShow = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let confirmacao = confirm("Confirmar No Show?");
    if (confirmacao) {
      let respostaNoShow = await api.post("/reunioes-noshow", {
        id_agenda: id,
      });
      if (respostaNoShow.status === 200) {
        handleAgendas();
      }
    }
  };

  return (
    <div className="container-adm-main">
      <div className="header-tela-adm">
        <h3>Listagem de reuniões pendentes</h3>
        <div className="header-options">
          <button
            className="btn btn-header-options"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft /> Voltar
          </button>
        </div>
      </div>

      <div style={{ paddingBottom: "150px" }}>
        <table className="table table-hovered table-bordered text-center">
          <thead>
            <tr>
              <th>Patrocinador</th>
              <th>Convidado</th>
              <th>Cargo</th>
              <th>Instituição</th>
              <th>Horario</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {agendas.map((a) => {
              console.log(a);
              return (
                <tr>
                  <td>{a.participantes[1].usuario.nome}</td>
                  <td>{a.participantes[0].usuario.nome}</td>
                  <td>{a.participantes[0].usuario.cargo}</td>
                  <td>{a.participantes[0].usuario.empresa}</td>
                  <td>
                    {`${formatarDiaHora(a.dt_hora_inicio).substring(
                      0,
                      16
                    )} - ${formatarHora(a.dt_hora_fim).substring(0, 5)}`}
                  </td>
                  <td>
                    {usuario?.tipo === "administracao" ? (
                      <div className={`area-btn-pendentes`}>
                        <div
                          className={`btn btn-header-options`}
                          onClick={() => handleNoShow(a.id)}
                        >
                          No show
                        </div>
                        <Link
                          to={"/private/adm/remarcar/" + a.id}
                          style={{ textDecoration: "none" }}
                        >
                          <div className={`btn btn-header-options`}>
                            Remarcar
                          </div>
                        </Link>
                      </div>
                    ) : (
                      false
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdmMaisDetalhesNaoEntregues;
