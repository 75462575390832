import React, { useState, useEffect } from "react";
import ModalB from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./modal.css";
import api from "../../services/axios";
import LinhaModalReunioes from "./linhaModalReunioes";
import Loading from "../../assets/home/loading.gif";

const ModalReunioes = (props) => {
  const [empresa, setEmpresa] = useState("");
  const [cargo, setCargo] = useState("");
  const [disponibilidade, setDisponibilidade] = useState("");
  const [participantes, setParticipantes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [listaParticipantes, setListaParticipantes] = useState([]);
  const [pesquisaNome, setPesquisaNome] = useState("");
  const [dia, setDia] = useState("04");
  const [indisponivelPat1, setIndisponivelPat1] = useState([]);
  const [indisponivelPat2, setIndisponivelPat2] = useState([]);
  const [showIncluir, setShowIncluir] = useState(true);
  const [showIncluirLotacao, setShowIncluirLotacao] = useState(true);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [tituloModal, setTituloModal] = useState(props.titulo);

  const matriz_disponibilidade = [
    "10:20",
    "11:00",
    "11:40",
    "12:20",
    "12:50",
  ];

  const matriz_disponibilidade_segundo_dia = [
    "10:30",
    "11:10",
    "11:50",
    "12:30",
    "13:10",
  ];

  useEffect(() => {
    console.log(props.dia);
    setPesquisaNome("");
    setDia(props.dia);
    // carregarParticipantes();
    carregarEmpresas();
    carregarCargos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPesquisaNome("");
    carregarParticipantes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dia, indisponivelPat1, indisponivelPat2]);

  useEffect(() => {
    handleDisponibilidade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_info]);

  const handleModal = () => props.toggleModal();

  const carregarParticipantes = async () => {
    setLoading(true);
    await props.carrega_usuario();
    let respUsuarios = await api.post(`/usuarios-apenas-reunioes`, {
      tipo: ["convidado"],
    });

    if (respUsuarios.status === 200) {
      let aux_participantes = respUsuarios.data.dados.map((p) => {
        let indisponivel = [];
        let selectIndisponivel = [];
        if (p.agendaParticipante.length) {
          // eslint-disable-next-line array-callback-return
          p.agendaParticipante.map((a) => {
            if (a.agenda.dt_hora_inicio.includes("2024-08-" + dia)) {
              let inicio = a.agenda.dt_hora_inicio
                .split(" ")[1]
                .substring(0, 5);

              indisponivel.push(inicio);
              selectIndisponivel.push(inicio);
              if (
                a.agenda.tipo === "conteudo_patrocinado" ||
                a.agenda.tipo === "conteudo_editorial"
              ) {
                let ind = matriz_disponibilidade.indexOf(inicio);
                if (dia === "03") {
                  ind = matriz_disponibilidade_segundo_dia.indexOf(inicio);
                }
                if (ind >= 0 && dia === "02") {
                  indisponivel.push(matriz_disponibilidade[ind + 1]);
                  selectIndisponivel.push(matriz_disponibilidade[ind + 1]);
                }
                if (ind >= 0 && dia === "03") {
                  indisponivel.push(
                    matriz_disponibilidade_segundo_dia[ind + 1]
                  );
                  selectIndisponivel.push(
                    matriz_disponibilidade_segundo_dia[ind + 1]
                  );
                }
              }
            }
          });
        }

        if (dia === "02") {
          console.log("props.cotaprops.cotaprops.cota", props.cota)
          matriz_disponibilidade.map((i) => {
            if (indisponivelPat1[i] >= props.cota) {
              // indisponivel.push(i);
              selectIndisponivel.push(i);
            }
          });
        }
        console.log("selectIndisponivel", selectIndisponivel)
        if (dia === "03") {
          matriz_disponibilidade_segundo_dia.map((i) => {
            if (indisponivelPat2[i] >= props.cota) {
              // indisponivel.push(i);
              selectIndisponivel.push(i);
            }
          });
        }

        let contadorReuniao = p.agendaParticipante.filter((apc) => {
          return (
            apc.agenda.tipo === "reuniao_negocio" &&
            apc.agenda.nome !== "Reunião de almoço/jantar"
          );
        });

        if (contadorReuniao.length >= 6) {
          indisponivel = matriz_disponibilidade;
          selectIndisponivel = matriz_disponibilidade;
          if (dia === "03") {
            indisponivel = matriz_disponibilidade_segundo_dia;
            selectIndisponivel = matriz_disponibilidade_segundo_dia;
          }
          p.mostrarbotao = false;
        } else {
          p.mostrarbotao = true;
        }

        let text_disponibilidade = "";
        let text_select_disponibilidade = "";
        // eslint-disable-next-line array-callback-return
        if (dia === "02") {
          matriz_disponibilidade.map((md) => {
            if (!indisponivel.includes(md)) {
              text_disponibilidade += `${md} `;
            }
          });

          matriz_disponibilidade.map((md) => {
            if (!selectIndisponivel.includes(md)) {
              text_select_disponibilidade += `${md} `;
            }
          });
        }

        if (dia === "03") {
          matriz_disponibilidade_segundo_dia.map((md) => {
            if (!indisponivel.includes(md)) {
              text_disponibilidade += `${md} `;
            }
          });

          matriz_disponibilidade_segundo_dia.map((md) => {
            if (!selectIndisponivel.includes(md)) {
              text_select_disponibilidade += `${md} `;
            }
          });
        }

        p.disponibilidade = text_disponibilidade.trim();
        p.selectdisponibilidade = text_select_disponibilidade.trim();
        return p;
      });
      setParticipantes(aux_participantes);
      setListaParticipantes(aux_participantes);
      setLoading(false);
    }
  };

  const carregarEmpresas = async () => {
    let respEmpresas = await api.get(`/usuarios-empresas`);
    if (respEmpresas.status === 200) {
      setEmpresas(respEmpresas.data.dados);
    }
  };

  const carregarCargos = async () => {
    let respCargos = await api.get(`/usuarios-cargos`);
    if (respCargos.status === 200) {
      setCargos(respCargos.data.dados);
    }
  };

  const handlePesquisa = (e) => {
    setPesquisaNome(e);
    let aux_pesquisa = participantes.filter((p) => {
      if (empresa !== "" && cargo !== "" && disponibilidade !== "") {
        return (
          p.empresa?.toUpperCase().includes(empresa.toUpperCase()) &&
          p.cargo?.toUpperCase().includes(cargo.toUpperCase()) &&
          p.disponibilidade
            ?.toUpperCase()
            .includes(disponibilidade.toUpperCase()) &&
          p.nome.toUpperCase().includes(e.toUpperCase())
        );
      } else if (empresa !== "" && cargo !== "") {
        return (
          p.empresa?.includes(empresa) &&
          p.cargo?.includes(cargo) &&
          p.nome.includes(e)
        );
      } else if (empresa !== "" && disponibilidade !== "") {
        return (
          p.empresa?.includes(empresa) &&
          p.disponibilidade?.includes(disponibilidade) &&
          p.nome.includes(e)
        );
      } else if (empresa !== "") {
        return p.empresa?.includes(empresa) && p.nome.includes(e);
      } else if (cargo !== "") {
        return p.cargo?.includes(cargo) && p.nome.includes(e);
      } else if (disponibilidade !== "") {
        return (
          p.disponibilidade?.includes(disponibilidade) && p.nome.includes(e)
        );
      } else {
        return p.nome.toUpperCase().includes(e.toUpperCase());
      }
    });
    setListaParticipantes(aux_pesquisa);
  };

  const handlePesquisaEmpresa = (e) => {
    setEmpresa(e);
    let aux_pesquisa = participantes.filter((p) => {
      if (pesquisaNome !== "" && cargo !== "" && disponibilidade !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.cargo?.includes(cargo) &&
          p.disponibilidade?.includes(disponibilidade) &&
          p.empresa?.includes(e)
        );
      } else if (pesquisaNome !== "" && cargo !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.cargo?.includes(cargo) &&
          p.empresa.includes(e)
        );
      } else if (pesquisaNome !== "" && disponibilidade !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.disponibilidade?.includes(disponibilidade) &&
          p.empresa.includes(e)
        );
      } else if (pesquisaNome !== "") {
        return p.nome?.includes(pesquisaNome) && p.empresa.includes(e);
      } else if (cargo !== "") {
        return p.cargo?.includes(cargo) && p.empresa.includes(e);
      } else if (disponibilidade !== "") {
        return (
          p.disponibilidade?.includes(disponibilidade) && p.empresa.includes(e)
        );
      } else {
        return p.empresa.includes(e);
      }
    });
    setListaParticipantes(aux_pesquisa);
  };

  const handlePesquisaCargo = (e) => {
    setCargo(e);
    let aux_pesquisa = participantes.filter((p) => {
      if (pesquisaNome !== "" && empresa !== "" && disponibilidade !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.empresa?.includes(empresa) &&
          p.disponibilidade?.includes(disponibilidade) &&
          p.cargo?.includes(e)
        );
      } else if (pesquisaNome !== "" && empresa !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.empresa?.includes(empresa) &&
          p.cargo.includes(e)
        );
      } else if (pesquisaNome !== "" && disponibilidade !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.disponibilidade?.includes(disponibilidade) &&
          p.cargo.includes(e)
        );
      } else if (pesquisaNome !== "") {
        return p.nome?.includes(pesquisaNome) && p.cargo.includes(e);
      } else if (empresa !== "") {
        return p.empresa?.includes(empresa) && p.cargo.includes(e);
      } else if (disponibilidade !== "") {
        return (
          p.disponibilidade?.includes(disponibilidade) && p.cargo.includes(e)
        );
      } else {
        return p.cargo.includes(e);
      }
    });
    setListaParticipantes(aux_pesquisa);
  };

  const handlePesquisaDisponibilidade = (e) => {
    setDisponibilidade(e);
    let aux_pesquisa = participantes.filter((p) => {
      if (pesquisaNome !== "" && empresa !== "" && cargo !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.empresa?.includes(empresa) &&
          p.cargo?.includes(cargo) &&
          p.disponibilidade?.includes(e)
        );
      } else if (pesquisaNome !== "" && empresa !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.empresa?.includes(empresa) &&
          p.disponibilidade.includes(e)
        );
      } else if (pesquisaNome !== "" && cargo !== "") {
        return (
          p.nome?.includes(pesquisaNome) &&
          p.cargo?.includes(cargo) &&
          p.disponibilidade.includes(e)
        );
      } else if (pesquisaNome !== "") {
        return p.nome?.includes(pesquisaNome) && p.disponibilidade.includes(e);
      } else if (empresa !== "") {
        return p.empresa?.includes(empresa) && p.disponibilidade.includes(e);
      } else if (cargo !== "") {
        return p.cargo?.includes(cargo) && p.disponibilidade.includes(e);
      } else {
        return p.disponibilidade.includes(e);
      }
    });
    setListaParticipantes(aux_pesquisa);
  };

  const handleDisponibilidade = async () => {
    setPesquisaNome("");
    await props.carrega_usuario();
    if (props?.id_info) {
      let respPatrocinador = await api.post(
        "/patrocinador-verifica-disponibilidade",
        { id_info: props.id_info }
      );
      if (props.cota) {
        let insdisponivel1 = respPatrocinador.data.indisponivel1;
        setIndisponivelPat1(insdisponivel1);
        let insdisponivel2 = respPatrocinador.data.indisponivel2;
        setIndisponivelPat2(insdisponivel2);

        if (respPatrocinador.data.quantidadeAgenda < props.cota_quantidade) {
          setShowIncluir(true);
        } else {
          setShowIncluir(false);
        }
      }
    }
  };

  const handleDia = (diaClick) => {
    setDia(diaClick);
    setPesquisaNome("");
  };

  return (
    <>
      <ModalB show={props.show} onHide={handleModal}>
        <ModalB.Header closeButton>
          <ModalB.Title className="titulo-modal">{tituloModal}</ModalB.Title>
        </ModalB.Header>
        <ModalB.Body>
          <>
            {loading ? (
              <div className="area-loading-blur">
                <div className="area-loading">
                  <img src={Loading} alt="" />
                </div>
              </div>
            ) : (
              false
            )}
            <div className={`d-flex`} style={{ marginBottom: "15px" }}>
              <div
                className={`btn-header-options ${dia === "02" ? "ativo" : ""}`}
                onClick={() => {
                  handleDia(`02`);
                }}
              >
                02/08
              </div>
              <div
                className={`btn-header-options ${dia === "03" ? "ativo" : ""}`}
                onClick={() => {
                  handleDia(`03`);
                }}
              >
                03/08
              </div>
            </div>
            <div className="area-modal-reuniao">
              <div className="area-filtro-reuniao">
                <label htmlFor="nome">Nome:</label>
                <input
                  type="text"
                  className="form-control form-control-xs"
                  value={pesquisaNome}
                  onChange={(e) => handlePesquisa(e.target.value)}
                />
                <label htmlFor="nome">Empresa:</label>
                <select
                  className="form-select form-control form-control-lg"
                  aria-label="Default select example"
                  id="input_procura"
                  value={empresa}
                  onChange={(e) => handlePesquisaEmpresa(e.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  {empresas.map((e, i) => {
                    return (
                      <option key={i} value={e.empresa}>
                        {e.empresa}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="nome">Cargo:</label>
                <select
                  className="form-select form-control form-control-lg"
                  aria-label="Default select example"
                  id="input_procura"
                  value={cargo}
                  onChange={(e) => handlePesquisaCargo(e.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  {cargos.map((c, i) => {
                    return (
                      <option key={i} value={c.cargo}>
                        {c.cargo}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="nome">Disponibilidade:</label>
                <select
                  className="form-select form-control form-control-lg"
                  aria-label="Default select example"
                  id="input_procura"
                  value={disponibilidade}
                  onChange={(e) =>
                    handlePesquisaDisponibilidade(e.target.value)
                  }
                >
                  <option value="">Selecione uma opção</option>
                  <option value="10:20">02 - 10:20</option>
                  <option value="11:00">02 - 11:00</option>
                  <option value="11:40">02 - 11:40</option>
                  <option value="12:20">02 - 12:20</option>
                  <option value="12:50">02 - 12:50</option>
                  <option value="10:30">03 - 10:30</option>
                  <option value="11:10">03 - 11:10</option>
                  <option value="11:50">03 - 11:50</option>
                  <option value="12:30">03 - 12:30</option>
                  <option value="13:10">03 - 13:10</option>
                </select>
              </div>
              <div className="area-lista-convidado">
                <div className="header-lista-adm">
                  <div>Nome </div>
                  <div>Empresa</div>
                  <div>Cargo</div>
                  <div>Disponibilidade dia {dia}</div>
                </div>
                <div className="body-lista-adm">
                  {listaParticipantes?.map((p, i) => {
                    let jaMarcado = props.ids_convidados.includes(p.id)
                      ? true
                      : false;
                    return p.id !== parseInt(props.id) && !jaMarcado ? (
                      <LinhaModalReunioes
                        key={i + "1a"}
                        participante={p}
                        id={props.id}
                        dia={dia}
                        carregarParticipantes={handleDisponibilidade}
                        mostrarIncluir={showIncluir}
                        mostrarIncluirLotacao={showIncluirLotacao}
                        jaMarcado={jaMarcado}
                        setLoading={setLoading}
                      />
                    ) : (
                      false
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        </ModalB.Body>
        {props.to ? (
          <ModalB.Footer>
            <Link to={props.to} onClick={handleModal} className="link-padrao">
              {props.labelTo}
            </Link>
          </ModalB.Footer>
        ) : (
          false
        )}
      </ModalB>
    </>
  );
};

export default ModalReunioes;
