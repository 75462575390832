import React, { useEffect, useState } from "react";

function AreaPatrocinadoresAdm(props) {
  const [patrocinadores, setPatrocinadores] = useState([]);

  useEffect(() => {
    let arrayPatrocinadores = props?.patrocinadores?.filter((p) => {
      console.log("dentro da linha");
      console.log(p);
      // let user = p.usuarios.filter((u) => {
      //   return u.id === p.id_usuario;
      // });
      // return user.length > 0 && props.id_cota === user[0].id_plano;
      return p.id_plano === props.id_cota;
    });
    setPatrocinadores(arrayPatrocinadores);
  }, [props]);

  return (
    <div className="patrocinio-cota-adm">
      <div className={`cota-adm`}>{props.cota}</div>
      <div className="area-patrocinador-adm">
        {patrocinadores?.map((p) => {
          console.log(p);
          return (
            <table
              className={`table table-hovered table-bordered text-center"`}
            >
              <tr>
                <td>
                  <div className={`label-nome-patrocinnio`}>
                    {p.patrocinador}
                  </div>
                </td>
                <td style={{ textAlign: "end", marginRight: "5px" }}></td>
              </tr>

              <tr>
                <td>Quantidade de reuniões agendadas</td>
                <td style={{ textAlign: "end", marginRight: "5px" }}>
                  {p.agenda_patrocinador.length}
                </td>
              </tr>
              <tr>
                <td>Quantidade de reuniões futuras</td>
                <td style={{ textAlign: "end", marginRight: "5px" }}>
                  {p.futuras}
                </td>
              </tr>
              <tr>
                <td>Quantidade de reuniões realizadas</td>
                <td style={{ textAlign: "end", marginRight: "5px" }}>
                  {p.entregues}
                </td>
              </tr>

              <tr>
                <td>Quantidade de reuniões no shows</td>
                <td style={{ textAlign: "end", marginRight: "5px" }}>
                  {p.noshow}
                </td>
              </tr>

              <tr>
                <td>Porcentagem SLA</td>
                <td style={{ textAlign: "end", marginRight: "5px" }}>
                  {p.entregues != 0
                    ? (
                      (p.entregues / (props.cota === 'LITE' ? 7 : 15)) *
                      100
                    ).toFixed(2) + " %"
                    : "0 %"}
                </td>
              </tr>
            </table>
          );
        })}
      </div>
    </div>
  );
}

export default AreaPatrocinadoresAdm;
