import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Main from "./components/main/main";
import MainAdm from "./components/mainAdm/mainAdm";

import Login from "./pages/login/login";
import Cadastro from "./pages/cadastro/cadastro";
import CadastroPatrocinador from "./pages/cadastro/cadastroPatrocinador";
import Agradecimento from "./pages/agradecimento/agradecimento";
import BoasVindas from "./pages/agradecimento/boasVindas";
import CadastroConfirmacao from "./pages/cadastro/cadastroConfirmacao";

import Home from "./pages/home/home";
import Chat from "./pages/chat/chat";
import Conversa from "./pages/chat/conversa";
import Perfil from "./pages/perfil/perfil";
import Agenda from "./pages/agenda/agenda";
import Mapa from "./pages/mapa/mapa";
import Participantes from "./pages/participantes/participantes";
import Patrocinadores from "./pages/patrocinadores/patrocinadores";

import Notificacoes from "./pages/notificacoes/notificacoes";

// import Adm from "./pages/adm/adm"
import AdmNotificacoes from "./pages/adm/admNotificacoes";
import AdmConvidados from "./pages/adm/admConvidados";
import AdmFormConvidados from "./pages/adm/admFormConvidados";
import AdmReunioesConvidados from "./pages/adm/admReunioesConvidados";
import AdmPostagens from "./pages/adm/admPostagens";
import AdmAgenda from "./pages/adm/admAgenda";
import AdmFormAgenda from "./pages/adm/admFormAgenda";
import AdmPatrocinadores from "./pages/adm/admPatrocinadores";
import AdmFormPatrocinadores from "./pages/adm/admFormPatrocinadores";
import AdmReunioesPatrocinadores from "./pages/adm/admReunioesPatrocinadores";
import AdmEventos from "./pages/adm/admEventos";
import AdmFormEventos from "./pages/adm/admFormEventos";
import AdmReunioes from "./pages/adm/admReunioes";
import AdmMaisDetalhesNaoEntregues from "./pages/adm/admMaisDetalhesNaoEntregues";
import AdmMaisDetalhes from "./pages/adm/admMaisDetalhes";
import Recepcao from "./pages/recepcao/recepcao";

import AdmImprimirReunioesConvidados from "./pages/adm/admImprimirReunioesConvidados";

import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";

import { AuthProvider } from "./contexts/AuthContext";
import { AppProvider } from "./contexts/AppContext";
import AdmDash from "./pages/adm/admDash";
import ListaAgenda from "./pages/relatorios/listaAgenda";
import AdmRemarcar from "./pages/adm/admRemarcar";
import IncluirConvidado from "./pages/recepcao/incluirConvidado";
import IncluirReuniaoNegocio from "./pages/recepcao/incluirReuniaoNegocio";
import IncluirReuniaoJantar from "./pages/adm/incluirReuniaoJantar";
import Reuniao from "./pages/reuniao/reuniao";
import AdmEditarRuniao from "./pages/adm/admEditarReuniao";
import AdmPortalPatrocinador from "./pages/adm/adm-portal-patrocinador";
import AdmPortalPatrocinadorNovo from "./pages/adm/adm-portal-patrocinador-novo";
import PortalPatrocinador from "./pages/portal-patrocinador/portal-patrocinador";
import AdmReuniaoResultado from "./pages/adm/admReuniaoResultado";
import LoginVerde from "./pages/login/loginVerde";
import AdmImprimirInscritosConteudo from "./pages/adm/admImprimirInscritosConteudo";
import Avaliacoes from "./pages/avaliacoes/avaliacoes";

function App() {
  return (
    <AuthProvider>
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PublicRoute />}>
              <Route path="/" element={<Login />} />
            </Route>
            <Route path="/relatorio-lista-agenda" element={<PublicRoute />}>
              <Route path="/relatorio-lista-agenda" element={<ListaAgenda />} />
            </Route>
            <Route path="/login" element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/login-verde" element={<PublicRoute />}>
              <Route path="/login-verde" element={<LoginVerde />} />
            </Route>
            <Route path="/cadastro" element={<PublicRoute />}>
              <Route path="/cadastro" element={<Login />} />
              {/* <Route
                path="/cadastro/patrocinadores"
                element={<CadastroPatrocinador />}
              /> */}
              <Route
                path="/cadastro/confirmacao/:token"
                element={<CadastroConfirmacao />}
              />
            </Route>

            <Route path="/agradecimento" element={<PublicRoute />}>
              <Route path="/agradecimento" element={<Agradecimento />} />
            </Route>

            <Route path="/portal-patrocinador" element={<PublicRoute />}>
              <Route
                path="/portal-patrocinador"
                element={<PortalPatrocinador />}
              />
            </Route>

            <Route
              path="/private/adm/convidados/imprimir/:id"
              element={<AdmImprimirReunioesConvidados />}
            />

            <Route path="/private" element={<PrivateRoute />}>
              <Route path="/private" element={<Main />}>
                <Route path="/private/" element={<Home />} />
                <Route path="/private/perfil" element={<Perfil />} />
                <Route path="/private/chat" element={<Chat />} />
                <Route path="/private/chat/:id" element={<Conversa />} />
                <Route path="/private/agenda" element={<Agenda />} />
                <Route path="/private/mapa" element={<Mapa />} />
                <Route path="/private/reuniao" element={<Reuniao />} />
                <Route path="/private/avaliacoes" element={<Avaliacoes />} />
                <Route
                  path="/private/participantes"
                  element={<Participantes />}
                />
                <Route
                  path="/private/patrocinadores"
                  element={<Patrocinadores />}
                />
                <Route
                  path="/private/notificacoes"
                  element={<Notificacoes />}
                />
              </Route>

              <Route path="/private/adm" element={<MainAdm />}>
                <Route path="/private/adm" element={<AdmDash />} />
                <Route
                  path="/private/adm/notificacoes"
                  element={<AdmNotificacoes />}
                />
                <Route
                  path="/private/adm/convidados"
                  element={<AdmConvidados />}
                />
                <Route
                  path="/private/adm/convidados/novo"
                  element={<AdmFormConvidados />}
                />
                <Route
                  path="/private/adm/convidados/editar/:id"
                  element={<AdmFormConvidados />}
                />
                <Route
                  path="/private/adm/convidados/reunioes/:id"
                  element={<AdmReunioesConvidados />}
                />

                <Route
                  path="/private/adm/patrocinadores"
                  element={<AdmPatrocinadores />}
                />
                <Route
                  path="/private/adm/patrocinadores/novo"
                  element={<AdmFormPatrocinadores />}
                />
                <Route
                  path="/private/adm/patrocinadores/editar/:id"
                  element={<AdmFormPatrocinadores />}
                />
                <Route
                  path="/private/adm/patrocinadores/reunioes/:id"
                  element={<AdmReunioesPatrocinadores />}
                />
                <Route
                  path="/private/adm/agenda/:dia"
                  element={<AdmAgenda />}
                />
                <Route
                  path="/private/adm/agenda-novo"
                  element={<AdmFormAgenda />}
                />
                <Route
                  path="/private/adm/agenda-editar/:id"
                  element={<AdmFormAgenda />}
                />
                <Route
                  path="/private/adm/eventos/:dia"
                  element={<AdmEventos />}
                />
                <Route
                  path="/private/adm/eventos-novo"
                  element={<AdmFormEventos />}
                />
                <Route
                  path="/private/adm/eventos-editar/:id"
                  element={<AdmFormEventos />}
                />
                <Route path="/private/adm/reunioes" element={<AdmReunioes />} />
                <Route
                  path="/private/adm/postagens"
                  element={<AdmPostagens />}
                />
                <Route
                  path="/private/adm/nao-entregue"
                  element={<AdmMaisDetalhesNaoEntregues />}
                />
                <Route
                  path="/private/adm/remarcar/:id"
                  element={<AdmRemarcar />}
                />
                <Route
                  path="/private/adm/mais-detalhes"
                  element={<AdmMaisDetalhes />}
                />

                <Route
                  path="/private/adm/recepcao/:dia"
                  element={<Recepcao />}
                />
                <Route
                  path="/private/adm/recepcao-incluir/:id"
                  element={<IncluirConvidado />}
                />
                <Route
                  path="/private/adm/recepcao-incluir-negocio/:id"
                  element={<IncluirReuniaoNegocio />}
                />
                <Route
                  path="/private/adm/reuniao-jantar/:id"
                  element={<IncluirReuniaoJantar />}
                />
                <Route
                  path="/private/adm/editar-reuniao/:id"
                  element={<AdmEditarRuniao />}
                />

                <Route
                  path="/private/adm/portal-patrocinador"
                  element={<AdmPortalPatrocinador />}
                />
                <Route
                  path="/private/adm/portal-patrocinador-novo"
                  element={<AdmPortalPatrocinadorNovo />}
                />
                <Route
                  path="/private/adm/portal-patrocinador-editar/:id"
                  element={<AdmPortalPatrocinadorNovo />}
                />
                <Route path="/private/adm/reuniao-resultado" element={<AdmReuniaoResultado />} />
                <Route
                  path="/private/adm/conteudo/imprimir/:id"
                  element={<AdmImprimirInscritosConteudo />}
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        {/* </header> */}
        {/* </div> */}
      </AppProvider>
    </AuthProvider>
  );
}

export default App;
