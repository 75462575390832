import React, { useEffect, useState } from "react";
import InputTextPatrocinador from "../cadastro/inputtextPatrocinador";
import useApp from "../../hooks/useAppContext";
import { useParams, useNavigate } from "react-router-dom";
import LinhaPatrocinador from "./linhaPatrocinador";
import ModalAdmPatrociandor from "./modalAdmPatrocinador";
import { FaArrowLeft } from "react-icons/fa";
import { toast } from "react-toastify";

const AdmFormPatrocinadores = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const {
    getPatrocinador,
    insertUsuarioAdm,
    atualizaPatrocinadorAdm,
    criarUsuarioPatrocinadorAdm,
  } = useApp();

  const [nomePatrocinador, setNomePatrocinador] = useState("");
  const [email, setEmail] = useState("");
  const [segmento, setSegmento] = useState("");
  const [segmentoOutros, setSegmentoOutros] = useState("");
  const [showSegmentoOutros, setShowSegmentoOutros] = useState(false);
  const [participantes, setParticipantes] = useState([]);
  const [perfil, setPerfil] = useState("");
  const [idUsuario, setIdUsuario] = useState(false);

  const [preview, setPreview] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    // setTipo("patrocinador");

    if (id) {
      carregaPatrocinador();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carregaPatrocinador = async () => {
    let patrocinador = await getPatrocinador(id);
    setNomePatrocinador(patrocinador.patrocinador);
    setEmail(patrocinador.email);
    setParticipantes(patrocinador.usuarios);
    setSegmento(
      patrocinador.usuarios[0] ? patrocinador.usuarios[0].tipo_instituicao : ""
    );
    setLogoUrl(patrocinador.logo);
    setPerfil(patrocinador.perfil);
    setIdUsuario(patrocinador.id_usuario);
  };

  // const handleSalvarPatrocinador = () => {
  //   console.log("click");
  //   if (id) {
  //     salvarUsuario(id);
  //   } else {
  //     salvarUsuario();
  //   }
  //   getPatrocinadores();
  // };

  const [showModal, setShowModal] = useState(false);
  const [validacaoSegmento, setValidacaoSegmento] = useState(true);

  const handleSegmento = (e) => {
    if (e === "") {
      setValidacaoSegmento(false);
    } else {
      setValidacaoSegmento(true);
    }
    if (e === "outros") {
      setShowSegmentoOutros(true);
    } else {
      setShowSegmentoOutros(false);
      setSegmentoOutros("");
    }
    setSegmento(e);
  };

  const handleModal = () => {
    // verificaFormularioPatrocinador();
    // setTimeout(verificaFormularioPatrocinador(), 1000);
    setShowModal(!showModal);
  };

  const incluirParticipante = async (participante) => {
    await insertUsuarioAdm(
      participante.nome,
      participante.cpf,
      participante.dt_nascimento,
      participante.email,
      "participante_patrocinador",
      participante.nome_cracha,
      participante.sexo === "Masculino" ? "masculino" : "feminino",
      participante.celular,
      nomePatrocinador,
      participante.cargo,
      participante.telefone_comercial,
      participante.departamento,
      segmento,
      segmentoOutros,
      id
    );
    if (id) {
      carregaPatrocinador();
    }
  };

  const handleChangeImage = (e) => {
    // setEnviado("true");
    if (e.target.files.length) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setLogo(e.target.files[0]);
    }
  };

  const salvarPatrocinador = async () => {
    let resp = await atualizaPatrocinadorAdm(
      nomePatrocinador,
      perfil,
      email,
      logo,
      id ?? null
    );
    console.log(resp);
    if (id) {
      carregaPatrocinador();
      toast.success("Patrocinador atualizado com sucesso");
    } else {
      navigate(
        "/private/adm/patrocinadores/editar/" + resp.novoPatrocinadorInsert.id
      );
    }
  };

  const criarUser = async () => {
    let response = await criarUsuarioPatrocinadorAdm(id);
    console.log("response", response);
    if (response?.mensagem) {
      toast.success("Usuário criado com sucesso");
      if (id) {
        carregaPatrocinador();
      }
    } else {
      toast.error("Erro ao criar usuário");
    }

  };
  return (
    <div className="container-adm-main">
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>{id ? "Editar" : "Novo"} Patrocinador</h3>
          <div className="header-options">
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className="area-imagem-usuario-patrocinador">
            <div className="form-imagem">
              {preview ? (
                <div className="">
                  <img src={preview} alt="" />
                </div>
              ) : (
                <img
                  className=""
                  src={logoUrl}
                  alt=""
                  title="Alterar imagem da empresa"
                />
              )}
              <input
                type="file"
                id="input-perfil-adm"
                onChange={(e) => handleChangeImage(e)}
              />
            </div>
          </div>

          <ModalAdmPatrociandor
            titulo="Inclusão de participante"
            show={showModal}
            toggleModal={() => handleModal()}
            footer={true}
            incluirParticipante={incluirParticipante}
          />

          <div className="area-inputs">
            <InputTextPatrocinador
              label="Nome da Instituição"
              type="text"
              requered={true}
              value={nomePatrocinador}
              onChange={setNomePatrocinador}
            />

            <InputTextPatrocinador
              label="E-mail"
              type="email"
              requered={true}
              value={email}
              onChange={setEmail}
            />

            <label className="form-label" htmlFor="input_procura">
              Segmento: <span className="text-red">*</span>
            </label>
            <select
              className={`form-select form-control form-control-lg ${validacaoSegmento ? "" : "requered-error"
                }`}
              aria-label="Default select example"
              id="input_procura"
              value={segmento}
              onChange={(e) => handleSegmento(e.target.value)}
            >
              <option value="">Selecione uma opção</option>
              <option value="tecnologia">Tecnologia</option>
              <option value="facilities">Facilities</option>
              <option value="equipamentos">Equipamentos</option>
              <option value="homecare">Homecare</option>
              <option value="infraestrutura">Infraestrutura</option>
              <option value="consultoria">Consultoria</option>
              <option value="logistica">Logistica</option>
              <option value="consumiveis">Consumiveis</option>
              <option value="outros">Outros</option>
            </select>

            {showSegmentoOutros ? (
              <InputTextPatrocinador
                label="Descrição do segmento"
                type="text"
                value={segmentoOutros}
                onChange={setSegmentoOutros}
                requered={showSegmentoOutros}
              />
            ) : (
              false
            )}
          </div>
          {id ? (
            <div
              className={`area-participantes-patrocinador ${participantes?.length === 0 ? "requered-error" : ""
                }`}
            >
              <div className="header-participantes-patrocinadores">
                <div>
                  <h5>Participantes</h5>
                </div>
                <div>
                  <button
                    style={{ backgroundColor: "#008A38" }}
                    type="button"
                    className="text-white btn botoes-cadastro"
                    onClick={() => {
                      handleModal();
                    }}
                  >
                    Incluir
                  </button>
                </div>
              </div>
              <div className="body-participantes-patrocinador">
                <div className="linha-participantes-header">
                  <div>Nome Completo</div>
                  <div>Nome Crachá</div>
                  <div>E-mail</div>
                  <div>Data Nascimento</div>
                  <div>Opções</div>
                </div>
                {participantes
                  ? participantes?.map((p) => {
                    return <LinhaPatrocinador participante={p} />;
                  })
                  : "Nenhum participante incluido"}
              </div>
            </div>
          ) : (
            false
          )}
          <div className="area-botoes-patrocinador">
            {id && !idUsuario ? (
              <button
                style={{ backgroundColor: "#008A38" }}
                type="button"
                className="text-white btn botoes-cadastro-alterar-senha"
                onClick={() => {
                  criarUser();
                }}
              >
                Criar Usuário
              </button>
            ) : (
              false
            )}
            <button
              style={{ backgroundColor: "#008A38" }}
              type="button"
              className="text-white btn botoes-cadastro-alterar-senha"
              onClick={() => {
                salvarPatrocinador();
              }}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmFormPatrocinadores;
