import React, { createContext, useCallback, useState, useEffect } from "react";
import api from "../services/axios";
import useApp from "../hooks/useAppContext";

const CadastroContext = createContext({});
export default CadastroContext;

export const CadastroProvider = ({ children }) => {
  const {
    formValido,
    setFormValido,
    formValidoPesquisa,
    setFormValidoPesquisa,
    formValidoBio,
    setFormValidoBio,
  } = useApp();

  const [nomeCompleto, setNomeCompleto] = useState("");
  const [senha, setSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const [nomeCracha, setNomeCracha] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCPF] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [telComercial, setTelComercial] = useState("");
  const [ddd, setDdd] = useState("");
  const [celular, setCelular] = useState("");
  const [nomeInstituicao, setnomeInstituicao] = useState("");
  const [cargo, setCargo] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [secretaria, setSecretaria] = useState("");
  const [telSecretaria, setTelSecretaria] = useState("");
  const [emailSecretaria, setEmailSecretaria] = useState("");
  const [sexo, setSexo] = useState("");
  const [showAlerta, setShowAlerta] = useState(false);
  const [faturamento, setfaturamento] = useState("");
  const [leitos, setleitos] = useState("");
  const [vidas, setvidas] = useState("");
  const [procura, setprocura] = useState("");
  const [outrosProcura, setOutrosProcura] = useState("");
  const [bio, setBio] = useState("");
  const [preview, setPreview] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [agendaConteudo, setAgendaConteudo] = useState([]);
  const [agendaConteudoEsporte, setAgendaConteudoEsporte] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [eventoId1, setEventoId1] = useState(0);
  const [eventoId2, setEventoId2] = useState(0);
  const [eventoId3, setEventoId3] = useState(0);
  const [eventoIdEsporte, setEventoIdEsporte] = useState(0);
  const [eventoIdEsporte2, setEventoIdEsporte2] = useState(0);
  const [eventoIdEsporte3, setEventoIdEsporte3] = useState(0);
  const [eventoIdEsporte4, setEventoIdEsporte4] = useState(0);
  const [eventoIdEsporte5, setEventoIdEsporte5] = useState(0);
  const [eventoIdEsporte6, setEventoIdEsporte6] = useState(0);
  const [eventoIdEsporte7, setEventoIdEsporte7] = useState(0);
  const [segmento, setSegmento] = useState("");
  const [segmentoOutros, setSegmentoOutros] = useState("");
  const [hobby, setHobby] = useState("");
  const [tipo, setTipo] = useState("convidado");
  const [termo1, setTermo1] = useState(null);
  const [termo2, setTermo2] = useState(null);
  const [empresaInteresse, setEmpresaInteresse] = useState("");
  const [prioridade, setPrioridade] = useState("");
  const [transformacao, setTransformacao] = useState("");
  const [usoImagem, setUsoImagem] = useState("");

  const verificaFormulario = () => {
    if (
      nomeCompleto !== "" &&
      nomeCracha !== "" &&
      email !== "" &&
      cpf !== "" &&
      dataNascimento !== "" &&
      telComercial !== "" &&
      celular !== "" &&
      nomeInstituicao !== "" &&
      cargo !== "" &&
      departamento !== "" &&
      secretaria !== "" &&
      telSecretaria !== "" &&
      emailSecretaria !== "" &&
      segmento !== ""
    ) {
      setShowAlerta(false);
      setFormValido(true);
    } else {
      setShowAlerta(true);
      setFormValido(false);
    }
  };

  const verificaFormularioPesquisa = () => {
    if (procura !== "" && faturamento !== "" && hobby !== "" && empresaInteresse !== "") {
      setShowAlerta(false);
      setFormValidoPesquisa(true);
    } else {
      setShowAlerta(true);
      setFormValidoPesquisa(false);
    }
  };

  const verificaFormularioBio = async () => {
    if (bio !== "" && avatar) {
      setShowAlerta(false);
      setFormValidoBio(true);
    } else {
      setShowAlerta(true);
      setFormValidoBio(false);
    }
  };

  const salvarUsuario = async (id = null) => {
    try {
      const formData = new FormData();

      if (!id) {
        setEventosArray(eventos);
      }
      // console.log("tipo", tipo);
      if (tipo === 'patrocinador' && id) {
        formData.append("celular", celular);
        let responseP = await api.put(`/usuarios-update/${id}`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        if (responseP.status === 200) {
          console.log("deu certo deu certo deu certo", responseP.data)
          return responseP.data;
        } else {
          return false
        }
      }

      console.log("eventos", eventos)
      const eventosUnicos = [...new Set(eventos)];
      console.log("eventosUnicos", eventosUnicos)

      formData.append("nome", nomeCompleto);
      formData.append("cpf", cpf);
      formData.append("dt_nascimento", dataNascimento);
      formData.append("email", email);
      formData.append("tipo", tipo);
      if (senha) {
        formData.append("password", senha);
      }
      formData.append("bio", bio);
      formData.append("celular", celular);
      formData.append("empresa", nomeInstituicao);
      formData.append("cargo", cargo);
      if (eventoId1 && eventoId1 !== "") {
        formData.append("conteudo1", eventoId1);
      } else {
        formData.append("conteudo1", eventosUnicos[0] ? eventosUnicos[0] : null);
      }
      if (eventoId2 && eventoId2 !== "") {
        formData.append("conteudo2", eventoId2);
      } else {
        formData.append("conteudo2", eventosUnicos[1] ? eventosUnicos[1] : null);
      }
      // if (eventoId3 && eventoId3 !== "") {
      //   formData.append("conteudo3", eventoId3);
      // } else {
      //   formData.append("conteudo3", eventos[2] ? eventos[2] : null);
      // }
      // formData.append("conteudo4", eventoId4);
      // formData.append("conteudo5", eventoId5);
      if (eventoIdEsporte) {
        formData.append("esporte1", eventoIdEsporte);
      }
      if (eventoIdEsporte2) {
        formData.append("esporte2", eventoIdEsporte2);
      }
      if (eventoIdEsporte3) {
        formData.append("esporte3", eventoIdEsporte3);
      }
      if (eventoIdEsporte4) {
        formData.append("esporte4", eventoIdEsporte4);
      }
      if (eventoIdEsporte5) {
        formData.append("esporte5", eventoIdEsporte5);
      }
      if (eventoIdEsporte6) {
        formData.append("esporte6", eventoIdEsporte6);
      }
      if (eventoIdEsporte7) {
        formData.append("esporte7", eventoIdEsporte7);
      }
      formData.append("nome_cracha", nomeCracha);
      formData.append("sexo", sexo.toLowerCase());
      formData.append("ddd_comercial", ddd);
      formData.append("telefone_comercial", telComercial);
      formData.append("departamento", departamento);
      formData.append("nome_secretaria", secretaria);
      formData.append("email_secretaria", emailSecretaria);
      formData.append("telefone_secretaria", telSecretaria);
      formData.append("procura_sbf", procura);
      formData.append("outros_procura_sbf", outrosProcura);
      formData.append("faturamento_instituicao", faturamento);
      formData.append("numero_leitos", leitos);
      formData.append("numero_vidas", vidas);
      if (avatar) {
        formData.append("avatar", avatar);
      }
      formData.append("tipo_instituicao", segmento);
      formData.append("segmento_outros", segmentoOutros);
      formData.append("hobby", hobby);
      formData.append("prioridade", prioridade);
      formData.append("transformacao", transformacao);
      formData.append("uso_imagem", usoImagem);
      formData.append("termo1", termo1);
      formData.append("termo2", termo2);
      formData.append("empresa_interesse", empresaInteresse);
      let response;
      if (id) {
        response = await api.put(`/usuarios-update/${id}`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
      } else {
        response = await api.post(`/usuarios-create`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
      }

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("login error:", error, error.response?.data);
      return error.response?.data;
    }
  };

  const carregaEventos = useCallback(async () => {
    try {
      const resp = await api.get("/agenda-cadastro-usuario");
      if (resp.status === 200) {
        setAgendaConteudo(resp.data.dados.conteudosPatrocinado);
        setAgendaConteudoEsporte(resp.data.dados.conteudosEsporte);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("login error:", error, error.response?.data);
    }
  }, []);

  const setEventosArray = (array_eventos) => {
    console.log(array_eventos);
    const eventosUnicos = [...new Set(array_eventos)];
    setEventoId1(eventosUnicos[0] ? eventosUnicos[0] : 0);
    setEventoId2(eventosUnicos[1] ? eventosUnicos[1] : 0);
    setEventoId3(eventosUnicos[2] ? eventosUnicos[2] : 0);
  };

  const limpaVariaveis = () => {
    setNomeCompleto("");
    setSenha("");
    setConfSenha("");
    setNomeCracha("");
    setEmail("");
    setCPF("");
    setDataNascimento("");
    setTelComercial("");
    setDdd("");
    setCelular("");
    setnomeInstituicao("");
    setCargo("");
    setDepartamento("");
    setSecretaria("");
    setTelSecretaria("");
    setEmailSecretaria("");
    setSexo("");
    setShowAlerta("");
    setfaturamento("");
    setleitos("");
    setvidas("");
    setprocura("");
    setOutrosProcura("");
    setBio("");
    setPreview("");
    setAvatar("");
    setAgendaConteudo("");
    setAgendaConteudoEsporte("");
    setEventos("");
    setEventoId1("");
    setEventoId2("");
    setEventoId3("");
    setEventoIdEsporte("");
    setEventoIdEsporte2("");
    setSegmento("");
    setSegmentoOutros("");
    setHobby("");
    setPrioridade("");
    setTransformacao("");
    setUsoImagem("");
    setTipo("");
    setTermo1("");
    setTermo2("");
    setEventoIdEsporte3("")
    setEventoIdEsporte4("")
    setEventoIdEsporte5("")
    setEventoIdEsporte6("")
    setEventoIdEsporte7("")
  };

  useEffect(() => {
    carregaEventos();
  }, []);

  return (
    <CadastroContext.Provider
      value={{
        nomeCompleto,
        setNomeCompleto,
        senha,
        setSenha,
        confSenha,
        setConfSenha,
        nomeCracha,
        setNomeCracha,
        email,
        setEmail,
        cpf,
        setCPF,
        dataNascimento,
        setDataNascimento,
        telComercial,
        setTelComercial,
        ddd,
        setDdd,
        celular,
        setCelular,
        nomeInstituicao,
        setnomeInstituicao,
        cargo,
        setCargo,
        departamento,
        setDepartamento,
        secretaria,
        setSecretaria,
        telSecretaria,
        setTelSecretaria,
        emailSecretaria,
        setEmailSecretaria,
        faturamento,
        setfaturamento,
        leitos,
        setleitos,
        vidas,
        setvidas,
        procura,
        setprocura,
        sexo,
        setSexo,
        bio,
        setBio,
        preview,
        setPreview,
        avatar,
        setAvatar,
        verificaFormulario,
        showAlerta,
        agendaConteudo,
        setAgendaConteudo,
        agendaConteudoEsporte,
        setAgendaConteudoEsporte,
        carregaEventos,
        eventoId1,
        setEventoId1,
        eventoId2,
        setEventoId2,
        eventoIdEsporte,
        setEventoIdEsporte,
        eventoIdEsporte2,
        setEventoIdEsporte2,
        eventoIdEsporte3,
        setEventoIdEsporte3,
        eventoIdEsporte4,
        setEventoIdEsporte4,
        eventoIdEsporte5,
        setEventoIdEsporte5,
        eventoIdEsporte6,
        setEventoIdEsporte6,
        eventoIdEsporte7,
        setEventoIdEsporte7,
        salvarUsuario,
        segmento,
        setSegmento,
        segmentoOutros,
        setSegmentoOutros,
        hobby,
        setHobby,
        prioridade,
        setPrioridade,
        transformacao,
        setTransformacao,
        usoImagem, setUsoImagem,
        setShowAlerta,
        tipo,
        setTipo,
        outrosProcura,
        setOutrosProcura,
        setEventosArray,
        eventos,
        setEventos,
        termo1,
        setTermo1,
        termo2,
        setTermo2,
        verificaFormularioPesquisa,
        verificaFormularioBio,
        limpaVariaveis,
        empresaInteresse,
        setEmpresaInteresse,
      }}
    >
      {children}
    </CadastroContext.Provider>
  );
};
