import React, { useState, useEffect } from "react";
import ModalB from "react-bootstrap/Modal";
import "../../components/modal/modal.css";
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";

const ModalAdmCSV = (props) => {
  const [tituloModal, setTituloModal] = useState(props.titulo);


  useEffect(() => { }, []);


  const handleModal = () => props.toggleModal();

  return (
    <>
      <ModalB show={props.show} onHide={handleModal}>
        <ModalB.Header closeButton>
          <ModalB.Title className="titulo-modal">{tituloModal}</ModalB.Title>
        </ModalB.Header>
        <ModalB.Body>
          <div className="download-csv" style={{ margin: "16px" }}>
            <CSVLink
              className="bt-download-csv btn btn-header-options"
              title="Download CSV"
              filename={"relatorio_usuairos.csv"}
              data={props.csvData}
            >
              <BsDownload /> <span style={{ marginLeft: "8px" }}> Download do arquivo</span>
            </CSVLink>
          </div>
        </ModalB.Body>
      </ModalB>
    </>
  );
};
export default ModalAdmCSV;

