import React, { useEffect, useState } from "react";
import ModalB from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./modal.css";

import useApp from "../../hooks/useAppContext";
import api from "../../services/axios";
import { BsStarFill, BsStar } from "react-icons/bs";

const ModalPesquisa = (props) => {
  const { verificaPesquisa } = useApp();
  const [tituloModal, setTituloModal] = useState(props.titulo);
  const [rate, setRate] = useState(0);

  useEffect(() => {
    setTituloModal(props.titulo);
  }, [props]);

  const handleModal = () => props.toggleModal();

  const handleResposta = async (rateDado) => {
    if (rateDado !== 0) {
      let resp = await api.post("responder-pesquisa", {
        id_agenda_participantes: props?.pesquisa?.id,
        rate: rateDado,
      });
      if (resp.data.pesquisa) {
        handleModal();
        verificaPesquisa();
        setRate(0);
      }
    } else {
      alert("Escolha uma opção!");
    }
  };

  const handleRespostaConteudo = async () => {
    if (rate !== 0) {
      let resp = await api.post("responder-pesquisa", {
        id_agenda_participantes: props?.pesquisa?.id,
        rate: rate,
      });
      console.log("resp.data", resp.data);
      if (resp.data.pesquisa) {
        setRate(0);
        handleModal();
        verificaPesquisa();
      }
    } else {
      alert("Escolha uma opção!");
    }
  };

  return (
    <>
      <ModalB show={props.show} onHide={handleModal} backdrop="static" >
        <ModalB.Header closeButton={props.close}>
          <ModalB.Title className="titulo-modal">{tituloModal}</ModalB.Title>
        </ModalB.Header>
        <ModalB.Body>
          <div
            className={`texto-pesquisa`}
          >{`${props.texto ? props.texto : 'Para fechar essa tela, por favor nos diga o que achou'} ${props?.pesquisa?.agenda?.tipo === "reuniao_negocio"
            ? "da reunião de negócios"
            : "do conteúdo:"
            } ${props?.pesquisa?.agenda?.tipo === "reuniao_negocio"
              ? "com " +
              props?.pesquisa?.agenda?.participantes[
                props.tipoUsuario === "patrocinador" ? 0 : 1
              ].usuario?.nome
              : props?.pesquisa?.agenda?.nome
            }`}</div>
          <div className={`aviso-pesquisa`}>
            Essa informação será compartilhada com o patrocinador / evento
          </div>
          {props?.pesquisa?.agenda?.tipo === "reuniao_negocio" ? (
            <div className={`area-btn-pesquisa`}>
              <div
                className={`btn-pesquisa-negocio ${rate === 5 ? "escolhido" : ""
                  }`}
                onClick={() => {
                  setRate(5);
                }}
              >
                Vamos fechar negócio
              </div>
              <div
                className={`btn-pesquisa-negocio ${rate === 4 ? "escolhido" : ""
                  }`}
                onClick={() => {
                  setRate(4);
                }}
              >
                Gerou proposta
              </div>
              <div
                className={`btn-pesquisa-negocio ${rate === 3 ? "escolhido" : ""
                  }`}
                onClick={() => {
                  setRate(3);
                }}
              >
                Aproximou relacionamento
              </div>
              <div
                className={`btn-pesquisa-negocio ${rate === 2 ? "escolhido" : ""
                  }`}
                onClick={() => {
                  setRate(2);
                }}
              >
                Não foi boa
              </div>
              <div
                className={`btn-pesquisa-negocio ${rate === 0 ? "desabilitado" : ""
                  }`}
                style={{ marginTop: "20px" }}
                onClick={() => {
                  handleResposta(rate);
                }}
              >
                Enviar
              </div>
            </div>
          ) : (
            <div className={`area-btn-star`}>
              <div className={`d-flex`}>
                <div
                  className={`btn-pesquisa-negocio-star`}
                  onClick={() => {
                    setRate(1);
                  }}
                >
                  {rate >= 1 ? <BsStarFill /> : <BsStar />}
                </div>
                <div
                  className={`btn-pesquisa-negocio-star`}
                  onClick={() => {
                    setRate(2);
                  }}
                >
                  {rate >= 2 ? <BsStarFill /> : <BsStar />}
                </div>
                <div
                  className={`btn-pesquisa-negocio-star`}
                  onClick={() => {
                    setRate(3);
                  }}
                >
                  {rate >= 3 ? <BsStarFill /> : <BsStar />}
                </div>
                <div
                  className={`btn-pesquisa-negocio-star`}
                  onClick={() => {
                    setRate(4);
                  }}
                >
                  {rate >= 4 ? <BsStarFill /> : <BsStar />}
                </div>
                <div
                  className={`btn-pesquisa-negocio-star`}
                  onClick={() => {
                    setRate(5);
                  }}
                >
                  {rate >= 5 ? <BsStarFill /> : <BsStar />}
                </div>
              </div>
              <div
                className={`btn-pesquisa-negocio ${rate === 0 ? "desabilitado" : ""
                  }`}
                onClick={() => {
                  handleRespostaConteudo();
                }}
              >
                Enviar
              </div>
            </div>
          )}
        </ModalB.Body>
        {props.to ? (
          <ModalB.Footer>
            <Link to={props.to} onClick={handleModal} className="link-padrao">
              {props.labelTo}
            </Link>
          </ModalB.Footer>
        ) : (
          false
        )}
      </ModalB>
    </>
  );
};

export default ModalPesquisa;
