import { FaEdit, FaTrash, FaCalendar } from "react-icons/fa";
import useCadastroPatrocinador from "../../hooks/useCadastroPatrocinadorContext";
import { formatarDia } from "../../services/conversaoTimer";
import { Link } from "react-router-dom";

const LinhaPatrocinador = (props) => {
  const { participantes, setParticipantes } = useCadastroPatrocinador();

  const handleExcluirParticipante = (id) => {
    let filter = participantes.filter((p) => {
      return p.id !== id;
    });
    setParticipantes(filter);
  };

  return (
    <div className="linha-participantes">
      <div>{props.participante?.nome}</div>
      <div className="nome-cracha">{props.participante?.nome_cracha}</div>
      <div className="email">{props.participante.email}</div>
      <div className="data-nascimento">
        {formatarDia(props.participante.dt_nascimento)}
      </div>
      <div>
        <div className="area-botoes-participantes">
          {/* <div
            className="btn-header-options"
            onClick={() => {
              handleExcluirParticipante(props.participante.id);
            }}
          >
            <FaTrash title="Excluir Participante" /> Excluir
          </div> */}
          <Link
            to={`/private/adm/convidados/editar/${props.participante.id}`}
            className="btn-header-options"
          >
            <FaEdit title="Editar convidado" /> Editar
          </Link>
          {/*<Link*/}
          {/*  to={`/private/adm/convidados/reunioes/${props.participante.id}`}*/}
          {/*  className="btn-header-options"*/}
          {/*>*/}
          {/*  <FaCalendar title="Editar reuniões" /> Agenda*/}
          {/*</Link>*/}
        </div>
      </div>
    </div>
  );
};
export default LinhaPatrocinador;
