import React from "react";
import "./notificacoes.css";
import useApp from "../../hooks/useAppContext";
import { formatarDiaHora } from "../../services/conversaoTimer";
import { Link } from "react-router-dom";

const Notificacoes = () => {
  const { notificacoesUsuario, visualizarNotificacao } = useApp();
  return (
    <div className="main">
      <div className="corpo-pagina">
        <div className="titulo-pagina">Notificações</div>
        {notificacoesUsuario.map((n) => {
          if (n.status === 0) {
            visualizarNotificacao(n.id);
          }
          let dataCriado = formatarDiaHora(n.created_at);
          return (
            <Link
              key={n.id}
              to={`/private/chat/${n.comunicado.id_usuario}`}
              style={{ textDecoration: "none", color: "#6c6c6c" }}
            >
              <div className="corpo-notificacao">
                <div className="texto-notificacao">{n.comunicado.texto}</div>
                <div className="data-notificacao">{dataCriado}</div>
              </div>
            </Link>
          );
        })}
        <div className="espaco-footer"></div>
      </div>
    </div>
  );
};

export default Notificacoes;
