import React, { useState, useEffect, useCallback } from 'react'

import './main.css'
import 'react-toastify/dist/ReactToastify.css'

import { Outlet, useNavigate } from 'react-router-dom'

import Header from '../header/header'
import Menu from '../menu/menu'
import Footer from '../footer/footer'
import Logo from '../../assets/SBF-logo.png'

import useAuth from '../../hooks/useAuthContext'
import useApp from '../../hooks/useAppContext'

import { useLocation } from 'react-router-dom'

const Main = () => {
  const navigate = useNavigate()
  const { validarUser, isAppLoaded, setIsAppLoaded, usuario } = useAuth()
  const { consultarNotificacoes, verificaPesquisa } = useApp()
  const location = useLocation()
  const [myTimeInterval, setMyTimeIntervalFooter] = useState(null)

  useEffect(() => {
    if (usuario?.tipo !== 'recepcao') {
      consultarNotificacoes()
      if (myTimeInterval) {
        clearInterval(myTimeInterval)
        handleTimeInterval()
      } else {
        handleTimeInterval()
      }
    }

    verificaPesquisa()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTimeInterval = () => {
    if (usuario?.tipo !== 'recepcao') {
      console.log("handleTimeInterval")
      let timer = setInterval(consultarNotificacoes, 30000)
      setMyTimeIntervalFooter(timer)
    }
  }

  const fetchProfile = async () => {
    try {
      let validacao = await validarUser()
      if (validacao?.tipo === 'recepcao') {
        navigate('/private/adm/recepcao/01')
      }
      // if (validacao?.tipo === "administracao_dash") {
      //   navigate("/private/adm");
      // }
      // if (location.pathname === "/private/adm") {
      //   if (
      //       validacao?.tipo !== "administracao" &&
      //       validacao?.tipo !== "administracao_dash"
      //   ) {
      //     navigate("/private");
      //   }
      // }
      setIsAppLoaded(true)
    } catch (error) { }
  }

  useEffect(() => {
    fetchProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="loading">
          <img
            className="animation__shake"
            style={{ animation: 'shake 1500ms infinite' }}
            src={Logo}
            alt="SBF"
            width={'50%'}
          />
        </div>
      )
    }
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <Outlet />
        {location.pathname.includes('/private/adm') || location.pathname.includes('private/chat/') ? false : <Footer />}
      </div>
    )
  }, [isAppLoaded, location])

  return getAppTemplate()
}

export default Main
