import React, { useState, useEffect } from "react";
import {
  FaPlus,
  FaEdit,
  FaBriefcase,
  FaArrowLeft,
  FaStar,
} from "react-icons/fa";
import useApp from "../../hooks/useAppContext";

import { useNavigate, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuthContext";

const AdmPatrocinadores = () => {
  const navigate = useNavigate();
  const { getPatrocinadoresLista } = useApp();
  const { usuario } = useAuth();
  const [patrocinadores, setPatrocinadores] = useState([]);

  useEffect(() => {
    handlePatrocinadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePatrocinadores = async () => {
    let pat = await getPatrocinadoresLista();
    console.log(pat);
    setPatrocinadores(pat);
  };

  const planos = [
    "",
    "Premium",
    "Target",
    "Advance",
    "Lite",
    "Advance Plus",
    "Lounge",
    "Conteudo",
    "Experience",
  ];

  return (
    <div className="container-adm-main">
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>Patrocinadores</h3>
          <div className="header-options">
            {usuario?.tipo === "administracao" ? (
              <Link
                to="/private/adm/portal-patrocinador"
                className="btn-header-options"
              >
                <FaStar title="Portal Patrocinadores" /> Portal Patrocinadores
              </Link>
            ) : (
              false
            )}
            {usuario?.tipo === "administracao" ? (
              <Link
                to="/private/adm/patrocinadores/novo"
                className="btn-header-options"
              >
                <FaPlus title="Adicionar novo patrocinador" /> Novo
              </Link>
            ) : (
              false
            )}
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div className={`nome-patrocinador-adm`}>Patrocinador</div>
              <div className={`plano`}>Plano</div>
              <div className={`numero-reunioes`}>N. Reuniões</div>
              <div className={`simultanias`}>Simultaneas</div>
              <div className={`opcoes-patrocinadores-adm`}>Opções</div>
            </div>
            <div className="body-lista-adm">
              {patrocinadores?.map((p) => {
                console.log("pppppppppppppp", p)
                let arrayIds = [];
                p.usuario?.agendaParticipante.map((ap) => {
                  if (ap.agenda.tipo === "reuniao_negocio") {
                    arrayIds.push(ap.id_agenda);
                  }
                });

                const arrUnique = [...new Set(arrayIds)];
                return (
                  <div className="row-lista-adm">
                    <div className={`nome-patrocinador-adm`}>
                      {p.patrocinador}
                    </div>
                    <div className={`plano`}>{p?.planos?.nome}</div>
                    <div className={`numero-reunioes`}>
                      {arrUnique.length} /{" "}
                      {p?.plano ? p?.plano.cota_reunioes : 0}
                    </div>
                    <div className={`simultanias`}>
                      {p?.plano?.cota_simultania}
                    </div>
                    <div className="opcoes-lista-adm">
                      {usuario?.tipo === "administracao" ? (
                        <Link
                          to={`/private/adm/patrocinadores/editar/${p.id}`}
                          className="btn-header-options"
                        >
                          <FaEdit title="Editar patrocinador" /> Editar
                        </Link>
                      ) : (
                        false
                      )}
                      {p.id_usuario ? (
                        <Link
                          to={`/private/adm/convidados/reunioes/${p.id_usuario}`}
                          className="btn-header-options"
                        >
                          <FaBriefcase title="Editar reuniões" /> Reuniões
                        </Link>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmPatrocinadores;
