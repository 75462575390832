import React, { useState, useEffect } from 'react'
import { FaPlus, FaEdit, FaArrowLeft, FaTrash } from 'react-icons/fa'
import useApp from '../../hooks/useAppContext'
import { Link, useNavigate } from 'react-router-dom'
import './adm.css'
import useAuth from '../../hooks/useAuthContext'
import { formatarDiaHora } from '../../services/conversaoTimer'

const AdmPortalPatrocinador = () => {
  const { usuario } = useAuth()
  const navigate = useNavigate()
  const { getDocs, excluirDocs } = useApp()
  const [itens, setItens] = useState([])

  useEffect(() => {
    handleItens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleItens = async () => {
    let aux_docs = await getDocs()
    setItens(aux_docs)
  }

  const handleExcluir = async (id) => {
    console.log(id)
    let excluir = await excluirDocs(id)
    console.log(excluir)
    if (excluir) {
      handleItens()
    }
  }

  return (
    <div className="container-adm-main">
      <div style={{ paddingBottom: '150px' }}>
        <div className="header-tela-adm">
          <h3>Portal Patrocinador</h3>
          <div className="header-options">
            {usuario?.tipo === 'administracao' ? (
              <Link
                to="/private/adm/portal-patrocinador-novo"
                title="Adicionar novo item"
                className="btn-header-options"
              >
                <FaPlus />
                Novo
              </Link>
            ) : (
              false
            )}
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1)
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div>Lista de itens cadastrados</div>
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div>Descricao</div>
              <div>Detalhes</div>
              <div>Doc</div>
              <div>Posição</div>
              <div>Clicks</div>
              <div>Criado em</div>
              <div>Opções</div>
            </div>
            <div className="body-lista-adm">
              {itens ? (
                itens?.map((i) => {
                  return (
                    <div className="row-lista-adm" key={i.id}>
                      <div>{i.descricao}</div>
                      <div title={i?.detalhes?.length > 50 ? i.detalhes : ''}>
                        {i?.detalhes?.length > 50 ? i.detalhes.substring(0, 50) + '...' : i.detalhes}
                      </div>
                      <div>
                        <a href={i.url} target={'_blank'} download={`${i.descricao}.pdf`} rel="noreferrer">
                          {i.url?.split('/').pop()}
                        </a>
                      </div>
                      <div>{i.posicao}</div>
                      <div>{i.clicks}</div>
                      <div>{formatarDiaHora(i.created_at)}</div>
                      <div className="opcoes-lista-adm">
                        {usuario?.tipo === 'administracao' ? (
                          <>
                            <Link to={`/private/adm/portal-patrocinador-editar/${i.id}`} className="btn-header-options">
                              <FaEdit title="Editar item" /> <span> Editar</span>
                            </Link>
                            <div className="btn-header-options bg-danger" onClick={() => handleExcluir(i.id)}>
                              <FaTrash title="Excluir item" /> <span> Excluir</span>
                            </div>
                          </>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="row-lista-adm">
                  <div>Nenhum item cadastrado</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdmPortalPatrocinador
