import React from "react";

// import useApp from "../../hooks/useAppContext";
import { IoChatboxEllipses } from "react-icons/io5";
import UserPadrao from "../../assets/participantes/user_padrao.jpg";
import UserPadraoM from "../../assets/participantes/Ícone-1-200x200.jpg";
import UserPadraoF from "../../assets/participantes/Ícone-2-200x200.jpg";
import { Link } from "react-router-dom";

const ItemParticipantes = (props) => {
  const handleImagemParticipante = () => {
    if (props.participante.avatar && props.participante.avatar !== "") {
      return props.participante.avatar;
    }
    if (props.participante.sexo === "masculino") {
      return UserPadraoM;
    }
    if (props.participante.sexo === "feminino") {
      return UserPadraoF;
    }
    return UserPadrao;
  };

  return (
    <div className="item-participante">
      <div className="area-dados">
        <div className="d-flex">
          <img
            className="imagem-participante"
            src={handleImagemParticipante()}
            alt=""
          />
        </div>
        <div className="dados-participante">
          <div className="nome-participante">{props.participante.nome}</div>
          <div className="empresa-participante">{`${props.participante.cargo ? props.participante.cargo + " / " : ""
            }  ${props.participante.empresa
              ? props.participante.empresa
              : "Não informado"
            }`}</div>
        </div>
      </div>
      <div className="botao-participante">
        <Link
          to={`/private/chat/${props.participante.id}`}
          className="btn btn-sm text-white mt-2 bg-black btn-enviar-mensagem"
        >
          <IoChatboxEllipses />
          <span className="label-mensagem">Enviar mensagem</span>
        </Link>
      </div>
    </div>
  );
};

export default ItemParticipantes;
