import React, { useEffect, useState } from "react";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/axios";
import { formatarDiaHora, formatarHora } from "../../services/conversaoTimer";

const AdmRemarcar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [agendas, setAgendas] = useState([]);
  const [patrocinador, setPatrocinador] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [nomePesquisa, setNomePesquisa] = useState("");
  const [indisponivel1, setIndisponivel1] = useState("");
  const [indisponivel2, setIndisponivel2] = useState("");
  const [idsJaMarcados, setIdsJaMarcados] = useState([]);

  const matriz_disponibilidade = [
    "10:20",
    "11:00",
    "11:40",
    "12:20",
    "12:50",
  ];

  const matriz_disponibilidade_segundo_dia = [
    "10:30",
    "11:10",
    "11:50",
    "12:30",
    "13:10",
  ];

  useEffect(() => {
    handlePatrocinador();
    // handleAgenda();
    handleConvidados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePatrocinador = async () => {
    let patrocinadorAux = await api.post("/patrocinador-por-agenda", {
      id: id,
    });
    console.log(patrocinadorAux.data);
    setIdsJaMarcados(patrocinadorAux.data.agendasParticipantes);
    let respostaPatrocinador = await api.post(
      "/patrocinador-verifica-disponibilidade",
      {
        id_info:
          patrocinadorAux.data.patrocinador.participantes[1].usuario
            .id_patrocinador,
      }
    );
    if (respostaPatrocinador.status === 200) {
      console.log(respostaPatrocinador.data.patrocinador);
      setPatrocinador(respostaPatrocinador.data.patrocinador);
      setIndisponivel1(respostaPatrocinador.data.indisponivel1);
      setIndisponivel2(respostaPatrocinador.data.indisponivel2);
    }
  };

  // const handleAgenda = async () => {
  //   let respostaAgenda = await api.post("/agenda", { adm: true, id: id });
  //   if (respostaAgenda.status === 200) {
  //     let auxPatrocinador =
  //       respostaAgenda.data.dados[0].agenda_participantes[1].usuario;
  //     setPatrocinador(auxPatrocinador);
  //   }
  // };

  const handleConvidados = async (nome) => {
    let respUsuarios = await api.post(`/usuarios-apenas-user`, {
      tipo: ["convidado"],
      nome,
    });

    if (respUsuarios.status === 200) {
      setParticipantes(respUsuarios.data.dados);
    }
  };

  const handleHorario = async (id_click) => {
    let respostaHorarios = await api.post(`/agenda-horarios-disponiveis`, {
      id_usuario: id_click,
    });
    setUsuarioSelecionado(id_click);
    if (respostaHorarios.status === 200) {
      let horariosDisponiveis = [];
      matriz_disponibilidade.map((md) => {
        console.log(md);
        if (!respostaHorarios.data.indisponivel01.includes(md)) {
          horariosDisponiveis.push("02/08 -" + md);
        }
      });

      matriz_disponibilidade_segundo_dia.map((md) => {
        if (
          !respostaHorarios.data.indisponivel02.includes(md) &&
          indisponivel2[md] < patrocinador?.plano.cota_simultania
        ) {
          horariosDisponiveis.push("03/08 -" + md);
        }
      });
      console.log(horariosDisponiveis);
      setHorarios(horariosDisponiveis);
    }
    setHorarioSelecionado(null);
  };

  const handleClickHorario = (horario) => {
    setHorarioSelecionado(horario);
  };

  const handleRemarcar = async () => {
    console.log(patrocinador);
    let respRemarcar = await api.post("/agenda-remarcar", {
      id_agenda: id,
      id_usuario: usuarioSelecionado,
      horario: horarioSelecionado,
      id_patrocinador: patrocinador.id_usuario,
    });
    if (respRemarcar.status === 200) {
      alert("Reagendamento realizado!");
      navigate("/private/adm/nao-entregue");
    }
  };

  const handlePesquisaConvidado = (nome) => {
    handleConvidados(nome);
    setNomePesquisa(nome);
  };

  return (
    <div className="container-adm-main">
      <div className="header-tela-adm">
        <h3>Remarcação de reunião - {patrocinador?.nome}</h3>
        <div className="header-options">
          <button
            className="btn btn-header-options"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft /> Voltar
          </button>
        </div>
      </div>
      <div className={`area-body-remarcar`}>
        <div className={`bloco-remarcar`}>
          <div
            className={`d-flex`}
            style={{ alignItems: "center", width: "345px" }}
          >
            <div style={{ width: "250px" }}>Lista de convidados</div>
            <div style={{ marginLeft: "15px", width: "100%" }}>
              <input
                style={{ width: "100%" }}
                placeholder={`Pesquisa por nome`}
                type="text"
                value={nomePesquisa}
                onChange={(e) => {
                  handlePesquisaConvidado(e.target.value);
                }}
              />
            </div>
          </div>
          {participantes.map((p) => {
            return idsJaMarcados.includes(p.id) ? (
              false
            ) : (
              <div
                className={`linha-remarcar ${usuarioSelecionado === p.id ? "ativo" : ""
                  }`}
                onClick={() => handleHorario(p.id)}
              >
                {p.nome}
              </div>
            );
          })}
        </div>
        <div className={`bloco-remarcar`}>
          <div>Lista de horarios disponiveis</div>
          {horarios.map((h) => {
            return (
              <div
                className={`linha-remarcar ${horarioSelecionado === h ? "ativo" : ""
                  }`}
                onClick={() => handleClickHorario(h)}
              >
                {h}
              </div>
            );
          })}
        </div>
        <div>
          <div
            className={`btn btn-header-options`}
            onClick={() => handleRemarcar()}
          >
            Remarcar
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "150px" }}></div>
    </div>
  );
};
export default AdmRemarcar;
