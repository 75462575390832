import { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import useApp from "../../hooks/useAppContext";
import { useParams } from "react-router-dom";
import ModalReunioes from "../../components/modal/modalReunioes";
import { formatarDiaHora } from "../../services/conversaoTimer";

const AdmReunioesPatrocinadores = () => {
  let { id } = useParams();
  const { getPatrocinadores } = useApp();
  const [patrocinador, setPatrocinador] = useState(null);
  const [quantidadeReunioes, setQuantidadeReunioes] = useState(0);
  const [quantidadeAgendada, setQuantidadeAgendada] = useState(0);
  const [reunioes, setReunioes] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (id) {
      carregaPatrocinador();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carregaPatrocinador = async () => {
    let patrocinadores = await getPatrocinadores();
    let openPatrocinador = patrocinadores.filter((p) => {
      return p.id === parseInt(id);
    });
    console.log(openPatrocinador[0]);
    setPatrocinador(openPatrocinador[0]);
    setQuantidadeReunioes(openPatrocinador[0].plano.cota_reunioes);
    setReunioes(openPatrocinador[0].agenda_participantes);
    setQuantidadeAgendada(openPatrocinador[0].agenda_participantes.length);
  };

  return (
    <div className="container-adm-main">
      <ModalReunioes
        titulo="Nova Reunião de trabalho"
        to="/agenda"
        labelTo=""
        show={showModal}
        toggleModal={() => setShowModal(!showModal)}
        id={id}
        dia={`02`}
      />
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>Agenda de Reuniões de Negócios - {patrocinador?.nome}</h3>
          <div className="header-options"></div>
        </div>
        <div className="body-tela-adm">
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div>Horário</div>
              <div>Convidado</div>
              <div>Opções</div>
            </div>
            <div className="body-lista-adm">
              {reunioes.length ? (
                // eslint-disable-next-line array-callback-return
                reunioes.map((r) => {
                  if (r.agenda.tipo === "reuniao_negocio") {
                    let convidado = r.agenda.participantes.filter(
                      (ap) => {
                        return ap.id_usuario !== parseInt(id);
                      }
                    );
                    console.log(convidado);
                    return (
                      <div className="row-lista-adm">
                        <div>{formatarDiaHora(r.agenda.dt_hora_inicio)}</div>
                        <div>{convidado[0].usuario.nome}</div>
                        <div>
                          <div className="btn-header-options">
                            <FaTrash title="Excluir Reunião" />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div className="row-lista-adm">
                  <div>Sem reuniões agenadas</div>
                </div>
              )}
            </div>
          </div>
          <div className="area-botoes-lista-adm">
            <div>
              <h6>
                Reuniões agendadas:{" "}
                {`${quantidadeAgendada}/${quantidadeReunioes}`}
              </h6>
            </div>
            <button
              type="button"
              className="text-white btn botoes-cadastro-salvar"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              <FaPlus /> Adicionar Reunião
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmReunioesPatrocinadores;
