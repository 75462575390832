import { DateTime } from "luxon"

export function segToTime(seg) {
  seg = parseInt(seg)
  let segundo = seg % 60
  let s = segundo < 10 ? "0" + segundo : segundo
  let minutos = Math.floor(seg / 60)
  let min = minutos % 60
  let m = min < 10 ? "0" + min : min
  let hora = Math.floor(minutos / 60)
  let h = hora < 10 ? "0" + hora : hora

  return `${h}:${m}:${s}`
}

export function formatarDiaHora(stringData) {
  if (stringData) {
    let dt = stringData.substring(0, 19).replace("T", " ")
    let data = DateTime.fromSQL(dt)
    return data.toFormat("dd-MM-yyyy HH:mm:ss")
  }
  return ""
}

export function formatarDia(stringData) {
  if (stringData) {
    let dt = stringData.substring(0, 19).replace("T", " ")
    let data = DateTime.fromSQL(dt)
    return data.toFormat("dd-MM-yyyy")
  }
  return ""
}

export function formatarHora(stringData) {
  if (stringData) {
    let dt = stringData.substring(0, 19).replace("T", " ")
    let data = DateTime.fromSQL(dt)
    return data.toFormat("HH:mm:ss")
  }
  return ""
}
