import React, { useState } from "react";
import "./cadastro.css";
import InputText from "./inputtext";
import { useParams } from "react-router-dom";
import api from "../../services/axios";

import { CadastroPatrocinadorProvider } from "../../contexts/CadastroPatrocinadorContext";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import useAuth from "../../hooks/useAuthContext";

const CadastroConfirmacao = () => {
  let { token } = useParams();
  const [senha, setSenha] = useState(false);
  const [confSenha, setConfSenha] = useState(false);
  const navigate = useNavigate();
  const notifyError = (mensagem) => toast.error(mensagem, { theme: "colored" });
  const notifyInfo = (mensagem) => toast.info(mensagem, { theme: "colored" });
  const { logout } = useAuth();

  const handleSenha = async () => {
    if (senha === confSenha) {
      let dados = {
        token,
        password: senha,
      };
      let response = await api.post(`/alterar-senha-usuario`, dados);
      if (response.status === 200) {
        notifyInfo(response.data.mensagem);
        logout();
        navigate("/private");
      }
    } else {
      notifyError("Os campos de senha e confirmação precisam ser iguais!");
    }
  };

  return (
    <CadastroPatrocinadorProvider>
      <ToastContainer />
      <div className="container-cadastro">
        <div className="barra-header"></div>
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3 className="fw-bold text-center" style={{ color: "#0d8133" }}>
            Saúde Business Fórum 2024.
          </h3>
          <h3>Alterar senha</h3>
          <div className="area-inputs">
            <InputText
              label="Senha"
              type="password"
              requered={true}
              value={senha}
              onChange={setSenha}
              validador={() => {
                console.log("campo a");
              }}
            />
            <InputText
              label="Confirme a senha"
              type="password"
              requered={true}
              value={confSenha}
              onChange={setConfSenha}
              comparador={senha}
              validador={() => {
                console.log("campo b");
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "70%",
              marginTop: "10px",
            }}
          >
            <button
              style={{ backgroundColor: "#008A38" }}
              type="button"
              className="text-white btn botoes-cadastro"
              onClick={() => {
                console.log("click");
                handleSenha();
              }}
            >
              Alterar Senha
            </button>
          </div>
        </div>
      </div>
    </CadastroPatrocinadorProvider>
  );
};
export default CadastroConfirmacao;
