import React, { useEffect, useState } from "react";
import { formatarHora } from "../../services/conversaoTimer";
import ModalValidaAgenda from "./modalValidaAgenda";
import { FaCheck } from "react-icons/fa";
import api from "../../services/axios";
import ModalPesquisa from "../../components/modal/modalPesquisa";
import { toast } from "react-toastify";

function LinhaAgenda(props) {
  const [showModal, setShowModal] = useState(false);
  const [showModalPesquisa, setShowModalPesquisa] = useState(false)
  const [pesquisa, setPesquisa] = useState(null)

  const handleModalAgenda = () => {
    if (!showModal) {
      verificaPesquisaUsuario()
    }
    props.handle();
    setShowModal(!showModal);
  };

  const handleModalPesquisa = () => {
    setShowModalPesquisa(!showModalPesquisa)
  }

  const verificaPesquisaUsuario = async () => {
    const pesquisa = await api.post("/verifica-pesquisa-usuario", { id_usuario: props.item.agenda.participantes[0].usuario.id });
    if (pesquisa.status === 200) {
      if (pesquisa.data.pesquisas.length > 0) {
        setShowModal(false)
        setPesquisa(pesquisa.data.pesquisas[0])
        handleModalPesquisa()
      } else {
        toast.info("Nenhuma pesquisa pendente para este usuário")
      }
    }
  }

  return (
    <>
      <ModalValidaAgenda
        titulo={`Validação do evento`}
        show={showModal}
        toggleModal={() => handleModalAgenda()}
        item={props.item}
      />
      <ModalPesquisa
        titulo="Pesquisa"
        show={showModalPesquisa}
        toggleModal={handleModalPesquisa}
        pesquisa={pesquisa}
        tipoUsuario={"convidado"}
        texto={"Para liberar a entrada na proxima reunião por favor nos diga o que achou"}
        close={true}
      />
      {props?.item.agenda.participantes[0] ? (
        <div
          className={`linha-agenda`}
          onClick={() => {
            if (!props.item.agenda?.participantes[0]?.presenca) {
              handleModalAgenda();
            } else {
              verificaPesquisaUsuario()
            }
          }}
        >
          {props.item.agenda.tipo !== "reuniao_negocio" ? (
            <div>{props.item.agenda.tipo.replace("_", " ")}</div>
          ) : (
            false
          )}
          <div
            className={`label-agenda`}
          >{`${props.item.agenda.nome} - ${props.item.agenda?.participantes[1]?.usuario?.nome}`}</div>
          <div className={`label-hora`}>
            {formatarHora(props.item.agenda.dt_hora_inicio).substring(0, 5)} /{" "}
            {formatarHora(props.item.agenda.dt_hora_fim).substring(0, 5)}
          </div>
          {props.item.agenda.tipo === "reuniao_negocio" ? (
            <div className={`area-reunia-nogocio`}>
              <div className={`d-flex`}>
                <div style={{ marginRight: "5px", color: "#007f1d" }}>
                  {props.item.agenda?.participantes[0]?.presenca ? (
                    <FaCheck />
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {props.item.agenda?.participantes[0]?.usuario?.nome} /{" "}
                  {props.item.agenda?.participantes[0]?.usuario?.empresa}
                </div>
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default LinhaAgenda;
