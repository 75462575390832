import React, { useState, useEffect } from 'react'

import './header.css'

// import Logo from '../../assets/login/Logo-comemorativo_SBF-2023_Branco.png'
import Logo from '../../assets/SBF-logo.png'
import iconeMenu from '../../assets/header/icone_menu.png'
// import iconeAgenda from "../../assets/header/icone_agenda.png";
// import iconeNotificacoes from "../../assets/header/icone_notificacoes.png";
import { Link } from 'react-router-dom'

import Modal from '../modal/modal'
import ModalPesquisa from '../modal/modalPesquisa'

import useApp from '../../hooks/useAppContext'
import useAuth from '../../hooks/useAuthContext'
import { FaBell, FaCalendar } from 'react-icons/fa'
import { formatarDiaHora } from '../../services/conversaoTimer'
import { ToastContainer } from 'react-toastify'

const Header = () => {
  const { toggleMenu, notificacoesUsuario, visualizarNotificacao, verificaPesquisa, pesquisasUsuario, tipoUsuario } =
    useApp()
  const { usuario } = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [showModalPesquisa, setShowModalPesquisa] = useState(false)
  const [quantidadeNotificacoes, setQuantidadeNotificacoes] = useState(0)
  const [notificacoes, setNotificacoes] = useState([])
  const [pesquisa, setPesquisa] = useState(null)

  const handleMenu = () => {
    toggleMenu()
  }
  const handleModalNotificacao = () => {
    setShowModal(!showModal)
  }

  const handleModalPesquisa = () => {
    setShowModalPesquisa(!showModalPesquisa)
  }

  const handleMouseNotificacoes = (id) => {
    visualizarNotificacao(id)
  }

  useEffect(() => {
    if (usuario.tipo !== 'recepcao') {
      handlePesquisas()
    }
    let aux_notificacoes = notificacoesUsuario.map((n, i) => {
      return (
        <div
          key={i}
          className="item-notificacao-modal"
          onMouseEnter={() => {
            if (n.status === 0) {
              handleMouseNotificacoes(n.id)
            }
          }}
        >
          {n.comunicado.tipo === 'geral' ? (
            <>
              <div className="titulo-item-notificacao-modal">
                Recebida em: {formatarDiaHora(n?.comunicado.created_at)}
              </div>
              <div className="body-item-notificacao-modal">{n?.comunicado.texto}</div>
            </>
          ) : (
            <Link
              to={`/private/chat/${n.comunicado.id_usuario}`}
              style={{ textDecoration: 'none' }}
              onClick={() => {
                setShowModal(false)
              }}
            >
              <div className="titulo-item-notificacao-modal">
                Recebida em: {formatarDiaHora(n?.comunicado.created_at)}
              </div>
              <div className="body-item-notificacao-modal">{n?.comunicado.texto}</div>
            </Link>
          )}
        </div>
      )
    })

    let aux_visualizadas = notificacoesUsuario.filter((n) => {
      return n.status === 0
    })
    setNotificacoes(aux_notificacoes)
    setQuantidadeNotificacoes(aux_visualizadas.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificacoesUsuario])

  const handlePesquisas = async () => {
    await verificaPesquisa()
  }

  useEffect(() => {
    if (pesquisasUsuario.length && usuario && usuario?.tipo && usuario?.tipo !== "patrocinador") {
      setPesquisa(pesquisasUsuario[0])
      setShowModalPesquisa(true)
    }
  }, [pesquisasUsuario])

  return (
    <div id="container-header">
      <ToastContainer />
      <Modal
        titulo="Notificações"
        to="/private/notificacoes"
        labelTo="Ver todas"
        show={showModal}
        toggleModal={handleModalNotificacao}
      >
        {notificacoes}
      </Modal>
      <ModalPesquisa
        titulo="Pesquisa"
        show={showModalPesquisa}
        toggleModal={handleModalPesquisa}
        pesquisa={pesquisa}
        tipoUsuario={tipoUsuario}
      ></ModalPesquisa>
      <img
        src={iconeMenu}
        alt="icone menu"
        id="icone-menu"
        onClick={() => {
          handleMenu()
        }}
      />
      <Link to="/" className="small-box-footer">
        <img src={Logo} alt="Logo" id="logo-header" />
      </Link>
      {usuario?.tipo !== 'recepcao' ? (
        <>
          <Link to="/private/agenda" className="small-box-footer">
            <div title="Minha agenda" id="agenda-header">
              <FaCalendar />
            </div>
          </Link>
          <div
            id="notificacoes-header"
            title="Notificações"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleModalNotificacao()
            }}
          >
            <FaBell style={{ color: quantidadeNotificacoes > 0 ? 'red' : '#018A38' }} />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {quantidadeNotificacoes}
              <span className="visually-hidden">Notificações</span>
            </span>
          </div>
        </>
      ) : (
        false
      )}
    </div>
  )
}

export default Header
