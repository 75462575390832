import React, { useEffect, useState } from "react";
import { formatarHora } from "../../services/conversaoTimer";
import ModalValidaAgendaConteudo from "./modalValidaAgendaConteudo";
import { FaAngleDoubleDown, FaAngleDoubleUp, FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import api from "../../services/axios";
import ModalPesquisa from "../../components/modal/modalPesquisa";
import { toast } from "react-toastify";

function LinhaAgendaConteudo(props) {
  const [showModal, setShowModal] = useState(false);
  const [showLinha, setShowLinha] = useState(false);
  const [participante, setParticipante] = useState(null);
  const [participantes, setParticipantes] = useState([]);
  const [showModalPesquisa, setShowModalPesquisa] = useState(false)
  const [pesquisa, setPesquisa] = useState(null)

  useEffect(() => {
    handleParticipantes();
  }, [showLinha]);

  useEffect(() => {
    setShowLinha(false);
  }, [props.item]);

  const handleParticipantes = () => {
    let arraySort = props?.item.participantes.sort((a, b) => {
      if (a.usuario.nome > b.usuario.nome) {
        return 1;
      }
      if (a.usuario.nome < b.usuario.nome) {
        return -1;
      }
      return 0;
    });

    setParticipantes(arraySort);
  };

  const handleModalAgenda = (itemParticipante) => {
    if (!itemParticipante.presenca) {
      verificaPesquisaUsuario(itemParticipante.id_usuario)
      setParticipante(itemParticipante);
      setShowModal(!showModal);
      props.handle();
    }
  };

  const handleModalPesquisa = () => {
    setShowModalPesquisa(!showModalPesquisa)
  }

  const verificaPesquisaUsuario = async (id) => {
    try {
      const pesquisa = await api.post("/verifica-pesquisa-usuario", { id_usuario: id });
      if (pesquisa.status === 200) {
        if (pesquisa.data.pesquisas.length > 0) {
          setShowModal(false)
          setParticipante(null);
          setPesquisa(pesquisa.data.pesquisas[0])
          handleModalPesquisa()
        } else {
          toast.info("Nenhuma pesquisa pendente para este usuário")
        }
      }
    } catch (error) {
      console.log(error)
    }

  }

  const handleLinha = () => {
    setShowLinha(!showLinha);
  };

  return (
    <>
      <ModalValidaAgendaConteudo
        titulo={`Validação do evento`}
        show={showModal}
        toggleModal={() => handleModalAgenda(!showModal)}
        item={props.item}
        participante={participante}
      />
      <ModalPesquisa
        titulo="Pesquisa"
        show={showModalPesquisa}
        toggleModal={handleModalPesquisa}
        pesquisa={pesquisa}
        tipoUsuario={"convidado"}
        texto={"Para liberar a entrada no proximo evento por favor nos diga o que achou"}
        close={true}
      />
      {props?.item.participantes[0] ? (
        <div className={`linha-agenda`}>
          <div className={`d-flex`}>
            <div className={`area-texto-conteudo`}>
              {props.item.tipo !== "reuniao_negocio" ? (
                <div>{props.item.tipo.replace("_", " ")}</div>
              ) : (
                false
              )}
              <div className={`label-agenda`}>{`${props.item.nome}`}</div>
              <div className={`label-hora`}>
                {formatarHora(props.item.dt_hora_inicio).substring(0, 5)} /{" "}
                {formatarHora(props.item.dt_hora_fim).substring(0, 5)}
              </div>
            </div>
            <div className={`area-botao-abrir`}>
              <div
                className={`btn btn-header-options`}
                onClick={() => handleLinha()}
              >
                {!showLinha ? <FaAngleDoubleDown /> : <FaAngleDoubleUp />}{" "}
                participantes
              </div>
            </div>
          </div>
          <div className={`area-participante ${showLinha ? "ativo" : ""}`}>
            <div className={`area-nome-evento`}>
              <h3>{props.item.participantes.length} Participantes</h3>
              {props.item.participantes.length < 28
                ? (
                  <Link
                    to={"/private/adm/recepcao-incluir/" + props.item.id}
                    className="btn btn-header-options"
                  >
                    Incluir Convidado
                  </Link>
                ) : (
                  false
                )}
            </div>
            {participantes.map((ap, index) => {
              return (
                <div
                  key={index}
                  className={`linha-participante-conteudo`}
                  onClick={() => {
                    if (!ap.presenca) {
                      handleModalAgenda(ap)
                    } else {
                      verificaPesquisaUsuario(ap.id_usuario)
                    }
                  }}
                >
                  {ap.presenca ? (
                    <div className={`icone-check`}>
                      <FaCheck />
                    </div>
                  ) : (
                    false
                  )}
                  {ap.usuario.nome.toUpperCase()}{" "}<span style={{ color: "red", marginLeft: "8px" }}>{ap.manual ? "(Manual)" : ""}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default LinhaAgendaConteudo;
