import React, { useState, useEffect } from "react";
import { FaEdit, FaCalendar, FaArrowLeft } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import { Link } from "react-router-dom";
import api from "../../services/axios";
import { CSVLink } from "react-csv";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuthContext";
import ModalAdmCSV from "./modalAdmCSV";

const AdmConvidados = () => {
  const navigate = useNavigate();
  const { usuario } = useAuth();
  const [convidados, setConvidados] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [labelPesquisa, setLabelPesquisa] = useState("Nome");
  const [showModalCSV, setShowModalCSV] = useState(false);
  const [eventos, setEventos] = useState([]);

  const carregarParticipantes = async (dados = { tipo: ["convidado"] }, arrayCSV = []) => {
    let respUsuarios = await api.post(`/usuarios-apenas-user`, dados);
    if (respUsuarios.status === 200) {
      setConvidados(respUsuarios.data.dados);
      let csvdata = respUsuarios.data.dados.map((c) => {
        return [
          c.id,
          c.nome,
          c.cpf,
          c.dt_nascimento,
          c.email,
          c.tipo,
          c.bio,
          c.celular,
          c.empresa,
          c.cargo,
          c.nome_cracha,
          c.sexo,
          c.ddd_comercial,
          c.telefone_comercial,
          c.departamento,
          c.nome_secretaria,
          c.email_secretaria,
          c.telefone_secretaria,
          c.procura_sbf,
          c.outros_procura_sbf,
          c.faturamento_instituicao,
          c.numero_leitos,
          c.numero_vidas,
          c.avatar,
          c.created_at,
          c.updated_at,
          c.tipo_instituicao,
          c.segmento_outros,
          c.hobby,
          c.termo1,
          c.termo2,
          c.pin,
          c.empresa_interesse,
          c.prioridade,
          c.transformacao,
          c.uso_imagem,
          c.conteudo1 ? arrayCSV[c.conteudo1] : null,
          c.conteudo2 ? arrayCSV[c.conteudo2] : null,
          c.esporte1 ? arrayCSV[c.esporte1] : null,
          c.esporte2 ? arrayCSV[c.esporte2] : null,
          c.esporte3 ? arrayCSV[c.esporte3] : null,
          c.esporte4 ? arrayCSV[c.esporte4] : null,
          c.esporte5 ? arrayCSV[c.esporte5] : null,
          c.esporte6 ? arrayCSV[c.esporte6] : null,
          c.esporte7 ? arrayCSV[c.esporte7] : null,
        ]
      });

      csvdata.unshift([
        "id",
        "nome",
        "cpf",
        "dt_nascimento",
        "email",
        "tipo",
        "bio",
        "celular",
        "empresa",
        "cargo",
        "nome_cracha",
        "sexo",
        "ddd_comercial",
        "telefone_comercial",
        "departamento",
        "nome_secretaria",
        "email_secretaria",
        "telefone_secretaria",
        "procura_sbf",
        "outros_procura_sbf",
        "faturamento_instituicao",
        "numero_leitos",
        "numero_vidas",
        "avatar",
        "created_at",
        "updated_at",
        "tipo_instituicao",
        "tipo_instituicao_outros",
        "hobby",
        "termo1",
        "termo2",
        "pin",
        "empresa_interesse",
        "prioridade_24-25",
        "transformacao_digital",
        "uso_imagem",
        "conteudo1",
        "conteudo2",
        "esporte1",
        "esporte2",
        "esporte3",
        "esporte4",
        "esporte5",
        "esporte6",
        "esporte7",
      ])
      setCsvData(csvdata);
    }
  };

  useEffect(() => {
    handleEventos()
  }, []);

  const handleEventos = async () => {
    let resp = await api.get("/agenda-descricao");
    if (resp.status === 200) {
      let arrayEventos = [];
      resp.data.dados.map((evento) => {
        arrayEventos[evento.id] = evento.nome
      });
      setEventos(arrayEventos);
      setTimeout(() => {
        carregarParticipantes({ tipo: ["convidado"] }, arrayEventos);
      }, 1000)
    }
  }

  const handleUsuarios = async (filtroTxt) => {
    setFiltro(filtroTxt);
    let dados;
    switch (labelPesquisa) {
      case "Nome":
        dados = {
          nome: filtroTxt.trim(),
        };
        break;
      case "Empresa":
        dados = {
          empresa: filtroTxt.trim(),
        };
        break;
      default:
        dados = {};
        break;
    }
    if (dados) {
      dados.tipo = ["convidado"];
      carregarParticipantes(dados);
    }
  };

  const handleOpenModal = () => {
    let csvdata = convidados.map((c) => {
      return [
        c.id,
        c.nome,
        c.cpf,
        c.dt_nascimento,
        c.email,
        c.tipo,
        c.bio,
        c.celular,
        c.empresa,
        c.cargo,
        c.nome_cracha,
        c.sexo,
        c.ddd_comercial,
        c.telefone_comercial,
        c.departamento,
        c.nome_secretaria,
        c.email_secretaria,
        c.telefone_secretaria,
        c.procura_sbf,
        c.outros_procura_sbf,
        c.faturamento_instituicao,
        c.numero_leitos,
        c.numero_vidas,
        c.avatar,
        c.created_at,
        c.updated_at,
        c.tipo_instituicao,
        c.segmento_outros,
        c.hobby,
        c.termo1,
        c.termo2,
        c.pin,
        c.empresa_interesse,
        c.prioridade,
        c.transformacao,
        c.uso_imagem,
        c.conteudo1 ? eventos[c.conteudo1] : null,
        c.conteudo2 ? eventos[c.conteudo2] : null,
        c.esporte1 ? eventos[c.esporte1] : null,
        c.esporte2 ? eventos[c.esporte2] : null,
        c.esporte3 ? eventos[c.esporte3] : null,
        c.esporte4 ? eventos[c.esporte4] : null,
        c.esporte5 ? eventos[c.esporte5] : null,
        c.esporte6 ? eventos[c.esporte6] : null,
        c.esporte7 ? eventos[c.esporte7] : null,
      ]
    });

    csvdata.unshift([
      "id",
      "nome",
      "cpf",
      "dt_nascimento",
      "email",
      "tipo",
      "bio",
      "celular",
      "empresa",
      "cargo",
      "nome_cracha",
      "sexo",
      "ddd_comercial",
      "telefone_comercial",
      "departamento",
      "nome_secretaria",
      "email_secretaria",
      "telefone_secretaria",
      "procura_sbf",
      "outros_procura_sbf",
      "faturamento_instituicao",
      "numero_leitos",
      "numero_vidas",
      "avatar",
      "created_at",
      "updated_at",
      "tipo_instituicao",
      "tipo_instituicao_outros",
      "hobby",
      "termo1",
      "termo2",
      "pin",
      "empresa_interesse",
      "prioridade_24-25",
      "transformacao_digital",
      "uso_imagem",
      "conteudo1",
      "conteudo2",
      "esporte1",
      "esporte2",
      "esporte3",
      "esporte4",
      "esporte5",
      "esporte6",
      "esporte7",
    ])
    setCsvData(csvdata);
    console.log(csvdata)
    setShowModalCSV(true);
  }

  return (
    <div className="container-adm-main">
      <ModalAdmCSV
        show={showModalCSV}
        toggleModal={() => setShowModalCSV(!showModalCSV)}
        titulo="Download CSV"
        csvData={csvData}
      />
      <div style={{ paddingBottom: "150px" }}>
        <div className="header-tela-adm">
          <h3>Convidados</h3>
          <div className="header-options">
            <div className="area-input-filtro">
              <InputGroup>
                <Form.Control
                  className="pesquisa"
                  aria-label="Text input with dropdown button"
                  onChange={(e) => {
                    handleUsuarios(e.target.value);
                  }}
                  value={filtro}
                />

                <DropdownButton
                  variant="outline-secondary"
                  title={labelPesquisa}
                  id="input-group-dropdown-participantes"
                  align="end"
                >
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setFiltro("");
                      setLabelPesquisa("Todos");
                      carregarParticipantes();
                    }}
                  >
                    Todos
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setFiltro("");
                      setLabelPesquisa("Nome");
                    }}
                  >
                    Nome
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setFiltro("");
                      setLabelPesquisa("Empresa");
                    }}
                  >
                    Empresa
                  </Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>

            {/* <div className="download-csv">
              <CSVLink
                className="bt-download-csv btn btn-header-options"
                title="Download CSV"
                filename={"relatorio_usuairos.csv"}
                data={csvData}
              >
                <BsDownload />
              </CSVLink>
            </div> */}
            <button
              className="btn btn-header-options"
              onClick={handleOpenModal}
            >
              <BsDownload />
            </button>
            <button
              className="btn btn-header-options"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaArrowLeft /> Voltar
            </button>
          </div>
        </div>
        <div className="body-tela-adm">
          <div className="lista-adm">
            <div className="header-lista-adm">
              <div>Convidado</div>
              <div>Empresa</div>
              <div>Opções</div>
            </div>
            <div className="body-lista-adm">
              {convidados?.map((p, index) => {
                return (
                  <div key={index} className="row-lista-adm">
                    <div>{p.nome}</div>
                    <div>{p.empresa}</div>
                    <div className="opcoes-lista-adm">
                      {usuario?.tipo === "administracao" ? (
                        <Link
                          to={`/private/adm/convidados/editar/${p.id}`}
                          className="btn-header-options"
                        >
                          <FaEdit title="Editar convidado" /> Editar
                        </Link>
                      ) : (
                        false
                      )}
                      <Link
                        to={`/private/adm/convidados/reunioes/${p.id}`}
                        className="btn-header-options"
                      >
                        <FaCalendar title="Editar reuniões" /> Agenda
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmConvidados;
