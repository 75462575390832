import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToExcel = async (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataExcel = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(dataExcel, fileName + fileExtension);
};

export default exportToExcel;
