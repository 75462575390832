import React, { useEffect, useState } from "react";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/axios";
import Loading from "../../assets/home/loading.gif";
import { formatarDiaHora, formatarHora } from "../../services/conversaoTimer";

const IncluirConvidado = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [agendas, setAgendas] = useState([]);
  const [evento, setEvento] = useState([]);
  const [idsParticipantes, setIdsParticipantes] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [nomePesquisa, setNomePesquisa] = useState("");
  const [mensagemRetorno, setMensagemRetorno] = useState("");
  const [loading, setLoading] = useState(false);

  const matriz_disponibilidade = [
    "10:20",
    "11:00",
    "11:40",
    "12:20",
    "12:50",
  ];

  const matriz_disponibilidade_segundo_dia = [
    "10:30",
    "11:10",
    "11:50",
    "12:30",
    "13:10",
  ];

  useEffect(() => {
    handleAgenda();
    handleConvidados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleConvidados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsParticipantes]);

  const handleAgenda = async () => {
    setLoading(true)
    let respostaAgenda = await api.post("/agenda", { adm: true, id: id });
    if (respostaAgenda.status === 200) {
      setEvento(respostaAgenda.data.dados[0]);
      let auxIdsUsers = respostaAgenda.data.dados[0].participantes.map(
        (ap) => {
          return ap.id_usuario;
        }
      );
      setIdsParticipantes(auxIdsUsers);
    }
    setLoading(false)
  };

  const handleConvidados = async (nome) => {
    let respUsuarios = await api.post(`/usuarios-apenas-user`, {
      tipo: ["convidado"],
      nome,
    });

    if (respUsuarios.status === 200) {
      let auxUser = respUsuarios.data.dados.filter((u) => {
        return !idsParticipantes.includes(u.id);
      });
      setParticipantes(auxUser);
    }
  };

  const handleUsuario = async (id_click) => {
    setMensagemRetorno("");
    if (id_click === usuarioSelecionado) {
      setUsuarioSelecionado(null);
    } else {
      setUsuarioSelecionado(id_click);
    }
    setParticipantes(participantes);
  };

  const handleIncluir = async () => {
    if (usuarioSelecionado) {
      setLoading(true)
      let respIncluir = await api.post("/agenda-incluir-convidado-conteudo", {
        id_agenda: id,
        id_usuario: usuarioSelecionado,
      });
      if (respIncluir.status === 200) {
        setIdsParticipantes([]);
        setParticipantes([]);
        setUsuarioSelecionado(null);
        setNomePesquisa("");
        alert(respIncluir.data.mensagem);
        setMensagemRetorno(respIncluir.data.mensagem);
        handleAgenda();
        handleConvidados();
      }
      setLoading(false)
    } else {
      alert("Escolha um convidado!");
    }
  };

  const handlePesquisaConvidado = (nome) => {
    handleConvidados(nome);
    setNomePesquisa(nome);
  };

  return (
    <div className="container-adm-main">
      {loading ? (
        <div className="area-loading-blur">
          <div className="area-loading">
            <img src={Loading} alt="" />
          </div>
        </div>
      ) : (
        <>
          <div className="header-tela-adm">
            <h3>Conteudo: {evento?.nome}</h3>
            <div className="header-options">
              <button
                className="btn btn-header-options"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaArrowLeft /> Voltar
              </button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            {mensagemRetorno !== "" ? (
              <div style={{ alignItems: "center", marginLeft: "35px", fontWeight: "500", color: "red" }}>{mensagemRetorno}</div>
            ) : (false)}
          </div>
          <div className={`area-body-remarcar`}>
            < div className={`bloco-remarcar`}>
              <div
                className={`d-flex`}
                style={{ alignItems: "center", width: "500px" }}
              >
                <div style={{ width: "250px" }}>Lista de convidados</div>
                <div style={{ marginLeft: "15px", width: "100%" }}>
                  <input
                    style={{ width: "100%" }}
                    placeholder={`Pesquisa por nome`}
                    type="text"
                    value={nomePesquisa}
                    onChange={(e) => {
                      handlePesquisaConvidado(e.target.value);
                    }}
                  />
                </div>
              </div>
              {participantes.map((p, index) => {
                return (
                  <div
                    key={index}
                    className={`linha-remarcar ${usuarioSelecionado === p.id ? "ativo" : ""
                      }`}
                    onClick={() => handleUsuario(p.id)}
                  >
                    {p.nome}
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className={`btn btn-header-options`}
                onClick={() => handleIncluir()}
              >
                Incluir
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: "150px" }}></div>
        </>
      )
      }
    </div >
  );
};
export default IncluirConvidado;
