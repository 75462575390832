import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuthContext";
import api from "../../services/axios";
import "./admReuniaoResultado.css";
import { FaDownload, FaArrowLeft } from "react-icons/fa";
import exportToExcel from "../../services/exportExcel";
import { useNavigate } from "react-router-dom";
import { formatarDiaHora } from "../../services/conversaoTimer";

const AdmReuniaoResultado = () => {
  const navigate = useNavigate()
  const [resultados, setResultados] = useState([]);
  const { usuario } = useAuth();

  const avaliacoes = [
    "",
    "",
    "Não foi boa",
    "Aproximou relacionamento",
    "Gerou proposta",
    "Vamos fechar negócio",
  ];

  // const patrocinadores = [
  //   161, 162, 163, 164, 165, 166, 170, 172, 173, 174, 183, 184, 186, 187, 188, 189,
  //   245, 332, 333, 334, 338, 381, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392,
  //   393, 394, 395, 396, 397, 398, 470, 499, 500, 501, 502, 503, 504, 505, 506
  // ]

  const patrocinadores = [
    161, 162, 163, 164, 165, 172, 173, 174, 183, 184, 186, 187, 188, 189, 245, 333, 383,
    386, 387, 388, 389, 390, 394, 514, 515, 603, 604, 605, 606, 607, 608, 609, 610, 611,
    612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622, 623, 624, 625, 626, 627, 628,
    629
  ]

  useEffect(() => {
    handlePesquisa();
  }, []);

  const handlePesquisa = async () => {
    let respostaPesquisa = await api.post("/pesquisa-reunioes-adm", {});
    if (respostaPesquisa.status === 200) {
      console.log(respostaPesquisa.data);
      setResultados(respostaPesquisa.data.pesquisas.filter((r) => r.presenca));
    }
  };

  const handleDownload = async () => {
    let jsonRelatorio =
      resultados.map((r, index) => {
        if (patrocinadores.includes(r.id_usuario) && r.agenda.participantes[1].status === 0) {
          return {
            Patrocinador: r.agenda.participantes[1].usuario.nome,
            Convidado: `${r.agenda.participantes[0].usuario.nome} / ${r.agenda.participantes[0].usuario?.cargo}`,
            Empresa: r.agenda.participantes[0].usuario.empresa,
            "Data reunião": formatarDiaHora(r.presenca),
            "Resultado patrocinador":
              r.agenda.participantes[1].status === 1
                ? avaliacoes[r.agenda.participantes[1].rate]
                : "Não Avaliado",
          }
        }
      })
    let jsonRelatorioFiltrado = jsonRelatorio.filter((r) => r !== undefined)
    await exportToExcel(jsonRelatorioFiltrado, "Reuniões não avaliadas pelos patrocinadores");
  };

  return (
    <div className="main">
      <div className="titulo-pagina">Reuniões não avaliadas pelos patrocinadores</div>
      <div className="header-options">
        <button
          style={{ marginBottom: "10px" }}
          className="btn btn-header-options"
          onClick={() => {
            handleDownload();
          }}
        >
          <FaDownload style={{ marginRight: "10px" }} /> Download
        </button>
        <button
          className="btn btn-header-options"
          style={{ marginBottom: "10px" }}
          onClick={() => {
            navigate(-1)
          }}
        >
          <FaArrowLeft /> Voltar
        </button>
      </div>
      <div className="corpo-pagina">
        <table
          className={`table table-bordered table-hover text-center align-items-center`}
          style={{ verticalAlign: "middle" }}
        >
          <thead>
            <tr>
              <th>Patrocinador</th>
              <th>Convidado</th>
              <th>Empresa</th>
              <th>Data reunião</th>
              <th>Resultado patrocinador</th>
            </tr>
          </thead>
          <tbody>
            {(resultados.length) ?
              resultados.map((r, index) => {
                console.log(r)
                if (patrocinadores.includes(r.id_usuario) && r.agenda.participantes[1].status === 0) {
                  return (
                    <tr key={index}>
                      <td>{r.agenda.participantes[1].usuario.nome}</td>
                      <td className={``}>
                        {r.agenda.participantes[0].usuario.nome} /{" "}
                        {r.agenda.participantes[0].usuario.cargo}
                      </td>
                      <td>{r.agenda.participantes[0].usuario.empresa}</td>
                      <td>{formatarDiaHora(r.presenca)}</td>
                      <td>
                        {r.agenda.participantes[1].status === 1
                          ? avaliacoes[r.agenda.participantes[1].rate]
                          : "Não Avaliado"}
                      </td>
                    </tr>
                  );
                }
              }) : <tr><td colSpan="5">Nenhum resultado encontrado</td></tr>
            }
          </tbody>
        </table>
        <div className={`espaco-footer`}></div>
      </div>
    </div >
  );
};

export default AdmReuniaoResultado;
