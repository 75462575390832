import React, { useEffect, useState } from "react";
import useApp from "../../hooks/useAppContext";
import useAuth from "../../hooks/useAuthContext";

import "./recepcao.css";
import { Link, useParams } from "react-router-dom";
// import { FaPlus } from "react-icons/fa";
import LinhaAgenda from "../agenda/linhaAgenda";
import LinhaAgendaConteudo from "../agenda/linhaAgendaConteudo";
import Loading from "../../assets/home/loading.gif";

function Recepcao() {
  const { usuario } = useAuth();
  const { setMostraMenuAdm, getAgendaRecepcao
    // , pesquisasUsuario, tipoUsuario 
  } = useApp();
  let { dia } = useParams();
  const [reunioes, setReunioes] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setMostraMenuAdm(false);
    handleEventos();
  }, [dia, usuario]);

  const handleEventos = async () => {
    if (usuario) {
      let id_patrocinador = usuario.id_patrocinador
        ? usuario.id_patrocinador
        : null;
      let tipo = usuario.id_patrocinador
        ? ["reuniao_negocio"]
        : ["conteudo_patrocinado", "conteudo_editorial"];
      let aux_eventos = await getAgendaRecepcao(dia, id_patrocinador, tipo);
      let ordenacao = aux_eventos.filter((ae) => ae.nome !== 'Reunião de almoço/jantar').sort(function (a, b) {
        let dataA = new Date(
          a.dt_hora_inicio ? a.dt_hora_inicio : a.agenda.dt_hora_inicio
        );
        let dataB = new Date(
          b.dt_hora_inicio ? b.dt_hora_inicio : b.agenda.dt_hora_inicio
        );
        return dataA < dataB ? -1 : dataA > dataB ? 1 : 0;
      });
      setReunioes(ordenacao);
    }
  };

  return (
    <div className={`container-recepcao`}>
      <div className="header-recepcao">
        <h3 className={`titulo`}>Agenda Evento</h3>
        <div className="d-flex">
          <Link
            to="/private/adm/recepcao/02"
            className={`btn-header-options ${dia === "02" ? "ativo" : ""}`}
          >
            02/08
          </Link>
          <Link
            to="/private/adm/recepcao/03"
            className={`btn-header-options ${dia === "03" ? "ativo" : ""}`}
          >
            03/08
          </Link>
        </div>
        <div className="header-options">
          {/*<Link to="/private/adm/agenda-novo" className="btn-header-options">*/}
          {/*  <FaPlus title="Adicionar novo evento" /> Novo*/}
          {/*</Link>*/}
          {usuario?.id_patrocinador && ["02", "03"].includes(dia) ? (
            <Link
              to={`/private/adm/recepcao-incluir-negocio/${usuario.id_patrocinador}`}
              className="btn-header-options"
            >
              Nova reunião
            </Link>
          ) : (
            false
          )}
        </div>
      </div>
      {loading ? (
        <div className="area-loading-blur">
          <div className="area-loading">
            <img src={Loading} alt="" />
          </div>
        </div>
      ) : (
        <div className={`area-lista-reunioes`}>
          {reunioes.length ? (
            reunioes?.map((r, index) => {
              return r.id_agenda ? (
                <LinhaAgenda key={index} dia={dia} item={r} handle={() => handleEventos()} />
              ) : (
                <LinhaAgendaConteudo
                  key={index}
                  dia={dia}
                  item={r}
                  handle={() => handleEventos()}
                />
              );
            })
          ) : (
            <div className={`linha-agenda`} style={{ textAlign: "center" }}>
              Sem reuniões
            </div>
          )}
        </div>)}
      <div className={`espaco-footer`}></div>
    </div>
  );
}

export default Recepcao;
