import { useState } from "react";
import useApp from "../../hooks/useAppContext";

const DetalhesParticipantesModal = (props) => {
  const { handleImagemParticipante } = useApp();
  const [classTamanho, setClassTamanho] = useState("pequeno");

  const handleTamanhoImagem = () => {
    if (classTamanho === "pequeno") {
      setClassTamanho("grande");
      setTimeout(() => {
        setClassTamanho("pequeno");
      }, 3000);
    } else {
      setClassTamanho("pequeno");
    }
  };

  const detalhes = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontWeight: "400", color: "#404040" }}>
        {props.usuario.nome}
      </div>
      <div>{`${props.usuario?.cargo ? props.usuario?.cargo + "/" : ""}  ${props.usuario?.empresa ?? ""}`}</div>
    </div>
  );

  return (
    <div
      className={`participante-agenda-${classTamanho}-modal`}
      style={{ alignItems: "center" }}
    >
      <img
        src={handleImagemParticipante(props.usuario)}
        alt=""
        className={`imagem-participante-${classTamanho}-modal`}
        onClick={() => handleTamanhoImagem()}
      />
      <div className="dados-participante-modal" style={{ display: "flex" }}>
        {detalhes}
      </div>
    </div>
  );
};
export default DetalhesParticipantesModal;
