import React, { useEffect, useState } from "react";
// import useAuth from "../../hooks/useAuthContext";
import api from "../../services/axios";
import "./reuniao.css";
import { FaDownload } from "react-icons/fa";
import exportToExcel from "../../services/exportExcel";
import { formatarDiaHora } from "../../services/conversaoTimer";
import ModalPesquisa from "../../components/modal/modalPesquisa";
import useApp from '../../hooks/useAppContext'

const Reuniao = () => {
  const { tipoUsuario, pesquisasUsuario } = useApp()
  const [resultados, setResultados] = useState([]);
  const [showModalPesquisa, setShowModalPesquisa] = useState(false)
  const [pesquisa, setPesquisa] = useState(null)

  const handleModalPesquisa = (idPesquisa) => {
    console.log("pesquisasUsuario", pesquisasUsuario)
    if (idPesquisa) {
      setPesquisa(pesquisasUsuario.filter(p => p.id === idPesquisa)[0])
    }
    setShowModalPesquisa(!showModalPesquisa)
    handlePesquisa()
  }

  const avaliacoes = [
    "",
    "",
    "Não foi boa",
    "Aproximou relacionamento",
    "Gerou proposta",
    "Vamos fechar negócio",
  ];

  useEffect(() => {
    handlePesquisa();
  }, []);

  const handlePesquisa = async () => {
    let respostaPesquisa = await api.post("/pesquisa-reunioes", {});
    if (respostaPesquisa.status === 200) {
      console.log(respostaPesquisa.data);
      setResultados(respostaPesquisa.data.pesquisas);
    }
  };

  const handleDownload = async () => {
    console.log("download");
    let jsonRelatorio = resultados.map((r) => {
      return {
        Convidado: r.agenda.participantes[0].usuario.nome,
        Empresa: r.agenda.participantes[0].usuario.empresa,
        "Resultado convidado":
          r.agenda.participantes[0].status === 1
            ? avaliacoes[r.agenda.participantes[0].rate]
            : "Não Avaliado",
        "Resultado patrocinador":
          r.agenda.participantes[0].status === 1
            ? avaliacoes[r.agenda.participantes[0].rate]
            : "Não Avaliado",
      };
    });
    console.log(jsonRelatorio);
    await exportToExcel(jsonRelatorio, "Resultado reuniões");
  };

  return (
    <div className="main">
      <ModalPesquisa
        titulo="Pesquisa"
        show={showModalPesquisa}
        toggleModal={handleModalPesquisa}
        pesquisa={pesquisa}
        tipoUsuario={tipoUsuario}
        close={true}
      ></ModalPesquisa>
      <div className="titulo-pagina">Resultado das reuniões</div>
      <div className="header-options">
        <button
          style={{ marginBottom: "10px" }}
          className="btn btn-header-options"
          onClick={() => {
            handleDownload();
          }}
        >
          <FaDownload style={{ marginRight: "10px" }} /> Download
        </button>
      </div>
      <div className="corpo-pagina">
        <table
          className={`table table-bordered table-hover text-center align-items-center`}
          style={{ verticalAlign: "middle" }}
        >
          <thead>
            <tr>
              <th>Convidado</th>
              <th>Empresa</th>
              <th>Data / Hora</th>
              <th>Resultado convidado</th>
              <th>Resultado patrocinador</th>
            </tr>
          </thead>
          <tbody>
            {resultados.map((r, index) => {
              console.log(r)
              return (
                <tr key={index}>
                  <td className={``}>
                    {r.agenda.participantes[0].usuario.nome} /{" "}
                    {r.agenda.participantes[0].usuario.cargo}
                  </td>
                  <td>{r.agenda.participantes[0].usuario.empresa}</td>
                  <td>
                    {formatarDiaHora(r.agenda.dt_hora_inicio)}
                  </td>
                  <td>
                    {r.agenda.participantes[0].status === 1
                      ? avaliacoes[r.agenda.participantes[0].rate]
                      : "Não Avaliado"}
                  </td>
                  <td style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>
                    {r.agenda.participantes[1].status === 1
                      ? avaliacoes[r.agenda.participantes[1].rate]
                      : r.agenda.participantes[1].presenca ? <button
                        style={{ marginBottom: "10px" }}
                        className="btn btn-header-options"
                        onClick={() => {
                          handleModalPesquisa(r.agenda.participantes[1].id);
                        }}
                      >
                        Responder Pesquisa
                      </button> : "Reunião não realizada"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={`espaco-footer`}></div>
      </div>
    </div>
  );
};

export default Reuniao;
