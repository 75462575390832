import { useState } from "react";
import useApp from "../../hooks/useAppContext";

const DetalhesParticipantesConteudo = (props) => {
  const { handleImagemParticipante } = useApp();
  const [classTamanho, setClassTamanho] = useState("pequeno");

  const handleTamanhoImagem = () => {
    console.log(props.usuario);
    if (classTamanho === "pequeno") {
      setClassTamanho("grande");
      setTimeout(() => {
        setClassTamanho("pequeno");
      }, 3000);
    } else {
      setClassTamanho("pequeno");
    }
  };

  const detalhes = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontWeight: "400", color: "#404040" }}>
        {props.usuario.nome}
      </div>
      <div>
        <hr></hr>
      </div>
      <div style={{ fontWeight: "700", color: "#000" }}>{`${props.usuario?.cargo ? props.usuario?.cargo + "/" : ""
        }  ${props.usuario?.empresa ? props.usuario?.empresa : ""}`}</div>
    </div>
  );

  const detalheslink = (
    <a
      title="Conversar pelo Whatsapp"
      href={`https://wa.me/55${props.usuario.celular}`}
      target={"_black"}
      style={{ textDecoration: "none", color: "#000" }}
    >
      {detalhes}
    </a>
  );

  return (
    <div
      className={`item-participante-modal  participante-agenda-${classTamanho}-modal ${props.status}`}
      style={{ alignItems: "center" }}
    >
      <img
        src={handleImagemParticipante(props.usuario)}
        alt=""
        className={`imagem-participante-${classTamanho}-modal`}
        onClick={() => handleTamanhoImagem()}
      />
      <div className="dados-participante-modal" style={{ display: "flex" }}>
        {props.usuario.celular ? detalheslink : detalhes}
      </div>
    </div>
  );
};
export default DetalhesParticipantesConteudo;
