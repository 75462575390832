/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'

import './mainAdm.css'
import 'react-toastify/dist/ReactToastify.css'

import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import Header from '../header/header'
import Menu from './menuAdm/menuAdm'
import Logo from '../../assets/SBF-logo.png'

import useAuth from '../../hooks/useAuthContext'
import useApp from '../../hooks/useAppContext'
import { CadastroProvider } from '../../contexts/CadastroContext'

const MainAdm = () => {
  const navigate = useNavigate()
  const { validarUser, isAppLoaded, setIsAppLoaded, usuario } = useAuth()
  const { consultarNotificacoes } = useApp()
  const location = useLocation()
  const [myTimeInterval, setMyTimeIntervalFooter] = useState(null)

  useEffect(() => {
    if (usuario && usuario?.tipo !== 'recepcao') {
      consultarNotificacoes()

      if (myTimeInterval) {
        console.log('a')
        clearInterval(myTimeInterval)
        handleTimeInterval()
      } else {
        handleTimeInterval()
      }
    }
  }, [])

  const handleTimeInterval = () => {
    if (usuario?.tipo !== 'recepcao') {
      let timer = setInterval(consultarNotificacoes, 30000)
      setMyTimeIntervalFooter(timer)
    }
  }

  const fetchProfile = async () => {
    try {
      let validacao = await validarUser()

      if (validacao?.tipo === 'recepcao') {
        if (!location.pathname.includes('/private/adm/recepcao')) {
          navigate('/private/adm/recepcao/01')
        }
      }

      if (validacao?.tipo === 'convidado' && validacao?.tipo === 'patrocinador') {
        navigate('/private')
      }
      if (location.pathname.includes('/private/adm')) {
        if (
          validacao?.tipo !== 'administracao' &&
          validacao?.tipo !== 'administracao_dash' &&
          validacao?.tipo !== 'recepcao'
        ) {
          navigate('/private')
        }
      }

      setIsAppLoaded(true)
    } catch (error) { }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="loading">
          <img
            className="animation__shake"
            style={{ animation: 'shake 1500ms infinite' }}
            src={Logo}
            alt="SBF"
            width={'50%'}
          />
        </div>
      )
    }
    return (
      <div className="wrapper">
        <CadastroProvider>
          <Header />
          <Menu />
          <Outlet />
        </CadastroProvider>
      </div>
    )
  }, [isAppLoaded, location])

  return getAppTemplate()
}

export default MainAdm
