import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuthContext";

const PrivateRoute = () => {
  const { isLoggedIn } = useAuth();
  const isAuthenticated = isLoggedIn !== null ? isLoggedIn : true;
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
