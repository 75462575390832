import React from "react";
import {
  // Navigate,
  Outlet
} from "react-router-dom";
// import useAuth from "../hooks/useAuthContext";

const PublicRoute = () => {
  // const { isLoggedIn } = useAuth();
  // const isAuthenticated = isLoggedIn;
  // return isAuthenticated ? <Navigate to="/private" /> : <Outlet />;
  return <Outlet />;
};

export default PublicRoute;

