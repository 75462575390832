import React, { useEffect } from "react";
import { useState } from "react";
import useApp from "../../hooks/useAppContext";
import useAuth from "../../hooks/useAuthContext";
import UserPadrao from "../../assets/participantes/user_padrao.jpg";
import UserPadraoM from "../../assets/participantes/Ícone-1-200x200.jpg";
import UserPadraoF from "../../assets/participantes/Ícone-2-200x200.jpg";
import "./chat.css";
import { Link } from "react-router-dom";

const Chat = () => {
  const { getMensagens } = useApp();
  const { usuario } = useAuth();
  const [mensgens, setMensagens] = useState([]);

  useEffect(() => {
    handleMensagens();
  }, [usuario]);

  const handleMensagens = async () => {
    let aux_mensagens = await getMensagens();
    let aux_mensagens_com_usuario = aux_mensagens.conversas.map((c) => {
      let baseUser = c.id_origem === usuario?.id ? c.id_destino : c.id_origem;
      let user = aux_mensagens.usuarioConversa.filter((uc) => {
        return uc.id === baseUser;
      });
      c.usuario = user[0];
      return c;
    });
    setMensagens(aux_mensagens_com_usuario);
  };

  const handleImagemParticipante = (participante) => {
    if (participante.usuario?.avatar && participante.usuario?.avatar !== "") {
      return participante.usuario.avatar;
    }
    if (participante.usuario?.sexo === "masculino") {
      return UserPadraoM;
    }
    if (participante.usuario?.sexo === "feminino") {
      return UserPadraoF;
    }
    return UserPadrao;
  };

  return (
    <div className="main">
      <div className="titulo-pagina">Mensagens</div>
      <div className="corpo-pagina-chat">
        <div className="area-lista-conversas">
          {mensgens?.map((m) => {
            return (
              <Link
                key={m.id}
                to={`/private/chat/${m.usuario?.id}`}
                className={`linha-usuario ${m?.chatMensagens.length ? "ativo" : ""
                  }`}
              >
                <div className="area-usuario-chat">
                  <div>
                    <img
                      className="imagem-participante-chat"
                      src={handleImagemParticipante(m)}
                      alt=""
                    />
                  </div>
                  <div className="dados-usuario-chat">
                    <div className="nome-usuario">{m?.usuario?.nome}</div>
                  </div>
                </div>
                {m?.chatMensagens.length ? (
                  <div className="quantidade-mensagens">
                    {m?.chatMensagens.length}
                  </div>
                ) : (
                  false
                )}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Chat;

