import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

function IndicadorDash(props) {
  return (
    <div
      className={`indicador tamanho-indicador-${props.tamanho} ${
        props.mais ? "indicador-relativo" : ""
      }`}
    >
      <div className={`indicador-label`}>{props.label}</div>
      <div className={`indicador-value`}>{props.value}</div>

      {props.mais ? (
        <Link
          to={props?.linkMais}
          className={"mais-detalhes"}
          style={{
            textDecoration: "none",
            color: "#8abb2a",
            position: "absolute",
            right: "8px",
            bottom: "8px",
          }}
        >
          <FaPlus />
        </Link>
      ) : (
        false
      )}
    </div>
  );
}

export default IndicadorDash;
