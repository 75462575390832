import React, { useEffect, useState } from "react";
import IndicadorDash from "./indicadorDash";
import "./adm.css";
import { Link } from "react-router-dom";
import api from "../../services/axios";

function AdmDash() {
  const [reunioesFuturas, setReunioesFuturas] = useState("00");
  const [reunioesEntregues, setReunioesEntregues] = useState("00");
  const [reunioesNaoEntregues, setReunioesNaoEntregues] = useState("00");
  const [reunioesEditorial, setReunioesEditorial] = useState("00");
  const [reunioesPatrocinado, setReunioesPatrocinado] = useState("00");
  const [reunioesTotalRealizada, setReunioesTotalRealizada] = useState("00");
  const [reunioesNoShow, setReunioesNoShow] = useState("00");
  const [reunioesExtras, setReunioesExtras] = useState("00");
  const [avaliacoesPatrocinadores, setAvaliacoesPatrocinadores] = useState("0");
  const [avaliacoesConvidados, setAvaliacoesConvidados] = useState("0");
  const [totalReunioes, setTotalReunioes] = useState("0");
  const [totalConvidados, setTotalConvidados] = useState("0");

  useEffect(() => {
    handleCarregaDash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCarregaDash = async () => {
    let valores = await api.get("/dashboard");
    if (valores.status === 200) {
      valores = valores.data;
      setReunioesFuturas(valores.reunioes_futuras);
      setReunioesEntregues(valores.reunioes_entregues);
      setReunioesNaoEntregues(valores.reunioes_nao_entregues);
      setReunioesEditorial(valores.editorial);
      setReunioesPatrocinado(valores.patrocinado);
      setReunioesTotalRealizada(valores.total_realizado);
      setReunioesNoShow(valores.total_no_show);
      setReunioesExtras(valores.total_extra);
      setAvaliacoesPatrocinadores(valores.totalAvaliacoesPatrocinadores)
      setAvaliacoesConvidados(valores.totalAvaliacoesConvidados)
      setTotalReunioes(valores.total_reunioes)
      setTotalConvidados(valores.totalConvidados)
    }
  };

  return (
    <div className={`container-adm-main`}>
      <div className="header-tela-adm"></div>

      <div className={"area-indicadores"}>
        <IndicadorDash
          label={`Porcentagem de futuras reuniões`}
          tamanho={1}
          value={`${reunioesFuturas}`}
        />
        <IndicadorDash
          label={`Porcentagem de reuniões entregues`}
          tamanho={1}
          value={`${reunioesEntregues}`}
        />
        <IndicadorDash
          label={`Porcentagem de reuniões não entregues`}
          tamanho={1}
          value={`${reunioesNaoEntregues}`}
          mais={true}
          linkMais={`/private/adm/nao-entregue`}
        />
        <IndicadorDash
          label={`Porcentagem de presença no conteúdo editorial`}
          tamanho={2}
          value={`${reunioesEditorial}`}
        />
        <IndicadorDash
          label={`Porcentagem de presença no conteúdo patrocinado`}
          tamanho={2}
          value={`${reunioesPatrocinado}`}
        />
        <IndicadorDash
          label={`Quantidade de reuniões realizadas`}
          tamanho={2}
          value={`${reunioesTotalRealizada}`}
        />
        <IndicadorDash
          label={`Quantidade de no shows das reuniões`}
          tamanho={2}
          value={`${reunioesNoShow}`}
        />
        <IndicadorDash
          label={`Reuniões não avaliadas pelo patrocinador`}
          tamanho={2}
          value={`${avaliacoesPatrocinadores}`}
          mais={true}
          linkMais={`/private/adm/reuniao-resultado`}
        />
        <IndicadorDash
          label={`Quantidade de reuniões extras`}
          tamanho={2}
          value={`${reunioesExtras}`}
        />
        <IndicadorDash
          label={`Total convidados`}
          tamanho={2}
          value={`${totalConvidados}`}
        />
      </div>
      <div className="area-botoes-notificacao">
        <Link
          to={"/private/adm/mais-detalhes"}
          style={{ textDecoration: "none" }}
        >
          <button className={`btn btn-header-options`}>Mais detalhes</button>
        </Link>
      </div>
      <div className={`espaco-footer`}></div>
    </div>
  );
}

export default AdmDash;
